var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        version: "1.1",
        id: "Layer_1_00000117644378966866490090000012234314297452828555_",
        xmlns: "http://www.w3.org/2000/svg",
        x: "0px",
        y: "0px",
        viewBox: "0 0 333 441.9",
        "xml:space": "preserve",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "_x3C_Clip_Group_x3E__00000155841654436392735480000011356862656030317186_",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "fill_00000083804165052822309660000009267360886110254984_",
              },
            },
            [
              _c("defs", [
                _c(
                  "clipPath",
                  {
                    attrs: {
                      id: "_x3C_Clipping_Path_x3E__00000097467826269626703960000001949188860486469035_",
                    },
                  },
                  [
                    _c("rect", {
                      attrs: {
                        x: "0",
                        y: _setup.offset,
                        width: "100%",
                        height: _setup.height + "%",
                      },
                    }),
                  ]
                ),
              ]),
              _c(
                "linearGradient",
                {
                  attrs: {
                    id: "_x3C_Compound_Path_x3E__00000067213885940826505920000003878382724841286792_",
                    gradientUnits: "userSpaceOnUse",
                    x1: "307.0499",
                    y1: "-122.85",
                    x2: "720.0499",
                    y2: "-122.85",
                    gradientTransform:
                      "matrix(6.123234e-17 -1 1 6.123234e-17 289.35 740.95)",
                  },
                },
                [
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#809700",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.305" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#829600",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.305" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#899200",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.305" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#948b01",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.305" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#a58101",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.305" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#bb7402",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.305" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#d66503",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.305" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#e75b04",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.305" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#e75b04",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.563" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#e75b04",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.605" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#e45805",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.605" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#da4d08",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.605" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#ca3b0e",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.605" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#b42216",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.605" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#ac1919",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.605" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#ac1919",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "0.661" },
                  }),
                  _c("stop", {
                    staticStyle: {
                      "stop-color": "#ac1919",
                      "stop-opacity": "0.8",
                    },
                    attrs: { offset: "1" },
                  }),
                ],
                1
              ),
              _c("polygon", {
                staticStyle: {
                  "clip-path":
                    "url(#_x3C_Clipping_Path_x3E__00000097467826269626703960000001949188860486469035_)",
                  fill: "url(#_x3C_Compound_Path_x3E__00000067213885940826505920000003878382724841286792_)",
                },
                attrs: {
                  id: "_x3C_Compound_Path_x3E_",
                  points:
                    "\n\t\t\t216.4,20.9 216.4,32 222,43 221.5,84.5 166.5,44.4 31.5,142.8 44.5,152.4 50,181.8 50,231.8 50,366.6 50,395.3 50,412.2 50,430.7\n\t\t\t283,433.9 283,165.6 301.5,142.8 255.3,95.4 255.3,43 260.8,32 260.8,20.9 \t\t",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "g",
        {
          attrs: {
            id: "outline_00000150104852890241042430000001386292003074761090_",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "outline_00000160175685872150445530000012388415487779670702_",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "_x3C_Compound_Path_x3E__00000062155129862049034450000014985191080889101497_",
                  },
                },
                [
                  _c("path", {
                    staticClass: "st1",
                    attrs: {
                      d: "M316.3,398.2c0-0.2,0-0.5,0-0.7c0-12.2-9.9-22.1-22.2-18.7v-70.9H283v122.8h-22.2h-11.1h-66.6H172H50V307.9\n\t\t\t\tH38.9v67.4c-12.3,0-22.2,9.9-22.2,22.2c0,0.2,0,0.5,0,0.7C6.7,400.8,0,409.8,0,419.6c0,1.8,0.2,3.7,0.7,5.5\n\t\t\t\tc2.5,9.8,11.4,16.6,21.5,16.6h288.6c12.3,0,22.2-9.9,22.2-22.1C333,409.5,326.1,400.7,316.3,398.2z M38.9,430.7H22.2\n\t\t\t\tc-6.1,0-11.1-5-11.1-11.1c0-6,4.9-11,10.9-11.1c0.3,0,0.6,0.1,0.9,0.1c3.1,0.2,5.7-2.1,5.9-5.2c0-0.8-0.1-1.5-0.3-2.2\n\t\t\t\tc-0.5-1.2-0.7-2.5-0.7-3.8c0-6.1,5-11.1,11.1-11.1V430.7z M310.8,430.7h-16.6v-44.3c6.1,0,11.1,5,11.1,11.1\n\t\t\t\tc0,1.3-0.3,2.6-0.7,3.8c-1,2.9,0.4,6,3.3,7.1c0.7,0.3,1.5,0.4,2.2,0.3c0.3,0,0.6-0.1,0.9-0.1c6.1,0,11.1,5,11,11.2\n\t\t\t\tC321.9,425.9,316.9,430.8,310.8,430.7z",
                    },
                  }),
                  _c("path", {
                    staticClass: "st1",
                    attrs: {
                      d: "M171.6,347.9c20.5,0.4,33.4-18.7,30-36.1h-8.9c2.1,7.3,0.4,15.5-5.5,21.3c-4.1,4.1-9.6,6.3-15.4,6.2\n\t\t\t\tc-15.4-0.4-23.9-14.9-20.4-27.5h-8.9C139.1,328.9,151.6,347.7,171.6,347.9z",
                    },
                  }),
                  _c("path", {
                    staticClass: "st1",
                    attrs: {
                      d: "M172,330.7c10.8-0.2,15.4-11.2,11.5-18.9h-22.9C156.6,319.5,161.2,330.5,172,330.7z M176.3,317.8\n\t\t\t\tc0,2.4-1.9,4.3-4.3,4.3c-2.4,0-4.3-1.9-4.3-4.3C167.9,312.1,176.1,312.1,176.3,317.8z",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "_x3C_Compound_Path_x3E__00000056394180119723895970000015057799298079832484_",
                  },
                },
                [
                  _c("rect", {
                    staticClass: "st2",
                    attrs: {
                      x: "38.9",
                      y: "184",
                      width: "11.1",
                      height: "123.8",
                    },
                  }),
                  _c("rect", {
                    staticClass: "st2",
                    attrs: {
                      x: "283",
                      y: "184",
                      width: "11.1",
                      height: "123.8",
                    },
                  }),
                  _c("path", {
                    staticClass: "st2",
                    attrs: {
                      d: "M193.6,223.2L193.6,223.2c-1.1-28.5-42-28.5-43.1,0v73.6c-4.4,4.4-7,9.7-8,15.1h8.9\n\t\t\t\tc1.2-4.2,3.7-8.2,7.8-11.3v-77.3c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9v77.3c3.9,3,6.5,7,7.7,11.3h8.9\n\t\t\t\tc-1-5.4-3.6-10.6-8-15.1V223.2z",
                    },
                  }),
                  _c("path", {
                    staticClass: "st2",
                    attrs: {
                      d: "M176.3,305.6v-56.7h-8.6v56.7c-3.5,1.3-5.8,3.5-7.2,6.2h22.9C182.1,309.2,179.8,306.9,176.3,305.6z",
                    },
                  }),
                  _c("g", [
                    _c("g", [
                      _c("path", {
                        staticClass: "st2",
                        attrs: {
                          d: "M116,214.6v8.6h25.9v-8.6H116z M124.6,231.8v8.6h17.2v-8.6H124.6z M116,257.6h25.9V249H116V257.6z M116,292h25.9v-8.6H116V292z M124.6,266.2v8.6h17.2v-8.6H124.6z",
                        },
                      }),
                    ]),
                  ]),
                ]
              ),
              _c("path", {
                staticClass: "st3",
                attrs: {
                  d: "M314.1,147.2c2.4-1.8,2.9-5.3,1.1-7.8c-0.3-0.4-0.7-0.8-1.2-1.1l-47.7-34.8V43c3.1,0,5.5-2.5,5.5-5.5V15.3 c0-3.1-2.5-5.5-5.5-5.5h-55.5c-3.1,0-5.5,2.5-5.5,5.5v22.2c0,3.1,2.5,5.5,5.5,5.5v20l-41.1-30c-2-1.4-4.6-1.4-6.5,0L18.9,138.3 c-2.5,1.8-3,5.3-1.2,7.7c0.3,0.4,0.7,0.8,1.2,1.2l20,15V184H50v-21.8l116.5-84.6l116.5,88V184h11.1v-18.4L314.1,147.2z M216.4,20.9h44.4V32h-44.4V20.9z M255.3,43v52.4l-33.8-24.7h0.5V43H255.3z M169.8,66.2c-2-1.4-4.6-1.4-6.5,0L44.5,152.4l-13-9.6 l135-98.4l135,98.4l-12.9,9.7L169.8,66.2z",
                },
              }),
              _c(
                "g",
                {
                  attrs: {
                    id: "_x3C_Compound_Path_x3E__00000134928757692529290620000002845179146400486273_",
                  },
                },
                [
                  _c("path", {
                    staticClass: "st2",
                    attrs: {
                      d: "M193.6,223.1c-1.1-28.5-42-28.5-43.1,0v73.6c-3.3,3.4-5.6,7.2-7,11.2h9.5c1.4-2.7,3.4-5.2,6.2-7.4v-77.3\n\t\t\t\tc0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9v77.3c2.7,2,4.7,4.6,6.1,7.4h9.4c-1.4-4-3.7-7.8-7-11.2V223.1z",
                    },
                  }),
                  _c("path", {
                    staticClass: "st2",
                    attrs: {
                      d: "M176.3,248.9h-8.6v56.7c-1.6,0.6-2.9,1.4-4,2.3h16.6c-1.1-0.9-2.4-1.7-4-2.3V248.9z",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "_x3C_Compound_Path_x3E__00000142868789716953986900000000032946294931148980_",
                  },
                },
                [
                  _c("path", {
                    staticClass: "st1",
                    attrs: {
                      d: "M171.6,347.9c22.1,0.4,35.3-21.7,29-40h-9.4c4.1,8,3,18.3-4,25.1c-4.1,4.1-9.6,6.3-15.4,6.2\n\t\t\t\tc-17.1-0.4-25.6-18.1-18.8-31.3h-9.5C137.3,325.9,150.1,347.6,171.6,347.9z",
                    },
                  }),
                  _c("path", {
                    staticClass: "st1",
                    attrs: {
                      d: "M172,330.6c12.9-0.3,16.9-15.6,8.4-22.7h-16.8C155.1,314.9,159.1,330.3,172,330.6z M176.3,317.7\n\t\t\t\tc0,2.4-1.9,4.3-4.3,4.3c-2.4,0-4.3-1.9-4.3-4.3C167.9,312,176.1,312,176.3,317.7z",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }