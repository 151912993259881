import { apiClient, getRequest, severityCode } from "./httpClient";

const END_POINT = "token";

const getProjectToken = async () => {
  let errorResponse = null;
  //let response = await apiClient.get(`${END_POINT}/project`).catch(error => {
  let response = await apiClient.get(`project/token`).catch((error) => {
    errorResponse = error.toString();
  });
  if (errorResponse) {
    return { data: null, error: errorResponse };
  }
  return { data: response.data, error: errorResponse };
};

const getSubLocationToken = async (paoId) => {
  const response = await getRequest(`${END_POINT}/partyaddress/${paoId}/sublocations`);
  return response;
};

const getBuildingsFilterToken = async (token, filterData) => {
  console.log("token.api getBuildingsFilterToken: ", token, filterData);
  const response = await apiClient.post(`${END_POINT}/building/${token}/filter`, { filterData: filterData });
  return response.data;
};

const getSubLocationsFilterToken = async (token, filterData) => {
  const response = await apiClient.post(`${END_POINT}/sublocations/${token}/filter`, { filterData: filterData });
  return response.data;
};

const getAppliedFilterToken = async (token, filterData) => {
  const response = await apiClient.post(`${END_POINT}/applied/${token}/filter`, { filterData: filterData });
  return response.data;
};

const getAppliedLogFilterToken = async (token, filterData) => {
  const response = await apiClient.post(`${END_POINT}/applied/${token}/logs/filter`, { filterData: filterData });
  return response.data;
};

export { getProjectToken, getSubLocationToken, getBuildingsFilterToken, getSubLocationsFilterToken, getAppliedFilterToken, getAppliedLogFilterToken, severityCode };
