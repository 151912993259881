<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();

function login() {
  authStore.login();
}
</script>

<template>
  <v-layout flex-column class="login">
    <header>
      <h1 class="large">Welkom bij de<br />Paris Proof Monitor</h1>
    </header>
    <article>Het intelligente dataplatform voor overzicht en inzicht in uw vastgoed en de meest effectieve verduurzamingsroute.</article>
    <footer>
      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn class="primary" @click="login">
            <v-icon dark left>mdi-arrow-right</v-icon>
            Inloggen
          </v-btn>
        </v-col>
      </v-row>
    </footer>
  </v-layout>
</template>
