import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { getProjectToken as getProjectTokenFromApi } from "@/services/api/token.api";
import { severityCode } from "@/services/api/httpClient";

//eigenlijk enum
const FilterStatus = {
  None: 0,
  Success: 1,
  Error: 2,
  Empty: 3,
  TooMany: 4,
};

const FilterTokenName = {
  Buildings: "BUILDINGS",
  Complex: "COMPLEX",
  ComplexDetails: "COMPLEX_DETAILS",
  Applied: "APPLIED",
  PartyAddress: "PARTY_ADDRESS",
  Measure: "MEASURE",
  MeasureSelection: "MEASURE_SELECTION",
  UnassignedPartyAddress: "UNA_PARTY_ADDRESS",
  AddPartyAddress: "ADD_PARTY_ADDRESS",
  AppliedLogging: "APPLIED_LOGGING",
  LocationLabel: "LOCATION_LABEL",
  Roadmap: "ROADMAP",
};

const useFiltertokenStore = defineStore("filtertoken", () => {
  const _projectToken = ref({ id: null, expire: 0 });
  const tokenDelayed = ref(undefined);

  const filterItems = ref([]);

  // referenced by name => tokens => {id, expire}
  const _filterTokens = ref([]);

  const projectToken = computed(() => (_projectToken.value.id ? _projectToken.value.id : undefined));

  function getSearchToken() {
    //console.log("filtertokenStore, getSearchToken load");
    return getProjectToken();
  }

  async function getProjectToken() {
    //console.log("filtertokenStore, getProjectToken");
    await fetchProjectToken();
    //console.log("filtertokenStore, getProjectToken projectToken: ", _projectToken.value.id);
    return _projectToken.value.id;
  }

  let loadProjectToken = null;

  async function fetchProjectToken() {
    //console.log("filtertokenStore, fetchProjectToken");
    if (!loadProjectToken || !_projectToken.value.id || _projectToken.value.expire < Date.now()) {
      loadProjectToken = async () => {
        try {
          //console.log("filtertokenStore, fetchProjectToken new");
          const response = await getProjectTokenFromApi();
          if (response && !response.error) {
            //expire komt als string binnen en moet omgezet worden naar milliseconden
            _projectToken.value = { id: response.data.id, expire: new Date(response.data.expire).getTime() };
          }
        } finally {
          loadProjectToken = null;
        }
      };
    }
    return loadProjectToken();
  }

  function resetAllTokens() {
    //console.log("filtertokenStore, resetAllTokens");
    _projectToken.value = { id: null, expire: 0 };
    Object.keys(FilterTokenName).forEach(function (key) {
      _filterTokens.value[FilterTokenName[key]] = { id: null, expire: 0 };
      //console.log("filtertokenStore, reset token ", FilterTokenName[key], _filterTokens.value[FilterTokenName[key]]);
    });

    filterItems.value = [];
    console.log("filtertokenStore, resetAllTokens ", filterItems.value, _filterTokens.value);
  }

  function getFilterToken(name) {
    //console.log("store, get filterToken", name, _filterTokens.value);
    const token = _filterTokens.value[name];
    //console.log("store, get filterToken token", token);
    if (token && token.expire && token.expire > Date.now()) {
      return token;
    }
    return { id: null, expire: 0 };
  }

  // { name: {string:token_name}, token {id: {guid:token_id}, expire: {int:time_milliseconden}} }
  function setFilterToken(name, id, expire) {
    //console.log("filtertokenStore, actions setFilterToken ", item);
    if (id) {
      //expire komt als string binnen en moet omgezet worden naar milliseconden
      _filterTokens.value[name] = { id: id, expire: new Date(expire).getTime() };
    } else {
      _filterTokens.value[name] = { id: null, expire: 0 };
    }
    return _filterTokens.value[name];
  }

  function setFilterItem(filterName, itemName, type, value) {
    // const k = `${filterName}.${itemName}.${type}`;
    // console.log("filtertokenStore, setFilterItem ", k, value);
    filterItems.value[`${filterName}.${itemName}.${type}`] = value;
  }

  function getFilterItem(filterName, itemName, type) {
    return filterItems.value[`${filterName}.${itemName}.${type}`];
  }

  return { projectToken, tokenDelayed, getSearchToken, getProjectToken, resetAllTokens, getFilterToken, setFilterToken, setFilterItem, getFilterItem };
});

function mapFilterResponseStatus(severity, code) {
  let status = FilterStatus.None;
  switch (severity) {
    case severityCode.none:
    case severityCode.hint:
    case severityCode.warning:
      switch (code) {
        case 204:
          status = FilterStatus.Empty;
          break;
        case 290:
          status = FilterStatus.TooMany;
          break;
        default:
          status = FilterStatus.Success;
          break;
      }
      break;
    case severityCode.error:
      status = FilterStatus.Error;
      break;
  }
  return status;
}

export { useFiltertokenStore, FilterTokenName, FilterStatus, mapFilterResponseStatus };
