<script lang="ts" setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { getCO2Emissions } from "@/services/api/chart.api";

const props = defineProps({
  token: {
    type: String,
    default: "",
  },
  certified: {
    type: Boolean,
    default: true,
  },
  refDate: {
    type: Date,
  },
});

const chart = ref({
  status: {},
  any: undefined,
});
const isLoading = ref(false);

onMounted(() => {
  fetchBuildingMeasuresData();
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchBuildingMeasuresData() {
  isLoading.value = true;
  const response = await getCO2Emissions(props.token);
  if (response) {
    chart.value = response.data;
  } else {
    proxy.$toaster.error("Ophalen van de maatregelen data is mislukt!");
  }
  isLoading.value = false;
}
</script>

<template>
  <v-layout class="widget-item">
    <header>
      <h2>CO₂</h2>
      <div class="widget-actions">
        <information-dropdown>
          <template v-slot:header>
            <h2>CO₂</h2>
          </template>
          <p>
            De huidige status van het CO₂ verbruik wordt berekend aan de hand van de energieverbruiken van de woningen. De norm voor 2050 is gebaseerd op het klimaatakkoord waarbij is afgesproken dat
            uitstoot van broeikasgassen met 95% afgenomen moet zijn ten opzichte van 1990. Het energieverbruik in 1990 is teruggerekend in een onderzoek door INNAX.
          </p>
        </information-dropdown>
      </div>
    </header>

    <loader-spinner v-if="isLoading === true" :is-loading="isLoading" :min-height="200"></loader-spinner>
    <!-- Charts werken niet als ze in een v-if constructie zitten -->
    <div v-show="isLoading === false && chart.any == true">
      <div class="widget-wrapper">
        <div class="widget-chart measures">
          <chart-co2-emissions :source-data="chart" :height="150"></chart-co2-emissions>
        </div>
      </div>
    </div>
    <div v-show="isLoading === false && chart.any == false">
      <p>Er zijn geen CO₂ waarden beschikbaar voor dit project</p>
    </div>
  </v-layout>
</template>
