<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getBuildingYearlyUsages, severityCode } from "@/services/api/building.api";
import { formatThousandNoFraction } from "@/composables/formatNumbers";
import BuildingUsages from "@/components/app/buildings/BuildingUsages.vue";

const emits = defineEmits(["input"]);

const props = defineProps({
  addressId: {
    type: String,
  },
  value: { default: false },
});

const showDetails = ref(false);
const isLoading = ref(false);
const usages = ref({
  energyTypes: [],
  yearUsages: [
    {
      year: 0,
      energyTypeUsages: [
        {
          energyTypeKey: "",
          value: 0,
        },
      ],
    },
  ],
});
const gridData = ref([]);
const selectedYear = ref(0);
const _headers = [{ text: "", value: "energyTypeDescr", sortable: false }];
const headers = computed(() => {
  const years = usages.value.yearUsages.map((x) => {
    return { text: x.year, value: `year${x.year}`, sortable: false, align: "end" };
  });
  const result = [..._headers, ...years];
  return result;
});

watch(
  () => props.addressId,
  () => {
    if (props.addressId) {
      fetchData();
    }
  },
  { immediate: true }
);

watch(
  () => props.value,
  () => {
    showDetails.value = props.value;
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  isLoading.value = true;
  usages.value = undefined;
  gridData.value = undefined;
  const response = await getBuildingYearlyUsages(props.addressId);
  if (response.severity <= severityCode.warning) {
    console.log("BuildingYearlyUsages fetchData response", props.addressId, response.data);
    usages.value = response.data;
    if (usages.value.energyTypes && usages.value.energyTypes.length > 0) {
      gridData.value = usages.value.energyTypes.map((energyType) => {
        const row = { energyTypeDescr: energyType.descr };
        usages.value.yearUsages.forEach((yearItem) => {
          const findValue = yearItem.energyTypeUsages.find((x) => x.energyTypeKey == energyType.key);
          if (findValue) {
            row[`year${yearItem.year}`] = findValue.value;
          }
        });
        return row;
      });
      selectedYear.value = usages.value.yearUsages[0].year;

      console.log("BuildingYearlyUsages gridData", gridData.value);
    }
  } else {
    proxy.$toaster.error(`Probleem met ophalen verbruiken: ${response.message}`);
  }
  isLoading.value = false;
}

// eslint-disable-next-line no-unused-vars
function consumption(value) {
  return value != undefined ? formatThousandNoFraction(value) : "-";
}

function toggeleDetails() {
  showDetails.value = !showDetails.value;
  console.log("BuildingYearlyUsages toggeleDetails", showDetails.value);
  emits("input", showDetails.value);
}

function onSetYear(year) {
  selectedYear.value = Number(year.substring(4));
}
</script>

<template>
  <v-layout class="widget-item usage-slider">
    <header>
      <h2>Jaarverbruiken</h2>
      <v-spacer></v-spacer>
      <v-btn text @click="toggeleDetails()"
        >Toon details
        <v-icon v-if="showDetails" right>mdi-chevron-up</v-icon>
        <v-icon v-else right>mdi-chevron-down</v-icon></v-btn
      >
      <div class="widget-actions">
        <information-dropdown>
          <template v-slot:header>
            <h2>Jaarverbruiken</h2>
          </template>
          <p>
            Hier wordt het totaal van uw energieverbruik getoond. Er wordt een onderverdeling gemaakt tussen elektra, gas en warmte (indien van toepassing). Tevens wordt deze data gesommeerd en in een
            totaal aan kilogram CO₂ weergeven.
          </p>
          <h4>Toon details</h4>
          <p>Hier ziet u op basis van welke energie aansluitingen de totalen gebaseerd zijn.</p>
        </information-dropdown>
      </div>
    </header>
    <perfect-scrollbar :options="{ wheelPropagation: true }">
      <loader-spinner v-if="isLoading === true" :is-loading="isLoading"></loader-spinner>
      <div v-else class="usage-wrapper">
        <v-data-table :headers="headers" hide-default-footer dense hide-default-header :items="gridData" class="usage-grid">
          <!-- <template v-slot:[`item.year`]="{ item }"> abc{{ item }} {{ item.header.text }} </template> -->
          <template #header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th v-for="header in headers" :key="header.value" role="columnheader" scope="col">
                  <span v-if="showDetails && `year${selectedYear}` == header.value" class="year selected">{{ header.text }}</span>
                  <span v-else-if="showDetails && header.text" class="year"
                    >{{ header.text
                    }}<v-btn icon small color="primary" @click="onSetYear(header.value)">
                      <v-icon small> mdi-eye </v-icon>
                    </v-btn></span
                  >
                  <span v-else>{{ header.text }}</span>
                </th>
              </tr>
            </thead>
          </template>

          <template v-for="header in headers.filter((x) => x.value.startsWith('year'))" #[`item.${header.value}`]="{ value }">
            {{ consumption(value) }}
          </template>
        </v-data-table>
      </div>
    </perfect-scrollbar>
    <div class="mt-3">
      <building-usages v-if="showDetails" :addressId="props.addressId" :year="selectedYear"></building-usages>
    </div>
  </v-layout>
</template>
