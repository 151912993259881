import { ref } from "vue";
import { defineStore } from "pinia";
import Oidc from "oidc-client";
import { useAppConfigurationStore } from "@/stores/appconfig";

const useAuthStore = defineStore("auth", () => {
  const mgr = ref(null);

  const demoUserAccessToken = ref(undefined);

  const isDemoUser = ref(false);

  function init(options) {
    console.log("create Oidc.UserManager ", options);

    mgr.value = new Oidc.UserManager({
      authority: options.identityUrl,
      client_id: process.env.VUE_APP_CLIENT_ID,
      redirect_uri: `${options.hostUrl}callback`,
      response_type: "code",
      scope: "openid profile email " + process.env.VUE_APP_SCOPE,
      post_logout_redirect_uri: options.hostUrl,
      store: new Oidc.WebStorageStateStore({ store: window.localStorage }),
      automaticSilentRenew: true,
      silent_redirect_uri: `${options.hostUrl}silent-renew.html`,
      accessTokenExpiringNotificationTime: 60,
    });
  }

  //voordat een functie aangeroepen wordt kan gescheckt worden of de mgr valid is
  //in new Vue() worden zowel pinia en router geinitialiseerd en daardoor wordt router.beforeEach aangeroepen
  //deze store kon nog niet geinitialiseerd worden daarvoor maar moet wel gebruikt worden
  //in vue3 kan dat anders en wel
  function checkMgr() {
    if (!mgr.value) {
      const appconfigStore = useAppConfigurationStore();
      init(appconfigStore.data);
    }
  }

  async function login() {
    checkMgr();
    // console.log('login');
    return await mgr.value.signinRedirect();
  }

  async function logout() {
    checkMgr();
    // console.log('logout');
    const user = await mgr.value.getUser();
    return await mgr.value.signoutRedirect({ id_token_hint: user.id_token });
  }

  function logoutDemo() {
    if (isDemoUser.value) {
      isDemoUser.value = false;
      demoUserAccessToken.value = undefined;
      return true;
    } else {
      return false;
    }
  }

  async function getAccessToken() {
    checkMgr();
    if (isDemoUser.value) {
      //console.log("getAccessToken, access_token demo: ", token);
      return demoUserAccessToken.value;
    } else {
      const user = await mgr.value.getUser();
      if (user) {
        //console.log("getAccessToken, access_token user: ", user);
        return user.access_token;
      } else {
        return undefined;
      }
    }
  }

  async function signinRedirectCallback(a, b, c, d) {
    checkMgr();
    console.log("authStore, signinRedirectCallback", a, b, c, d);
    const cb = await mgr.value.signinRedirectCallback();
    console.log("authStore, signinRedirectCallback mgr: ", mgr.value);

    return cb;
  }

  async function signinSilent(prm) {
    checkMgr();
    console.log("authStore, signinSilent");
    return await mgr.value.signinSilent(prm);
  }

  async function signinSilentCallback() {
    checkMgr();
    console.log("signinSilentCallback");
    return await mgr.value.signinSilentCallback();
  }

  async function isLoggedIn() {
    checkMgr();
    //console.log("authStore, isLoggedIn, isdemo?: ", isDemoUser.value);
    if (isDemoUser.value) {
      return true;
    } else {
      const user = await mgr.value.getUser();
      const validUser = !!user && !user.expired;
      //console.log("authStore, isLoggedIn, validUser?: ", validUser);
      return validUser;
    }
  }

  function setDemoUserAccessToken(item) {
    isDemoUser.value = true;
    demoUserAccessToken.value = item.token;
  }

  async function isAllowedForApplication() {
    checkMgr();
    console.log("authStore, isAllowedForApplication, isdemo?: ", isDemoUser.value);
    if (isDemoUser.value) {
      return true;
    } else {
      const user = await mgr.value.getUser();
      return user.profile.innax_application && user.profile.innax_application.includes(process.env.VUE_APP_CLIENT_ID);
    }
  }

  return { init, login, logout, logoutDemo, getAccessToken, signinSilent, signinSilentCallback, signinRedirectCallback, isLoggedIn, setDemoUserAccessToken, isAllowedForApplication };
});

export { useAuthStore };
