<script lang="ts" setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import { getBuildingMeasures } from "@/services/api/chart.api";

const { formatDateApi } = useDateTimeFormatter();

const props = defineProps({
  token: {
    type: String,
    default: "",
  },
  certified: {
    type: Boolean,
    default: true,
  },
  refDate: {
    type: Date,
  },
});

const chart = ref({
  status: {},
  any: undefined,
});
const isLoading = ref(false);

onMounted(() => {
  fetchBuildingMeasuresData();
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchBuildingMeasuresData() {
  isLoading.value = true;
  const response = await getBuildingMeasures(props.token, props.certified, formatDateApi(props.refDate));
  if (response) {
    chart.value = response.data;
  } else {
    proxy.$toaster.error("Ophalen van de maatregelen data is mislukt!");
  }
  isLoading.value = false;
}
</script>

<template>
  <v-layout class="widget-item">
    <header>
      <slot name="headerwidget"></slot>
      <div class="widget-actions">
        <router-link :to="{ name: 'AppliedOverview' }" class="widget-link">Bekijk alle maatregelen</router-link>
        <information-dropdown>
          <template v-slot:header>
            <slot name="headerwidget"></slot>
          </template>
          <slot name="infotext"></slot>
        </information-dropdown>
      </div>
    </header>

    <loader-spinner v-if="isLoading === true" :is-loading="isLoading" :min-height="200"></loader-spinner>
    <!-- Charts werken niet als ze in een v-if constructie zitten -->
    <div v-show="isLoading === false && chart.any == true">
      <div class="widget-wrapper">
        <div class="widget-chart measures">
          <chart-measures :source-data="chart" :height="150"></chart-measures>
        </div>
      </div>
      <div class="widget-wrapper">
        <div class="widget-chart">
          <chart-applied-status :width="200" :height="200" legendPosition="legend-right" :source-data="chart.status"></chart-applied-status>
        </div>
      </div>
    </div>
    <div v-show="isLoading === false && chart.any == false">
      <slot name="nomeasurestext"></slot>
    </div>
  </v-layout>
</template>
