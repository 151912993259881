var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "pt-5" },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-pagination", {
                key: _setup.selectedKey,
                attrs: {
                  value: _setup.selectedPageNumber,
                  length: _setup.selectedPageCount,
                  "total-visible": 7,
                },
                on: { input: _setup.onPageNumberChange },
              }),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "auto" } }, [
            _vm._v(" Regels per pagina "),
          ]),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c("v-select", {
                attrs: {
                  value: _setup.selectedPageSize,
                  items: _setup.pageSizes,
                  "item-text": "key",
                  "item-value": "value",
                },
                on: { input: _setup.onPageSizeChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }