export default function useMessages() {
  const applied = {
    // Wijzigbare maatregelvelden (mutatielog)
    Description: "Toelichting",
    Investment: "Investering onafh.",
    InvestmentNaturalMoment: "Invest. nat. mom.",
    ReturnOnInvestment: "Terugverdientijd onafh.",
    ReturnOnInvestmentNaturalMoment: "Terugverdientijd nat. mom.",
    ReductionCarbonDioxide: "CO₂ reductie",
    ReductionElectricity: "Elektra kWh reductie",
    ReductionGas: "Gas m³ reductie",
    ReductionHeat: "Warmte GJ reductie",
    ReductionTotal: "Totale GJ reductie",
    ProposalDate: "Voorsteldatum",
    AchievedDate: "Realisatiedatum",
    PlannedDate: "Plandatum",
    VariantType: "Variant",
    Status: "Status",
    StatusKey: "Status",
    RecordCreation: "Aangemaakt",
    RecordDeletion: "Verwijderd",

    // Enum Status
    Applicable: "(Nog) niet uitgevoerd",
    ApplicableButNotPresent: "Niet aanwezig",
    ApplicableButNotProfitable: "Niet rendabel",
    ApplicableButNotTechnicallyFeasible: "Technisch niet haalbaar",
    ApplicableButObviousMomentNotReached: "Natuurlijk moment nog niet voorgedaan", 
    ApplicableButPartlyExecuted: "Gedeeltelijk uitgevoerd",
    InvalidConditions: "Voldoet niet aan randvoorwaarden",
    Executed: "Uitgevoerd",
    None: "Geen",
    NotApplicable: "Niet van toepassing",
    Unknown: "Onbekend",
  };

  const datascore = {
    UnknownMessageCode: "Reden onbekend",

    // Datascore:
    NoLabels: "Een definitief label ontbreekt of is verlopen",
    NoOperationalLabels: "Een operationeel label ontbreekt of is verlopen",
    NoUsages: "Er zijn geen verbruiken bekend voor het vorige jaar",
    NoGoSurface: "Er is geen gebruiksoppervlakte (GO) opgegeven",
    NoMeasures: "Er zijn geen toegepaste maatregelen aanwezig",
    NoEcmFunction: "Er is geen ECM gebouwsfunctie opgegeven",
    NoWEiiCategory: "Er is geen WEii gebouwscategorie opgegegeven",
    NoConstructionProperties: "Er zijn geen bouwkundige gegevens aanwezig",
    NoInstallations: "Er zijn geen installatiegegevens aanwezig",
  };

  const errorCodes = {
    NotUpdatedDueToMandatoryFieldsInvestmentAndReturnOnInvestment: "Investering en Terugverdientijd zijn verplicht",
    NotUpdatedDueToMandatoryReductionFields: "Voer minimaal Reductie elektra, gas of warmte in.",
  };

  const measureCheckCodes = {
    301: "Categorie is niet aangevinkt, maar status geeft aan dat maatregel wel van toepassings is.",
    310: "EP-Online label niet beschikbaar voor locatie.",
    401: "Maatregel heeft een ongeldige status.",
    410: 'Er moet een "Opdrachtgever" als contactpersoon zijn op partij niveau.',
    411: 'De rol voor deze locatie is "Eigenaar", dan moet er een "Gebruiker/Uitvoerder" als contactpersoon zijn.',
    412: 'De rol voor deze locatie is "Gebruiker/Uitvoerder", dan moet er een "Eigenaar" als contactpersoon zijn.',
    420: 'De status is "Uitgevoerd", maar de uitvoerdatum is niet gevuld.',
    421: 'De status is "Voldoet niet aan randvoorwaarden", maar er is geen randvoorwaarde geselecteerd waar niet aan voldaan wordt.',
    430: "Er wordt hernieuwbare energie gebruikt, maar er is geen verdeling opgegeven.",
    431: "EP-Online label ongeldig voor locatie.",
    432: "Geen verbruiken voor locatie.",
  };

  function msgApplied(msgCode) {
    return msgCode in applied ? applied[msgCode] ?? msgCode : msgCode;
  }

  function msgDataScore(msgCode) {
    return msgCode in datascore ? datascore[msgCode] ?? msgCode : msgCode;
  }

  function msgErrorCode(msgCode) {
    return msgCode in errorCodes ? errorCodes[msgCode] ?? msgCode : msgCode;
  }

  function msgMeasureCheckCode(msgCode) {
    return msgCode in measureCheckCodes ? measureCheckCodes[msgCode] ?? msgCode : msgCode;
  }

  return {
    msgApplied,
    msgDataScore,
    msgErrorCode,
    msgMeasureCheckCode,
  };
}
