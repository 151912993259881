<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";
import LocationLabelGrid from "@/components/app/homes/LocationLabelGrid.vue";
import { getLocationLabelFilterOptions, getLabelDocuments, severityCode } from "@/services/api/locationlabel.api";
import { FilterTokenName, FilterStatus, mapFilterResponseStatus } from "@/stores/filtertoken";
import handleFilterTokens27 from "@/composables/handleFilterTokens27";
import { labels } from "@/composables/miscellaneous.js";
import { useUserStore } from "@/stores/user";
import { mdiFileDownload } from "@mdi/js";

const { mapFilterResult } = handleFilterTokens27(FilterTokenName.LocationLabel);
const userStore = useUserStore();

const filters = ref([
  {
    name: "Volledigheid",
    fieldName: "dataScore",
    type: "list",
    options: [
      { text: "OK (Groen)", id: "green" },
      { text: "Waarschuwing (Oranje)", id: "orange" },
      { text: "Fout (Rood)", id: "red" },
    ],
  },
  {
    name: "VHE nummer",
    fieldName: "reference",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Complexnummer",
    fieldName: "parentReference",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  // {
  //   name: "Omschrijving",
  //   fieldName: "description",
  //   type: "search",
  //   options: [],
  // },
  {
    name: "Adres",
    fieldName: "address",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Plaats",
    fieldName: "city",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  // {
  //   name: "EP1",
  //   fieldName: "vabiCurrentLabelBeng1",
  //   type: "range",
  //   options: [],
  // },
  {
    name: "EP2",
    fieldName: "vabiCurrentLabelBeng2",
    type: "range",
    options: [],
    status: FilterStatus.None,
  },
  // {
  //   name: "EP3",
  //   fieldName: "vabiCurrentLabelBeng3",
  //   type: "range",
  //   options: [],
  // },
  {
    name: "Vabi Huidig Label",
    fieldName: "vabiCurrentLabel",
    type: "list",
    options: [...labels],
  },
  {
    name: "EI",
    fieldName: "vabiRegisteredEnergyIndex",
    type: "range",
    options: [],
  },
  // {
  //   name: "EP1",
  //   fieldName: "vabiRegisteredLabelBeng1",
  //   type: "range",
  //   options: [],
  // },
  {
    name: "EP2",
    fieldName: "vabiRegisteredLabelBeng2",
    type: "range",
    options: [],
  },
  // {
  //   name: "EP3",
  //   fieldName: "vabiRegisteredLabelBeng3",
  //   type: "range",
  //   options: [],
  // },
  {
    name: "Vabi label",
    fieldName: "vabiRegisteredLabel",
    type: "list",
    options: [...labels],
  },
  {
    name: "EI",
    fieldName: "epOnlineEnergyIndex",
    type: "range",
    options: [],
  },
  // {
  //   name: "EP1",
  //   fieldName: "epOnlineLabelBeng1",
  //   type: "range",
  //   options: [],
  // },
  {
    name: "EP2",
    fieldName: "epOnlineLabelBeng2",
    type: "range",
    options: [],
  },
  // {
  //   name: "EP3",
  //   fieldName: "epOnlineLabelBeng3",
  //   type: "range",
  //   options: [],
  // },
  {
    name: "EPOnline label",
    fieldName: "epOnlineLabel",
    type: "list",
    options: [...labels],
  },
  // {
  //   name: "Certificaat",
  //   fieldName: "certificate",
  //   type: "search",
  //   options: [],
  // },
]);

async function handleFilterSearch(index, text) {
  console.log("LoactionlabelOverview handleFilterSearch filterData: ", index, text);
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    case "dataScore":
      {
        console.log("getFilterOptions, filters.value[index]: ", filters.value[index]);
        result = filters.value[index].options;
      }
      break;
    case "vabiCurrentLabel":
      result = filters.value[index].options;
      break;
    case "vabiRegisteredLabel":
      result = filters.value[index].options;
      break;
    case "epOnlineLabel":
      result = filters.value[index].options;
      break;
    default:
      if (text && text.length > 0) {
        const response = await getLocationLabelFilterOptions(partyReference.value, filters.value[index].fieldName, text);
        //console.log("LoactionlabelOverview handleFilterSearch response: ", response);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
}

//hack voor vue 2.7
//const proxy = getCurrentInstance().proxy;

async function handleFiltersChanged(newFilterData) {
  console.log("LoactionlabelOverview handleFiltersChanged filterData: ", newFilterData);
  if (newFilterData.length > 0) {
    gridLoaded.value = false;
    filterData.value = [...newFilterData];
  } else {
    filterData.value = [];
  }
}

const partyReference = ref();
const filterData = ref([]);
const gridLoaded = ref(false);
const downloadBusy = ref(false);

watch(
  () => userStore.currentPartyReference,
  () => {
    partyReference.value = userStore.currentPartyReference.toString();
    filterData.value = [];
  },
  { immediate: true }
);

function dataLoaded() {
  gridLoaded.value = true;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function downloadDocuments() {
  downloadBusy.value = true;
  const response = await getLabelDocuments(partyReference.value, filterData.value);
  console.log("LoactionlabelOverview downloadDocuments ", response);
  if (response.severity <= severityCode.warning) {
    console.log("LoactionlabelOverview downloadDocuments ", response);
    saveToFile(response.filename, response.data);
  } else {
    proxy.$toaster.error("Ophalen van energielabels is mislukt! ", response.message);
  }
  downloadBusy.value = false;
  return response;
}

function saveToFile(name, data) {
  console.log("LoactionlabelOverview saveToFile ", name);
  const a = document.createElement("a");
  a.href = URL.createObjectURL(data);
  a.download = name;
  a.click();
}
</script>

<template>
  <v-container fluid class="app-locationlabel-overview">
    <v-row>
      <v-col class="app-welcome-message">
        <h1>Overzicht van energielabels</h1>
        <p>In dit overzicht worden alle Vabi en EPOnline energielabels van gebouwen in uw portefeuille getoond. Aan de hand van het filter kunt u uw gewenste inzicht creëren.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3" sm="6">
        <building-overview-energy-labels v-if="partyReference && gridLoaded" :partyReference="partyReference" :filterData="filterData" partyType="EnergyLabels" labelSource="VabiApi">
          <template v-slot:headerwidget>
            <h3>Verdeling huidige VABI labels</h3>
          </template>
        </building-overview-energy-labels>
      </v-col>
      <v-col md="3" sm="6">
        <building-overview-energy-labels v-if="partyReference && gridLoaded" :partyReference="partyReference" :filterData="filterData" partyType="EnergyLabels" labelSource="EPOnline">
          <template v-slot:headerwidget>
            <h3>Verdeling EPOnline labels</h3>
          </template>
        </building-overview-energy-labels>
      </v-col>
      <v-col md="2" sm="3">
        <building-energylabels-age v-if="partyReference && gridLoaded" :partyReference="partyReference" :filterData="filterData" labelSource="EPOnline">
          <template v-slot:headerwidget>
            <h3>Leeftijd EPOnline labels</h3>
          </template>
        </building-energylabels-age>
      </v-col>
    </v-row>
    <v-row class="overview-wrapper">
      <v-col>
        <article class="inner">
          <v-row class="overview-filters align-items-start">
            <v-col cols="auto">
              <filter-set :name="FilterTokenName.LocationLabel" :filters="filters" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="text-right">
              <v-btn class="primary" @click="downloadDocuments()" :disabled="downloadBusy">
                <v-icon dark left>{{ mdiFileDownload }}</v-icon>
                download (meerdere) labels
                <span v-if="downloadBusy" class="loadingDots"></span>
              </v-btn>
            </v-col>
          </v-row>

          <location-label-grid :partyReference="partyReference" :filterData="filterData" @fetch-data-complete="dataLoaded"></location-label-grid>
        </article>
      </v-col>
    </v-row>
  </v-container>
</template>
