var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-layout", { staticClass: "widget-item" }, [
    _c("h3", [_vm._v("Functies van de gebouwen")]),
    _c(
      "div",
      { staticClass: "widget-wrapper widget-chart" },
      [
        _setup.isLoading === true
          ? _c("loader-spinner", {
              attrs: { "is-loading": _setup.isLoading, "min-height": 170 },
            })
          : _c("chart-building-functions", {
              attrs: { height: 170, sourceData: _setup.buildingFunctions },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }