var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-measures-overview", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "app-welcome-message" }, [
            _c("h1", [_vm._v("Maatregelen")]),
            _c("p", [
              _vm._v(
                "In dit overzicht worden alle maatregelen voor uw portefeuille getoond. Aan de hand van het filter kunt u uw gewenste inzicht creëren."
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "4", sm: "3" } },
            [
              _c("applied-overview-types", {
                attrs: { token: _setup.tokenDelayed },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "4", sm: "3" } },
            [
              _c("applied-overview-return-on-investment", {
                attrs: { token: _setup.tokenDelayed },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "4", sm: "3" } },
            [
              _c("applied-overview-statusses", {
                attrs: { token: _setup.tokenDelayed },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "d-none" },
        [
          _c(
            "v-col",
            [
              _c("chart-investment-vs-savings", {
                staticClass: "app-measures-chart",
                attrs: {
                  years: _setup.chartData.years,
                  savings: _setup.chartData.savings,
                  investments: _setup.chartData.investments,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c("v-col", [
            _c(
              "article",
              { staticClass: "inner" },
              [
                _c(
                  "v-row",
                  { staticClass: "overview-filters align-items-start" },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c("filter-set", {
                          attrs: {
                            name: _setup.FilterTokenName.Applied,
                            filters: _setup.filters,
                          },
                          on: {
                            "filter-search": _setup.handleFilterSearch,
                            "filters-changed27": _setup.handleFiltersChanged,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary",
                            attrs: { dark: "" },
                            on: {
                              click: function ($event) {
                                return _setup.doCreateCsv()
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { dark: "", left: "" } }, [
                              _vm._v("mdi-file-excel"),
                            ]),
                            _vm._v(" Exporteer "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "auto" } },
                      [
                        _setup.projectHasCertifiedMeasures
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "primary",
                                attrs: {
                                  dark: "",
                                  to: { name: "ProjectCreateBulkUploadXML" },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { dark: "", left: "" } },
                                  [_vm._v("mdi-file-upload")]
                                ),
                                _vm._v(" Informatieplicht "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              bottom: "",
                              disabled:
                                _setup.appliedCount >= 2 &&
                                _setup.filteredEml2023,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        { staticClass: "d-inline-block" },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "primary",
                                            attrs: {
                                              disabled:
                                                _setup.appliedCount < 2 ||
                                                !_setup.filteredEml2023,
                                              to: {
                                                name: "AppliedMutation",
                                                params: { allow: true },
                                              },
                                            },
                                          },
                                          [_vm._v(" Bulk wijzigen ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            !_setup.filteredEml2023
                              ? _c("span", [
                                  _vm._v(
                                    "Soort moet gefilterd zijn op 'EML 2023'"
                                  ),
                                ])
                              : _setup.appliedCount < 2
                              ? _c("span", [
                                  _vm._v(
                                    "Onvoldoende maatregelen om in bulk te wijzigen"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c("applied-logging-modal", {
                          attrs: { token: _setup.token },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _setup.token
                  ? _c("applied-overview-grid", {
                      attrs: {
                        token: _setup.token,
                        "hide-kind": _setup.userStore.isPartyHome,
                      },
                      on: { "fetch-data-complete": _setup.appliedLoaded },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }