import { apiClient } from "./httpClientUnauthorized";

const END_POINT = "userAccess";

const getDemoLogin = async (type) => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/demoLogin/${type}`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

export { getDemoLogin };
