var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vue-filter-set" },
    _vm._l(_vm.filters, function (filterItem, index) {
      return _c(
        "span",
        { key: filterItem.fieldName },
        [
          filterItem.show === undefined || filterItem.show == true
            ? _c("filter-item", {
                attrs: {
                  filterName: _setup.props.name,
                  type: filterItem.type,
                  name: filterItem.name,
                  filterIndex: index,
                  searchOptions: _setup.filterOptions(index),
                  "search-status": _setup.filterStatus(index),
                  "filter-set-active-filter": _setup.activeFilter,
                },
                on: {
                  "filter-search": _setup.handleFilterSearch,
                  "filter-expanded": _setup.handleFilterExpanded,
                  "filter-activated": _setup.handleFilterActivated,
                  "filter-deactivated": _setup.handleFilterDeactivated,
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }