var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { style: _setup.loaderStyles, attrs: { "fill-height": "" } },
    [
      _c(
        "v-row",
        { staticClass: "overview-loader", attrs: { align: "center" } },
        [
          _c("v-col", { staticClass: "text-center" }, [
            _c("span", {
              staticClass: "icon-loader-icon",
              class: { spinner: _vm.isLoading },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }