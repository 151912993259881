<script>
import { Line } from "vue-chartjs";

export default {
  name: "AdvancedLineChart",

  extends: Line,

  props: {
    options: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      htmlLegend: null,
    };
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
    this.htmlLegend = this.generateLegend();
  },

  watch: {
    htmlLegend: function () {
      this.$emit("chart-rendered", this.htmlLegend);
    },

    chartData: {
      handler: function () {
        this.$data._chart.update();
      },
    },
  },
};
</script>
