<script lang="ts" setup>
import { ref, computed, watch } from "vue";

import _ from "lodash";

const props = defineProps({
  height: {
    type: Number,
    default: 150,
  },

  sourceData: {
    type: Object,
    required: true,
  },

  legendPosition: {
    type: String,
    default: "legend-side",
  },
});

const refreshKey = ref(0);
const chartData = ref({
  labels: [],
  datasets: [
    {
      label: "CO₂ uitstoot verloop",
      type: "line",
      fill: "false",
      borderWidth: "3",
      borderColor: "#00A2DF",
      pointRadius: "0",
      pointStyle: "line",
      lineTension: "0",
      yAxisID: "left",
      data: [],
      order: 1,
      backgroundColor: "#00A2DF",
    },
    {
      label: "CO₂ besparing cumulatief xxxxxxxxxxxxxxxxxxxxxxxxxx",
      type: "line",
      fill: "false",
      borderWidth: "3",
      borderColor: "#9CECF9",
      pointRadius: "0",
      pointStyle: "line",
      lineTension: "0",
      yAxisID: "left",
      data: [],
      order: 1,
      backgroundColor: "#9CECF9",
    },
  ],
});
const options = ref({
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    labels: {
      padding: 30,
      usePointStyle: true,
    },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let tooltipData = data.datasets[tooltipItem.datasetIndex];
        let label = tooltipData.label;
        let dataValue = tooltipData.data[tooltipItem.index].toLocaleString("nl-NL", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        return label + ": " + dataValue + " kg";
      },
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        id: "left",
        type: "linear",
        position: "left",
        ticks: {
          callback: function (value) {
            return value.toLocaleString();
          },
        },
        gridLines: {
          drawOnChartArea: false,
        },
        scaleLabel: {
          display: "true",
          labelString: "CO₂ (in kg)",
          fontSize: "16",
        },
      },
    ],
  },
});

const chartStyles = computed(() => {
  return {
    height: `${props.height}px`,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  };
});

watch(
  () => props.sourceData,
  () => {
    console.log("ChartRoadMapCO2EmissionVsSaving, sourceData: ", props.sourceData);
    if (props.sourceData.yearlyEmission && props.sourceData.yearlySavingCumulative) {
      let keys = [];
      if (props.sourceData.yearlyEmission) {
        keys = _.merge(keys, _.keys(props.sourceData.yearlyEmission));
      }
      if (props.sourceData.yearlySavingCumulative) {
        keys = _.merge(keys, _.keys(props.sourceData.yearlySavingCumulative));
      }
      chartData.value.labels = keys;

      if (props.sourceData.yearlyEmission) {
        chartData.value.datasets[0].data = _.values(props.sourceData.yearlyEmission);
      }
      if (props.sourceData.yearlySavingCumulative) {
        chartData.value.datasets[1].data = _.values(props.sourceData.yearlySavingCumulative);
      }
      refreshKey.value++;
    }
  }
);
</script>

<template>
  <v-row class="vue-chart vue-chart-roadmap-co2emission-vs-saving pa-0" :class="props.legendPosition">
    <v-col class="vue-chart-wrapper" cols="12">
      <advanced-combined-bar-line-chart :chartData="chartData" :options="options" :styles="chartStyles" :key="refreshKey"></advanced-combined-bar-line-chart>
    </v-col>
  </v-row>
</template>
