import { apiClient, getRequest, patchRequest, severityCode } from "./httpClient";

const END_POINT = "measure";

const getApplied = async (appliedMeasureId) => {
  let errorResponse = null;
  let response = await apiClient.get(`${END_POINT}/applied`, { params: { appliedMeasureId } }).catch((error) => {
    errorResponse = error.toString();
  });
  if (errorResponse) {
    response.data = "";
  }
  return { data: response.data, error: errorResponse };
};

const getNewApplied = async (definitionId) => {
  const response = await apiClient.get(`${END_POINT}/applied/new/${definitionId}`);
  return response;
};

const updateApplied = async (appliedData) => {
  const response = await apiClient.put(`${END_POINT}/applied/${appliedData.guid}`, { ...appliedData });
  return response;
};

const deleteApplied = async (appliedMeasureId) => {
  const response = await apiClient.delete(`${END_POINT}/applied/${appliedMeasureId}`);
  return response;
};

const getAppliedByToken = async (token, currentPage, pageSize, sortBy, sortDesc) => {
  console.log("++++++++++++++++++++++++ getAppliedByToken, voor call: ");
  const response = await apiClient.get(`${END_POINT}/applied/${token}/list`, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } });
  console.log("++++++++++++++++++++++++ getAppliedByToken, response: ", response);
  return response;
};

const getAppliedFilterOptions = async (token, fieldName, text) => {
  return await getRequest(`${END_POINT}/applied/${token}/autoComplete/${fieldName}`, { text: text });
};

const getCategories = async () => {
  return getRequest(`${END_POINT}/categories`);
};

const getBuildingCategories = async (buildingId) => {
  return getRequest(`${END_POINT}/building/${buildingId}/categories`);
};

const getDefinitions = async (ecmFunctionCode, certified, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  const response = await apiClient.post(
    `${END_POINT}/definition/list`,
    { filterData: filterData },
    { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc, ecmFunctionCode: ecmFunctionCode, certified: certified } }
  );
  return response;
};

const getDefinitionFilterOptions = async (ecmFunctionCode, certified, fieldName, text) => {
  return await getRequest(`${END_POINT}/definition/autoComplete/${fieldName}`, { text: text, ecmFunctionCode: ecmFunctionCode, certified: certified });
};

const getBuildingApplied = async (addressId, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  const response = await apiClient.post(
    `${END_POINT}/applied/building/${addressId}`,
    { filterData: filterData },
    { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } }
  );
  return response;
};

const getBuildingAppliedFilterOptions = async (addressId, fieldName, text) => {
  return await getRequest(`${END_POINT}/applied/building/${addressId}/autoComplete/${fieldName}`, { text: text });
};

const getAppliedStatusses = async (token) => {
  const response = await apiClient.get(`${END_POINT}/${token}/ecmAppliedStatusses`);
  return response.data;
};

const getAllAppliedStatusses = async () => {
  const response = await apiClient.get(`${END_POINT}/ecmAppliedStatusses`);
  return response.data;
};

const getBuildingAppliedStatusses = async (addressId, fieldName, text) => {
  const response = await apiClient.get(`${END_POINT}/applied/building/${addressId}/ecmAppliedStatusses`, { params: { text: text } });
  return response.data;
};

const getAllKinds = async () => {
  const response = await apiClient.get(`${END_POINT}/ecmKinds`);
  return response.data;
};

const getBuildingAppliedKinds = async (addressId, fieldName, text) => {
  const response = await apiClient.get(`${END_POINT}/applied/building/${addressId}/ecmAppliedKinds`, { params: { text: text } });
  return response.data;
};

const getKinds = async (token) => {
  const response = await apiClient.get(`${END_POINT}/${token}/ecmKinds`);
  return response.data;
};

const getAppliedLogByToken = async (token, currentPage, pageSize, sortBy, sortDesc) => {
  const response = await apiClient.get(`${END_POINT}/applied/${token}/logs/list`, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } });
  return response;
};

const getAppliedLogFilterOptions = async (token, fieldName, text) => {
  return await getRequest(`${END_POINT}/applied/${token}/logs/autoComplete/${fieldName}`, { text: text });
};

const patchAppliedBulk = async (request) => {
  return patchRequest(`${END_POINT}/applied/bulk`, request);
};

export {
  severityCode,
  getNewApplied,
  getApplied,
  updateApplied,
  deleteApplied,
  getCategories,
  getBuildingCategories,
  getDefinitions,
  getDefinitionFilterOptions,
  getAppliedByToken,
  getAppliedFilterOptions,
  getBuildingApplied,
  getBuildingAppliedFilterOptions,
  getBuildingAppliedStatusses,
  getAppliedStatusses,
  getAllAppliedStatusses,
  getAllKinds,
  getBuildingAppliedKinds,
  getKinds,
  getAppliedLogFilterOptions,
  getAppliedLogByToken,
  patchAppliedBulk,
};
