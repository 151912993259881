<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";
import { getParisProofColorClass } from "@/composables/colors";
import { getParisProofScore, severityCode } from "@/services/api/building.api";

const props = defineProps({
  addressId: String,
  dataScore: Array,
});

const showWarning = ref(false);
const warningTextScore = ref("");
const warningTextNorm = ref("");
const score = ref(null);
const norm = ref(null);
const isLoading = ref(true);

watch(
  () => props.addressId,
  () => {
    loadParisProofScore();
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function loadParisProofScore() {
  isLoading.value = true;
  console.log("BuildingParisProofScore loadParisProofScore ", props.addressId);
  var response = await getParisProofScore(props.addressId);
  console.log("BuildingParisProofScore loadParisProofScore ", response);
  if (response.severity <= severityCode.warning) {
    score.value = response.data.score;
    norm.value = response.data.norm;
  } else {
    proxy.$toaster.error(`Probleem met ophalen Paris Proof Score`);
  }
  isLoading.value = false;
}

watch(
  () => props.dataScore,
  () => {
    if (props.dataScore) {
      const NoUsages = props.dataScore.includes("NoUsages");
      const NoGoSurface = props.dataScore.includes("NoGoSurface");
      const NoWEiiCategory = props.dataScore.includes("NoWEiiCategory");
      if (NoUsages || NoGoSurface) {
        warningTextScore.value =
          "Om de Paris Proof Score voor het huidige gebouw te kunnen berekenen hebben we nog " +
          (NoUsages ? "de energiedata" : "") +
          (NoUsages && NoGoSurface ? " en " : "") +
          (NoGoSurface ? "de gebruiksoppervlakte" : "") +
          " nodig";
        showWarning.value = true;
      }

      if (NoWEiiCategory) {
        warningTextNorm.value = "Om de Paris Proof Norm voor het huidige gebouw te kunnen bepalen hebben we nog de WEii Klasse nodig";
        showWarning.value = true;
      }
    }
  },
  { immediate: false }
);
</script>

<template>
  <perfect-scrollbar>
    <h2>Paris Proof Score</h2>
    <information-dropdown>
      <template v-slot:header>
        <h2>Paris Proof?</h2>
      </template>
      <p>U ziet hier de Paris Proof Score op basis van de WEii methodiek van uw gebouw.</p>

      <p>De Paris Proof Score is berekend op basis van het WEii protocol versie 2.0.</p>

      <h4>Waar komt de Paris Proof norm vandaan?</h4>
      <p>
        Klik
        <a class="nav-link-light" href="https://www.weii.nl" target="_blank"><b>hier</b></a>
        voor een uitgebreide uitleg.
      </p>
    </information-dropdown>

    <warning-dropdown v-if="showWarning">
      <template v-slot:header>
        <h2>Ontbrekende data</h2>
      </template>
      <p>{{ warningTextScore }}</p>
      <p>{{ warningTextNorm }}</p>
    </warning-dropdown>

    <v-container>
      <p class="paris-score">
        <span v-if="isLoading">
          <loader-spinner :is-loading="true"></loader-spinner>
        </span>
        <span v-else>
          <span v-if="score" :class="getParisProofColorClass(score, norm)">
            Score:
            <number :from="0" :to="score" :duration="1.6"></number>&nbsp;kWh/m²<br />
          </span>
          <span v-else> Score: -<br /> </span>
          <span v-if="norm" :class="getParisProofColorClass(score, norm)"> Norm: {{ norm }}&nbsp;kWh/m² </span>
          <span v-else> Norm: -<br /> </span>
        </span>
      </p>
    </v-container>
    <v-container>
      <v-row>
        <v-col md="12" sm="6">
          <div class="paris-proof-score">
            <eiffel-tower :score="score" :norm="norm"></eiffel-tower>
          </div>
        </v-col>
        <v-col md="12" sm="6">
          <div class="paris-proof-legend">
            <div class="item"><span class="color color-red"></span> Onvoldoende score</div>
            <div class="item"><span class="color color-orange"></span> Matige score</div>
            <div class="item"><span class="color color-green"></span> Onder of gelijk aan de norm</div>
          </div>

          <div class="paris-proof-about">
            <h2>Over Paris Proof</h2>
            <p>
              <strong>Paris Proof</strong> is gebaseerd op de door Dutch Green Building Council opgestelde doelwaarden voor het realiseren van de 2050-doelstelling van het akkoord van Parijs.
              Uitgangspunt bij de getalswaarde van de Paris Proof doelstelling per gebouwtype is dat met de in 2050 verwachte beschikbare hoeveelheid duurzame energie alle gebouwen van energie kunnen
              worden voorzien als de gebouwen een energiegebruik hebben dat maximaal gelijk is aan de Paris Proof getalswaarden. Daarmee zou voldaan worden aan de doelselling van het akkoord van
              Parijs en het Nederlandse klimaatakkoord.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </perfect-scrollbar>
</template>
