<script>
import { Bar } from "vue-chartjs";

export default {
  name: "AdvancedCombinedBarLineChart",

  extends: Bar,

  props: ["chartData", "options"],

  data() {
    return {
      htmlLegend: null,
    };
  },

  mounted() {
    this.renderLineChart();
  },

  watch: {
    htmlLegend: function () {
      this.$emit("chart-rendered", this.htmlLegend);
    },
  },

  methods: {
    renderLineChart() {
      this.renderChart(this.chartData, this.options);
      this.htmlLegend = this.generateLegend();
    },
  },
};
</script>

// https://vue-chartjs.org/guide/#example // https://www.chartjs.org/docs/2.9.4/charts/mixed.html Dit voorbeeld
