var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "warning-popup", class: { active: _setup.toggled } },
    [
      _c(
        "v-icon",
        {
          ref: "input",
          staticClass: "icon-warning",
          on: {
            click: _setup.toggle,
            blur: function ($event) {
              return _setup.blur()
            },
          },
        },
        [_vm._v("mdi-alert")]
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _setup.toggled === true,
                expression: "toggled === true",
              },
            ],
            staticClass: "warning-dropdown",
          },
          [
            _c(
              "div",
              { staticClass: "warning-dropdown-header" },
              [_vm._t("header")],
              2
            ),
            _c(
              "div",
              { staticClass: "warning-dropdown-content" },
              [_vm._t("default")],
              2
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }