<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import _ from "lodash";

import { getDataAvailability } from "@/services/api/chart.api";
import useMessages from "@/composables/messages.js";

const { msgDataScore } = useMessages();

const props = defineProps({
  token: String,
});

const chart = ref({
  messages: [],
  percent: 0,
});
const isLoading = ref(true);

const hasDataAvailabilityMessages = computed(() => {
  return _.isEmpty(chart.value.messages) === false;
});

watch(
  () => props.token,
  () => {
    fetchDataAvailabilityData();
  },
  { immediate: true }
);

async function fetchDataAvailabilityData() {
  isLoading.value = true;
  const response = await getDataAvailability(props.token);
  if (response) {
    chart.value = response.data;
    isLoading.value = false;
  } else {
    isLoading.value = false;
    const app = getCurrentInstance();
    app.proxy.$toaster.error("Ophalen van de availability data is mislukt!");
  }
}
</script>
<template>
  <v-layout class="widget-item">
    <header>
      <h2>Volledigheid data</h2>
      <div class="widget-actions">
        <information-dropdown>
          <template v-slot:header>
            <h2>Goed op weg!</h2>
          </template>
          <p>
            Betrouwbare duurzaamheidsdata helpt om onderbouwde beslissingen te nemen in de verduurzamingsstrategie. INNAX begint daarom met het in kaart brengen van de beschikbare data en het bepalen
            van de kwaliteit hiervan. Belangrijke aspecten daarbij zijn:
          </p>
          <ul>
            <li>Volledigheid</li>
            <li>Juistheid</li>
            <li>Actualiteit</li>
          </ul>
          <br />
          <p>De Paris Proof Monitor toont hier een aantal verbeterslagen voor uw portefeuille waar deze nog geen volledige data dekking/coverage heeft.</p>
        </information-dropdown>
      </div>
    </header>
    <loader-spinner v-show="isLoading === true" :is-loading="isLoading"></loader-spinner>
    <div class="widget-wrapper" v-show="isLoading === false" :min-height="200">
      <div class="widget-chart data-availability">
        <percentage-chart :percentage="chart.percent" :height="200" :width="200"></percentage-chart>
      </div>
      <div v-show="hasDataAvailabilityMessages" class="widget-info">
        <h3>Dit missen we nog</h3>
        <span>Voor &eacute;&eacute;n of meerdere panden geldt</span>
        <ul>
          <li v-for="(msg, index) in chart.messages" :key="index">
            {{ msgDataScore(msg) }}
          </li>
        </ul>
      </div>
    </div>
  </v-layout>
</template>
