var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-main", [
    _c(
      "div",
      { staticClass: "account-page" },
      [
        _c(
          "div",
          { staticClass: "welcome" },
          [
            _c(
              "div",
              { staticClass: "logo" },
              [
                _c("v-img", {
                  staticClass: "image",
                  attrs: {
                    alt: "INNAX Logo",
                    src: require("@/assets/img/logo.png"),
                  },
                }),
              ],
              1
            ),
            _c(
              "v-tabs",
              {
                staticClass: "tabToggles",
                attrs: { "active-class": "active" },
              },
              [
                _c("v-tabs-slider", { attrs: { hidden: "" } }),
                _c("v-tab", { staticClass: "tab" }, [_vm._v("Aanmelden")]),
                _c("v-tab", { staticClass: "tab" }, [
                  _vm._v("Meer informatie"),
                ]),
                _c("v-tab-item", [_c("account-login")], 1),
                _c("v-tab-item", [_c("account-info")], 1),
              ],
              1
            ),
          ],
          1
        ),
        _c("v-layout", { staticClass: "main-image" }, [
          _c("p", [
            _vm._v(" Nog "),
            _c("strong", [_vm._v(_vm._s(_setup.timeCountDown) + " dagen")]),
            _vm._v(" om"),
            _c("br"),
            _vm._v("Paris Proof te halen "),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }