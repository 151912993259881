<script lang="ts" setup>
import { onMounted } from "vue";
import { getDemoLogin } from "@/services/api/demo.api";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import router from "@/router/index";

const props = defineProps({
  type: String,
});

onMounted(async () => {
  const authStore = useAuthStore();
  const userStore = useUserStore();
  try {
    const demoUser = await getDemoLogin(props.type);
    console.log("DemoLogin, authorized: ", demoUser, props.type);

    authStore.setDemoUserAccessToken({ token: demoUser.data });
    if (demoUser.data) {
      const authorized = await authStore.isAllowedForApplication();
      console.log("DemoLogin, authorized: ", authorized);
      if (authorized) {
        await userStore.setUserAccessData();
        router.push({ name: "Dashboard" }).catch(() => {});
      } else {
        console.log("DemoLogin, user unauthorized for application");
        router.push({ name: "Unauthorized" });
      }
    } else {
      console.log("DemoLogin unknown, user unauthorized for application");
      router.push({ name: "Unauthorized" });
    }
  } catch (error) {
    console.log("DemoLogin, error: ", error);
    router.push({ name: "Unauthorized" });
  }
});
</script>

<template>
  <v-container fill-height class="callback">
    <v-row>
      <v-col>
        <div>Aanmelding voltooien...</div>
      </v-col>
    </v-row>
  </v-container>
</template>
