var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-layout", { staticClass: "widget-item" }, [
    _c("h3", [_vm._v("Gemiddelde Paris Proof Score doorlopend")]),
    _c("div", { staticClass: "widget-wrapper" }, [
      _c(
        "div",
        { staticClass: "widget-chart" },
        [
          _c("loader-spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _setup.isLoading === true,
                expression: "isLoading === true",
              },
            ],
            attrs: { "is-loading": _setup.isLoading, "min-height": 170 },
          }),
          _c("chart-roadmap-investment-vs-pps", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _setup.isLoading === false,
                expression: "isLoading === false",
              },
            ],
            attrs: {
              height: 500,
              legendPosition: "legend-bottom",
              "source-data": _setup.chartdata,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }