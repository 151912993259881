var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-layout", { staticClass: "widget-item" }, [
    _c("header", [
      _c("h2", [_vm._v("Energiekompas")]),
      _c(
        "div",
        { staticClass: "widget-actions" },
        [
          _c(
            "information-dropdown",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [_c("h2", [_vm._v("Energiekompas")])]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("p", [
                _vm._v(
                  " Het EnergieKompas combineert de krachten van beschikbare middelen en methodes (het energielabel en de WEii-score), en geeft in één oogopslag inzicht in de verduurzamingsroute van een pand. Wat is de meest effectieve route om gebouwen te verduurzamen tot een Paris Proof niveau? Veel bestaande wet- en regelgeving en beschikbare instrumenten bieden geen eenduidige oplossing. Het EnergieKompas wel. Door het energielabel en de WEii-score (werkelijk gemeten energiegebruik) te combineren, maakt het EnergieKompas voor elk gebouw inzichtelijk wat de meest effectieve route is om op het gewenste ambitieniveau te komen. "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", [
      _c("div", { staticClass: "widget-wrapper" }, [
        _c("div", { staticClass: "widget-chart measures" }, [
          _c(
            "p",
            {
              staticStyle: {
                display: "none",
                position: "absolute",
                "background-color": "white",
                "border-style": "solid",
                "border-width": "1px",
                "border-color": "black",
                padding: "1px 5px 1px 5px",
                "z-index": "100",
              },
              attrs: { id: "energyCompassToolTip" },
            },
            [_vm._v(" a tooltip! ")]
          ),
          _c("canvas", {
            staticStyle: { width: "100%", height: "384px" },
            attrs: { id: "energyCompassCanvas" },
            on: {
              click: _setup.doClick,
              mousemove: _setup.doMove,
              mouseleave: _setup.doLeave,
            },
          }),
          _c(
            "div",
            { staticClass: "legend ml-1" },
            _vm._l(_setup.legend, function (legendItem, index) {
              return _c(
                "div",
                { key: index, staticClass: "legend-item" },
                [
                  legendItem.icon
                    ? _c("v-icon", { attrs: { color: legendItem.color } }, [
                        _vm._v("mdi-" + _vm._s(legendItem.icon)),
                      ])
                    : _vm._e(),
                  legendItem.image
                    ? _c("i", {
                        staticClass:
                          "v-icon notranslate mdi mdi-square lightGray",
                        attrs: { color: legendItem.color },
                      })
                    : _vm._e(),
                  _c("span", { staticClass: "ml-0-75" }, [
                    _vm._v(_vm._s(legendItem.label)),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }