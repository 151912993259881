import { render, staticRenderFns } from "./AppBase.vue?vue&type=template&id=53f7ba48"
import script from "./AppBase.vue?vue&type=script&lang=ts&setup=true"
export * from "./AppBase.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53f7ba48')) {
      api.createRecord('53f7ba48', component.options)
    } else {
      api.reload('53f7ba48', component.options)
    }
    module.hot.accept("./AppBase.vue?vue&type=template&id=53f7ba48", function () {
      api.rerender('53f7ba48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/AppBase.vue"
export default component.exports