<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getBuildingRegisters, severityCode } from "@/services/api/building.api";
import useGrid27 from "@/composables/grid27.js";
import { formatThousandNoFraction } from "@/composables/formatNumbers";

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageSize, totalItems, changedOptions } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  addressId: {
    type: String,
  },
  year: {
    type: Number,
  },
});

const _headers = [
  { text: "Energytype", value: "energyTypeDesc", sortable: false },
  { text: "Omschrijving", value: "description", sortable: false },
  { text: "Metertype", value: "registerTypeDesc", sortable: false },
  { text: "Waarde", value: "value", sortable: false, align: "end" },
  { text: "PP score", value: "valuePps", sortable: false, align: "end" },
];
const headers = computed(() => _headers);
const ppsUsage = ref();
const surface = ref();
const ppScore = ref();

watch(
  () => [props.addressId, props.year],
  () => {
    fetchFirstPage();
  },
  {
    immediate: true,
  }
);

async function getData() {
  console.log("BuildingUsages getData ", props.addressId, props.year);
  let response = null;
  response = await getBuildingRegisters(props.addressId, props.year);
  if (response.severity <= severityCode.warning) {
    console.log("BuildingUsages fetchData response", props.addressId, response.data);
    ppsUsage.value = response.data.ppsUsage;
    surface.value = response.data.surface;
    ppScore.value = response.data.ppScore;
    return { data: response.data.gridData };
  } else {
    proxy.$toaster.error(`Probleem met ophalen verbruiken: ${response.message}`);
  }
  return response;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  console.log("BuildingRegisterGrid fetchError", value);
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>
<template>
  <v-container>
    <header>
      <h4>Overzicht van meters in {{ props.year }}</h4>
    </header>
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      dense
      :items="gridData"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
      class="overview-table usage-table"
      @update:options="changedOptions"
      :footer-props="{ usage: 123 }"
    >
      <template v-slot:[`item.value`]="{ item }"> {{ formatThousandNoFraction(item.value) }} {{ item.unitTypeUnit }} </template>
      <template v-slot:[`item.valuePps`]="{ item }"> {{ formatThousandNoFraction(item.valuePps) }} kWh </template>
      <template slot="body.append">
        <tr class="totals">
          <td colspan="4" class="description">Totaal:</td>
          <td>{{ formatThousandNoFraction(ppsUsage) }} kWh</td>
        </tr>
        <tr class="totals">
          <td colspan="4" class="description">Oppervlakte:</td>
          <td>{{ formatThousandNoFraction(surface) }} m²</td>
        </tr>
        <tr class="totals">
          <td colspan="4" class="description">Paris Proof Score:</td>
          <td>{{ ppScore }} kWh/m²</td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>
