var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    {
      staticClass: "vue-chart vue-chart-measures pa-0",
      class: _setup.props.legendPosition,
    },
    [
      _c(
        "v-col",
        { staticClass: "vue-chart-wrapper", attrs: { cols: "12" } },
        [
          _c("advanced-horizontal-bar-chart", {
            attrs: {
              chartData: _setup.chartData,
              options: _setup.options,
              styles: _setup.chartStyles,
            },
            on: { "chart-rendered": _setup.handleChartRendered },
          }),
        ],
        1
      ),
      _c("v-col", { staticClass: "vue-chart-legend" }, [
        _c("span", { domProps: { innerHTML: _vm._s(_setup.htmlLegend) } }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }