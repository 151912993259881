<script lang="ts" setup>
import { ref } from "vue";

const chartdata = ref({
  labels: ["Gebouw", "Gebruik", "Werking"],
  datasets: [
    {
      data: [100, 232, 98],
      backgroundColor: ["#00A2DF", "#C7E4F5", "#0C3AED"],
      borderColor: "#FFFFFF",
      borderWidth: 2,
    },
  ],
});
const options = ref({
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
});
const htmlLegend = ref();

function handleChartRendered(payload) {
  htmlLegend.value = payload;
}
</script>

<template>
  <v-container class="vue-chart vue-chart-measure-consumption" pa-0 :class="legendPosition">
    <v-row>
      <v-col class="vue-chart-wrapper" cols="auto">
        <advanced-doughnut-chart :chartdata="chartdata" :options="options" @chart-rendered="handleChartRendered"></advanced-doughnut-chart>
      </v-col>
      <v-col class="vue-chart-legend"><span v-html="htmlLegend"></span></v-col>
    </v-row>
  </v-container>
</template>
