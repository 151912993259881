<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import _ from "lodash";
import { labelColors } from "@/composables/colors";
import { customChartLegendCallback } from "@/composables/customChartLegend.js";

const props = defineProps({
  sourceData: {
    type: Object,
    required: true,
  },

  width: {
    type: Number,
    default: 150,
  },

  height: {
    type: Number,
    default: 150,
  },

  legendPosition: {
    type: String,
    default: "legend-side",
  },

  partyType: {
    type: String,
  },
});

const htmlLegend = ref(null);
const chart = ref({
  labels: null,
  data: null,
  backgroundColors: null,
});
const options = ref({
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 2000,
  },
  legend: {
    display: false,
  },
  legendCallback: customChartLegendCallback,
});

const totalBuildings = ref(0);

const chartStyles = computed(() => {
  return {
    width: `${props.width}px`,
    height: `${props.height}px`,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  };
});

function handleChartRendered(payload) {
  htmlLegend.value = payload;
}

watch(
  () => props.sourceData,
  () => {
    let backgroundColors = [];

    let sortedData = [];
    for (const [key, value] of Object.entries(props.sourceData)) sortedData.push([key, value]);
    sortedData.sort(function s(a, b) {
      let ka = a[0];
      let pa = ka.indexOf("+");
      if (pa == -1) {
        pa = 0;
      } else {
        let t = ka.length - pa;
        ka = ka.substring(0, pa);
        pa = t;
      }
      let kb = b[0];
      let pb = kb.indexOf("+");
      if (pb == -1) {
        pb = 0;
      } else {
        let t = kb.length - pb;
        kb = kb.substring(0, pb);
        pb = t;
      }
      if (ka < kb) return -1;
      else if (ka > kb) return 1;
      else return pb - pa;
    });
    let sortedKeys = [];
    let sortedValues = [];
    for (let i = 0; i < sortedData.length; i++) {
      sortedKeys.push(sortedData[i][0]);
      sortedValues.push(sortedData[i][1]);
    }

    sortedKeys.forEach((colorKey) => {
      backgroundColors.push(labelColors[colorKey] ? labelColors[colorKey] : "#CCCCCC");
    });

    chart.value.backgroundColors = backgroundColors;
    chart.value.labels = Object.keys(props.sourceData);
    chart.value.data = Object.values(props.sourceData);

    totalBuildings.value = _.reduce(Object.values(props.sourceData), (a, b) => a + b, 0);
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <v-container class="vue-chart vue-chart-building-energy-labels inline-text" pa-0 :class="legendPosition">
    <v-row>
      <v-col class="vue-chart-wrapper" cols="auto">
        <advanced-doughnut-chart :data-set="chart" :options="options" :styles="chartStyles" @chart-rendered="handleChartRendered"></advanced-doughnut-chart>
        <div class="vue-chart-text">
          <span
            >Totaal <strong>{{ totalBuildings }}</strong> {{ partyType == "Homes" ? "complexen" : "panden" }}</span
          >
        </div>
      </v-col>
      <v-col class="vue-chart-legend"><span v-html="htmlLegend"></span></v-col>
    </v-row>
  </v-container>
</template>
