var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "overview-table",
        attrs: {
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
          "click:row": _setup.gotoAppliedDetails,
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.investment`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_setup.formatEuroNoFraction(item.investment)) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.kind`,
              fn: function ({ item }) {
                return [
                  _c(_setup.MeasureKindLogo, {
                    attrs: {
                      kind: item.definition.kind.key,
                      description: item.definition.kind.descr,
                    },
                  }),
                ]
              },
            },
            {
              key: `item.recoupedYears`,
              fn: function ({ item }) {
                return [
                  item.recoupedYears != null
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.recoupedYears.toLocaleString(undefined, {
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                              })
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.reductionElec`,
              fn: function ({ item }) {
                return [
                  item.reductionElec != null
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _setup.formatThousandNoFraction(item.reductionElec)
                          ) + " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.reductionGas`,
              fn: function ({ item }) {
                return [
                  item.reductionGas != null
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _setup.formatThousandNoFraction(item.reductionGas)
                          ) + " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.reductionHeat`,
              fn: function ({ item }) {
                return [
                  item.reductionHeat != null
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _setup.formatThousandNoFraction(item.reductionHeat)
                          ) + " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.reductionCo2`,
              fn: function ({ item }) {
                return [
                  item.reductionCo2 != null
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _setup.formatThousandNoFraction(item.reductionCo2)
                          ) + " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _setup.totalItems > 5
        ? _c(_setup.GridPagination, {
            attrs: {
              pageNumber: _setup.pageNumber,
              pageSize: _setup.pageSize,
              pageCount: _setup.pageCount,
            },
            on: { changedPaging: _setup.changedPaging },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }