import { apiClient, getRequest } from "./httpClient";

const END_POINT = "project";

const getProjectList = async () => {
  return await apiClient.get(`${END_POINT}/list`);
};

const getProjects = async (currentPage, pageSize, sortBy, sortDesc, filterData) => {
  const response = await apiClient.post(`${END_POINT}/overview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } });
  return response;
};

const getProjectFilterOptions = async (fieldName, text) => {
  const response = await apiClient.get(`${END_POINT}/autocomplete/${fieldName}`, { params: { text: text } });
  return response.data;
};

const getProject = async (projectId) => {
  let errorResponse = null;
  let response = await apiClient.get(`${END_POINT}/${projectId}/detail`).catch((error) => {
    errorResponse = error.toString();
  });
  if (errorResponse) {
    response.data = "";
  }
  return { data: response.data, error: errorResponse };
};

const getProjectUsers = async (projectId, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  const response = await apiClient.post(`${END_POINT}/${projectId}/users`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } });
  return response;
};

const getProjectUsersFilterOptions = async (projectId, fieldName, text) => {
  const response = await apiClient.get(`${END_POINT}/${projectId}/users/autocomplete/${fieldName}`, { params: { projectId: projectId, text: text } });
  return response.data;
};

const getProjectReports = async (projectId, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  const response = await apiClient.post(
    `${END_POINT}/${projectId}/reports`,
    { filterData: filterData },
    { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } }
  );
  return response;
};

const getProjectReportsList = async () => {
  let errorResponse = null;
  const response = await apiClient.get(`${END_POINT}/reports/list`).catch((error) => {
    errorResponse = error.toString();
  });
  if (errorResponse) {
    return { data: null, error: errorResponse };
  }
  return { data: response.data, error: errorResponse };
};

const getProjectReportsFilterOptions = async (projectId, fieldName, text) => {
  const response = await apiClient.get(`${END_POINT}/${projectId}/reports/autocomplete/${fieldName}`, { params: { projectId: projectId, text: text } });
  return response.data;
};

const addProject = async (projectData) => {
  const response = await apiClient.post(`${END_POINT}`, { ...projectData });
  return response;
};

const updateProject = async (projectId, projectData) => {
  console.log("project.api, updateProject: ", projectData);
  const response = await apiClient.patch(`${END_POINT}/${projectId}`, { ...projectData });
  return response;
};

const deleteProject = async (projectId) => {
  const response = await apiClient.delete(`${END_POINT}/${projectId}`);
  return response;
};

const getCllParties = async () => {
  return await apiClient.get(`${END_POINT}/partyList`);
};

const hasCertifiedMeasures = async (partyReference) => {
  const response = await apiClient.get(`${END_POINT}/${partyReference}/hasCertifiedMeasures`);
  return response;
};

const hasUndefinedVariants = async (partyReference) => {
  const response = await apiClient.get(`${END_POINT}/${partyReference}/hasUndefinedVariants`);
  return response;
};

const hasUndefinedStatusses = async (partyReference) => {
  const response = await apiClient.get(`${END_POINT}/${partyReference}/hasUndefinedStatusses`);
  return response;
};

const getMeasuresCheckInfoSummary = async (partyReference) => {
  const response = await apiClient.get(`${END_POINT}/${partyReference}/measuresCheckInfoSummary`);
  return response;
};

const getMeasuresCheckInfoPaged = async (partyReference, currentPage, pageSize, sortBy, sortDesc) => {
  const response = await apiClient.get(`${END_POINT}/${partyReference}/measuresCheckInfoPaged`, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } });
  return response;
};

const createBulkUploadXML = async (partyReference, xmlData) => {
  console.log("project.api, createBulkUploadXML, projectId, xmlData: ", partyReference, xmlData);
  const response = await apiClient.post(`${END_POINT}/${partyReference}/createBulkUploadXML`, { ...xmlData });
  return response;
};

const createCsv = async (token, partyReference) => {
  const response = await apiClient.post(`${END_POINT}/${token}/createCsv/${partyReference}`);
  return response;
};

const getPartyEnergyEfficiencyObligatory = async (partyReference) => {
  return getRequest(`${END_POINT}/${partyReference}/energyEfficiencyObligatory`);
};

export {
  getCllParties,
  getProjectList,
  getProjects,
  getProjectFilterOptions,
  getProject,
  getProjectUsers,
  getProjectUsersFilterOptions,
  getProjectReports,
  getProjectReportsList,
  getProjectReportsFilterOptions,
  addProject,
  updateProject,
  deleteProject,
  hasCertifiedMeasures,
  hasUndefinedVariants,
  hasUndefinedStatusses,
  getMeasuresCheckInfoSummary,
  getMeasuresCheckInfoPaged,
  createBulkUploadXML,
  createCsv,
  getPartyEnergyEfficiencyObligatory,
};
