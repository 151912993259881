var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.userStore.isPartyHome
    ? _c(_setup.DashboardHomes)
    : _setup.userStore.isPartyEnergyLabels
    ? _c(_setup.DashboardLabels)
    : _setup.userStore.isPartyUtility
    ? _c(_setup.DashboardUtility)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }