var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    {
      staticClass: "vue-chart vue-chart-roadmap-investment-vs-pps pa-0",
      class: _setup.props.legendPosition,
    },
    [
      _c(
        "v-col",
        { staticClass: "vue-chart-wrapper", attrs: { cols: "12" } },
        [
          _c("advanced-combined-bar-line-chart", {
            key: _setup.refreshKey,
            attrs: {
              chartData: _setup.chartData,
              options: _setup.options,
              styles: _setup.chartStyles,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }