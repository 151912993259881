<script lang="ts" setup>
import { ref } from "vue";

const toggled = ref(false);
const input = ref();

function blur() {
  if (toggled.value === true) {
    toggle();
  }
}

function toggle() {
  toggled.value = !toggled.value;

  if (toggled.value === true) {
    input.value.focus();
  }
}
</script>

<style>
* {
  transition: background-color 0.2s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="information-popup" :class="{ active: toggled }">
    <button class="icon-information-icon" @click="toggle" ref="input" v-on:blur="blur()" />

    <transition name="fade">
      <div class="information-dropdown" v-show="toggled === true">
        <div class="information-dropdown-header">
          <slot name="header"></slot>
        </div>

        <div class="information-dropdown-content">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>
