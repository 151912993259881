<script lang="ts" setup>
import { computed, watch, getCurrentInstance } from "vue";
import { getAppliedLogByToken } from "@/services/api/measure.api";
import GridPagination from "@/components/general/GridPagination.vue";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useMessages from "@/composables/messages.js";
import useGrid27 from "@/composables/grid27.js";

const { formatDateUtcDT } = useDateTimeFormatter();
const { msgApplied } = useMessages();
const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  token: String,
  showAddress: { type: Boolean, default: false },
});

const _headers = [
  // https://stackoverflow.com/questions/52625562/hide-a-particular-header-and-its-corresponding-column-in-vuetify-datatable
  { text: "Gebouw", value: "address", align: props.showAddress ? "left" : " d-none", width: "14%" },
  { text: "Maatregel", value: "appliedDesc" },
  { text: "Code", value: "appliedCode", width: "7%" },
  { text: "Veld", value: "field", sortable: false, width: "12%" },
  { text: "Tijdstip", value: "mutDate", width: "7%" },
  { text: "Waarde oud", value: "valOld", align: "right", sortable: false, width: "9%" },
  { text: "Waarde nieuw", value: "valNew", align: "right", sortable: false, width: "9%" },
  { text: "Gebruiker", value: "userName", align: "right", sortable: false, width: "12%" },
];

const headers = computed(() => _headers);

watch(
  () => props.token,
  () => {
    fetchFirstPage();
  },
  { immediate: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  //console.log("%cAppliedLoggingOverviewGrid, getData", "background: orange; color: black", props.token);
  let response = undefined;
  if (props.token) {
    const pageNumber0 = pageNumber - 1;
    response = await getAppliedLogByToken(props.token, pageNumber0, pageSize, sortBy, sortDesc);
  }
  return response;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  //console.log("AppliedLoggingOverviewGrid fetchError", value);
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      :loading="!token || gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
      class="overview-table"
      @update:options="changedOptions"
    >
      <template v-slot:[`item.mutDate`]="{ item }"> {{ formatDateUtcDT(item.mutDate) }}</template>
      <template v-slot:[`item.field`]="{ item }">
        {{ msgApplied(item.field) }}
      </template>
      <template v-slot:[`item.valOld`]="{ item }">
        {{ Number.isNaN(parseInt(item.valOld)) ? msgApplied(item.valOld) : item.valOld }}
      </template>
      <template v-slot:[`item.valNew`]="{ item }">
        {{ Number.isNaN(parseInt(item.valNew)) ? msgApplied(item.valNew) : item.valNew }}
      </template>
    </v-data-table>
    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
