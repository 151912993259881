var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "widget-item usage-slider" },
    [
      _c(
        "header",
        [
          _c("h2", [_vm._v("Jaarverbruiken")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _setup.toggeleDetails()
                },
              },
            },
            [
              _vm._v("Toon details "),
              _setup.showDetails
                ? _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-chevron-up"),
                  ])
                : _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-chevron-down"),
                  ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "widget-actions" },
            [
              _c(
                "information-dropdown",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_c("h2", [_vm._v("Jaarverbruiken")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("p", [
                    _vm._v(
                      " Hier wordt het totaal van uw energieverbruik getoond. Er wordt een onderverdeling gemaakt tussen elektra, gas en warmte (indien van toepassing). Tevens wordt deze data gesommeerd en in een totaal aan kilogram CO₂ weergeven. "
                    ),
                  ]),
                  _c("h4", [_vm._v("Toon details")]),
                  _c("p", [
                    _vm._v(
                      "Hier ziet u op basis van welke energie aansluitingen de totalen gebaseerd zijn."
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "perfect-scrollbar",
        { attrs: { options: { wheelPropagation: true } } },
        [
          _setup.isLoading === true
            ? _c("loader-spinner", {
                attrs: { "is-loading": _setup.isLoading },
              })
            : _c(
                "div",
                { staticClass: "usage-wrapper" },
                [
                  _c("v-data-table", {
                    staticClass: "usage-grid",
                    attrs: {
                      headers: _setup.headers,
                      "hide-default-footer": "",
                      dense: "",
                      "hide-default-header": "",
                      items: _setup.gridData,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function ({ props: { headers } }) {
                            return [
                              _c(
                                "thead",
                                { staticClass: "v-data-table-header" },
                                [
                                  _c(
                                    "tr",
                                    _vm._l(headers, function (header) {
                                      return _c(
                                        "th",
                                        {
                                          key: header.value,
                                          attrs: {
                                            role: "columnheader",
                                            scope: "col",
                                          },
                                        },
                                        [
                                          _setup.showDetails &&
                                          `year${_setup.selectedYear}` ==
                                            header.value
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "year selected",
                                                },
                                                [_vm._v(_vm._s(header.text))]
                                              )
                                            : _setup.showDetails && header.text
                                            ? _c(
                                                "span",
                                                { staticClass: "year" },
                                                [
                                                  _vm._v(_vm._s(header.text)),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _setup.onSetYear(
                                                            header.value
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v(" mdi-eye ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c("span", [
                                                _vm._v(_vm._s(header.text)),
                                              ]),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        _vm._l(
                          _setup.headers.filter((x) =>
                            x.value.startsWith("year")
                          ),
                          function (header) {
                            return {
                              key: `item.${header.value}`,
                              fn: function ({ value }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_setup.consumption(value)) +
                                      " "
                                  ),
                                ]
                              },
                            }
                          }
                        ),
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _setup.showDetails
            ? _c(_setup.BuildingUsages, {
                attrs: {
                  addressId: _setup.props.addressId,
                  year: _setup.selectedYear,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }