<script lang="ts" setup>
import { computed, watch, getCurrentInstance } from "vue";
import { getLocationLabels, getDocumentIds } from "@/services/api/locationlabel.api";
import GridPagination from "@/components/general/GridPagination.vue";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useGrid27 from "@/composables/grid27.js";
import { useCacheStore } from "@/stores/cache";
import { useAppConfigurationStore } from "@/stores/appconfig";

const { formatDateUtc } = useDateTimeFormatter();
const appconfigStore = useAppConfigurationStore();
const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
  fetchDataComplete: fetchDataComplete,
});
const emits = defineEmits(["fetch-data-complete"]);

const props = defineProps({
  partyReference: String,
  filterData: { type: Array, default: () => [] },
});

const _headers = [
  { text: "Volledigheid", value: "dataScore", align: "middle" },
  { text: "VHE nummer", value: "reference" },
  { text: "Complexnummer", value: "parentReference" },
  { text: "Adres", value: "address" },
  { text: "Plaats", value: "city" },

  { text: "EP2 kWh/m²", value: "vabiCurrentLabelBeng2" },
  { text: "Label", value: "vabiCurrentLabel" },

  { text: "", sortable: false },
  { text: "EI", value: "vabiRegisteredEnergyIndex" },
  { text: "EP2 kWh/m²", value: "vabiRegisteredLabelBeng2" },
  { text: "Label", value: "vabiRegisteredLabel" },
  { text: "Opnamedatum", value: "vabiRegisteredLabelRecordedDate" },
  { text: "Registratiedatum", value: "vabiRegistrationDate" },
  { text: "Registratienummer", value: "vabiRegistrationNumber" },

  { text: "", sortable: false },
  { text: "EI", value: "epOnlineEnergyIndex" },
  { text: "EP2 kWh/m²", value: "epOnlineLabelBeng2" },
  { text: "Label", value: "epOnlineLabel" },
  { text: "Opnamedatum", value: "epOnlineRecordedDate" },
  { text: "Registratiedatum", value: "epOnlineRegistrationDate" },
  { text: "Document", value: "documentId" },
];
const headers = computed(() => _headers);

watch(
  () => [props.partyReference, props.filterData],
  () => {
    fetchFirstPage();
  },
  { immediate: true, deep: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  if (props.filterData) {
    const pageNumber0 = pageNumber - 1;
    console.log("%cLocationLabelGrid, getData, filter, response: ", "background: purple; color: white", props.filterData);
    const response = await getLocationLabels(props.partyReference, pageNumber0, pageSize, sortBy, sortDesc, props.filterData);
    return response;
  } else {
    return undefined;
  }
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  console.log("LocationLabelGrid fetchError", value);
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}

function fetchDataComplete() {
  //console.log("LocationLabelGrid fetchDataComplete ", gridData.value.length);
  emits("fetch-data-complete", true);
  for (const item of gridData.value) {
    //    console.log("LocationLabelGrid fetchDataComplete ", item.reference);
    getDocumentId(item);
  }
}

async function getDocumentId(item) {
  // ToDo: Ophalen documentId's verplaatsen naar store. In de store worden de Id's opgehaald, al dan niet uit de cache.
  //console.log("LocationLabelGrid getDocumentId", item.reference);
  const cacheStore = useCacheStore();
  item.documentExists = null;

  if (cacheStore.isDocumentCached(item.locationReference)) {
    const cachedDocument = cacheStore.getDocumentInfo(item.locationReference);
    item.documentId = cachedDocument.documentId;
    item.documentDescription = cachedDocument.description;
    item.documentExists = item.documentId == undefined ? "false" : "true";
  } else {
    const response = await getDocumentIds(item.locationReference, "EnergyLabel");
    console.log("%cLocationLabelGrid, response: ", "background: purple; color: white", response);
    if (response && response.length > 0) {
      item.documentId = response[0].id;
      item.documentDescription = response[0].description;
      item.documentExists = "true";
    } else {
      item.documentId = undefined;
      item.documentDescription = undefined;
      item.documentExists = "false";
    }
    cacheStore.cacheDocumentInfo(item.locationReference, item.documentId, item.documentDescription);
  }
  dataScoreCheck(item);
  //console.log("LocationLabelGrid getDocumentId finish", item.locationReference, item.documentId);
}

function getDocumentUrl(documentId) {
  return appconfigStore.data.portalUrl + "asset/document/" + documentId;
}

function dataScoreCheck(item) {
  const dataScore =
    item.vabiCurrentLabel != null &&
    item.vabiRegisteredLabel != null &&
    item.epOnlineLabel != null &&
    item.vabiCurrentLabel == item.vabiRegisteredLabel &&
    item.vabiCurrentLabel == item.epOnlineLabel &&
    item.documentExists != "false"
      ? "OK"
      : item.vabiCurrentLabel == null && item.vabiRegisteredLabel == null && item.epOnlineLabel == null && item.documentExists == "false"
      ? "notOK"
      : "warning";
  item.dataScoreChecked = dataScore;
}
</script>

<template>
  <div>
    <!-- server-items-length: noodzakelijk anders werkt sorting niet goed, gaat ie eerst client sorting doen en dan alsnog van server halen -->
    <!-- options.sync: dit werkt niet goed met externe paging, wordt dus alleen gebruikt voor sorting -->
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
      class="overview-table"
      @update:options="changedOptions"
    >
      <template v-slot:header="">
        <thead>
          <tr>
            <th colspan="5" style="border-bottom-style: none"></th>
            <th colspan="2" style="border-bottom-style: solid">Huidig label (Vabi)</th>
            <th colspan="1" style="border-bottom-style: none"></th>
            <th colspan="6" style="border-bottom-style: solid">Geregistreerd label (VABI)</th>
            <th colspan="1" style="border-bottom-style: none"></th>
            <th colspan="7" style="border-bottom-style: solid">Geregistreerd label (EPOnline)</th>
          </tr>
        </thead>
      </template>

      <template v-slot:[`item.dataScore`]="{ item }">
        <v-icon v-if="item.dataScoreChecked == 'notOK'" large color="red"> mdi-alert-octagon </v-icon>
        <v-icon v-else-if="item.dataScoreChecked == 'warning'" large color="orange"> mdi-alert </v-icon>
        <v-icon v-else-if="item.dataScoreChecked == 'OK'" large color="green"> mdi-checkbox-marked-circle </v-icon>
        <v-icon v-else>mdi-tilde</v-icon>
      </template>

      <template v-slot:[`item.reference`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-layout v-on="on" class="d-flex align-center justify-space-between">
              <span> {{ item.reference }} </span>
            </v-layout>
          </template>
          <span> {{ item.description }} (locRef: {{ item.locationReference }}) </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.vabiCurrentLabelBeng2`]="{ item }">
        <span v-if="item.vabiCurrentLabelBeng2 != null">{{ item.vabiCurrentLabelBeng2 }}</span>
      </template>
      <template v-slot:[`item.vabiCurrentLabel`]="{ item }">
        <energy-label :label="item.vabiCurrentLabel" :estimate="false" class="small"></energy-label>
      </template>

      <template v-slot:[`item.vabiRegisteredLabelBeng2`]="{ item }">
        <span v-if="item.vabiRegisteredLabelBeng2 != null">{{ item.vabiRegisteredLabelBeng2 }}</span>
      </template>
      <template v-slot:[`item.vabiRegisteredLabel`]="{ item }">
        <energy-label :label="item.vabiRegisteredLabel" :estimate="false" class="small"></energy-label>
      </template>
      <template v-slot:[`item.vabiRegisteredLabelRecordedDate`]="{ item }">
        {{ formatDateUtc(item.vabiRegisteredLabelRecordedDate) }}
      </template>
      <template v-slot:[`item.vabiRegistrationDate`]="{ item }">
        {{ formatDateUtc(item.vabiRegistrationDate) }}
      </template>

      <template v-slot:[`item.epOnlineLabelBeng2`]="{ item }">
        <span v-if="item.epOnlineLabelBeng2 != null">{{ item.epOnlineLabelBeng2 }}</span>
      </template>
      <template v-slot:[`item.epOnlineLabel`]="{ item }">
        <energy-label :label="item.epOnlineLabel" :estimate="false" class="small"></energy-label>
      </template>
      <template v-slot:[`item.epOnlineRecordedDate`]="{ item }">
        {{ formatDateUtc(item.epOnlineRecordedDate) }}
      </template>
      <template v-slot:[`item.epOnlineRegistrationDate`]="{ item }">
        {{ formatDateUtc(item.epOnlineRegistrationDate) }}
      </template>

      <template v-slot:[`item.documentId`]="{ item }">
        <v-tooltip v-if="item.documentExists == 'true'" bottom>
          <template v-slot:activator="{ on }">
            <v-layout v-on="on" class="d-flex align-center">
              <a :href="getDocumentUrl(item.documentId)" :download="item.documentDescription"><v-icon large color="primary"> mdi-file </v-icon></a>
            </v-layout>
          </template>
          <span> {{ item.documentDescription }} </span>
        </v-tooltip>
        <template v-else>
          <span v-if="item.documentExists == 'false'"> -<v-icon medium color="gray"> mdi-file-hidden </v-icon>- </span>
          <v-icon v-else>mdi-tilde</v-icon>
        </template>
      </template>
    </v-data-table>
    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
