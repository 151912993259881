var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "widget-item" },
    [
      _vm._t("headerwidget"),
      _c(
        "div",
        { staticClass: "widget-wrapper widget-chart" },
        [
          _c("loader-spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _setup.isLoading === true,
                expression: "isLoading === true",
              },
            ],
            attrs: { "is-loading": _setup.isLoading, "min-height": 170 },
          }),
          _c("chart-building-energy-labels", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _setup.isLoading === false,
                expression: "isLoading === false",
              },
            ],
            attrs: {
              height: 170,
              sourceData: _setup.energyLabels,
              partyType: _vm.partyType,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }