<script lang="ts" setup>
import { ref, watch, onMounted, getCurrentInstance } from "vue";

import { getCategories, severityCode } from "@/services/api/measure.api";

const emits = defineEmits(["changed-selection"]);

const props = defineProps({
  selectedCategoriesProp: [],
  resetSelectedCategories: Boolean,
});

const categoriesLoaded = ref(false);
const categoriesMain = ref([]);
const categories = ref([]);
const selectedCategories = ref([]);
const selectedMains = ref([]);
const selectedIndeterminate = ref([]);

onMounted(() => {
  loadCategories();
});

watch(
  () => selectedCategories.value,
  () => {
    emits("changed-selection", { selected: [...selectedCategories.value] });
  },
  { deep: true }
);

watch(
  () => props.resetSelectedCategories,
  () => {
    if (props.resetSelectedCategories && props.selectedCategoriesProp) {
      selectedCategories.value = [...new Set(props.selectedCategoriesProp.filter((c) => c.isActive === true).map((o) => o.type.key))];
    }
  }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function loadCategories() {
  const response = await getCategories();

  if (response.severity <= severityCode.warning) {
    if (props.selectedCategoriesProp) {
      selectedCategories.value = [...new Set(props.selectedCategoriesProp.filter((c) => c.isActive === true).map((o) => o.type.key))];
    }

    categories.value = [...response.data];
    categoriesMain.value = [...new Set(response.data.map((o) => JSON.stringify({ key: o.mainType.key, descr: o.mainType.descr })))]
      .map((s) => JSON.parse(s))
      .sort((a, b) => a.descr.localeCompare(b.descr));

    categories.value.forEach((c) => {
      if (selectedCategories.value.indexOf(c.type.key) >= 0) {
        c.type.isActive = true;
      }
    });

    categoriesMain.value.forEach((main) => {
      if (!categories.value.filter((c) => c.mainType.key === main.key).some((x) => !x.type.isActive)) {
        selectedMains.value.push(main.key);
      }
    });

    categoriesLoaded.value = true;
  } else {
    categoriesLoaded.value = false;
    proxy.$toaster.error(`Probleem met laden categorieën: ${response.message}`);
  }
}

function subCategories(mainKey) {
  if (categories.value) {
    // console.log("CertifiedCategories, subCategories, categories ", mainKey, categories.value);
    const lst = categories.value.filter((o) => o.mainType.key === mainKey);
    // console.log("CertifiedCategories, subCategories ", lst);
    return lst;
  } else {
    return undefined;
  }
}

function mainSelected(mainKey, values) {
  const valueSet = values.indexOf(mainKey) >= 0;
  // console.log("CertifiedCategories, selectMain ", mainKey, values, valueSet);
  const categories = subCategories(mainKey);
  categories.forEach((category) => {
    const idx = selectedCategories.value.indexOf(category.type.key);
    if (valueSet && idx == -1) {
      selectedCategories.value.push(category.type.key);
    } else if (!valueSet && idx >= 0) {
      selectedCategories.value.splice(idx, 1);
    }
  });
  setMain(mainKey, false, valueSet);
}

function setMain(mainKey, indeterminate, selected) {
  // console.log("CertifiedCategories, setMain ", mainKey, indeterminate, selected);
  const indeterminateIdx = selectedIndeterminate.value.indexOf(mainKey);
  const mainIdx = selectedMains.value.indexOf(mainKey);
  if (indeterminate) {
    if (indeterminateIdx == -1) {
      selectedIndeterminate.value.push(mainKey);
    }
    if (mainIdx >= 0) {
      selectedMains.value.splice(mainIdx, 1);
    }
  } else {
    if (indeterminateIdx >= 0) {
      selectedIndeterminate.value.splice(indeterminateIdx, 1);
    }
    if (selected) {
      if (mainIdx == -1) {
        selectedMains.value.push(mainKey);
      }
    } else {
      if (mainIdx >= 0) {
        selectedMains.value.splice(mainIdx, 1);
      }
    }
  }
}

function categorySelected(mainKey) {
  // console.log("CertifiedCategories, selectCategory ", mainKey);
  const categories = subCategories(mainKey);
  let all = true;
  let none = true;
  categories.forEach((category) => {
    const idx = selectedCategories.value.indexOf(category.type.key);
    all = all && idx >= 0;
    none = none && idx == -1;
  });
  setMain(mainKey, !all && !none, all);
}

function isIndeterminate(mainKey) {
  const valueSet = selectedIndeterminate.value.indexOf(mainKey) >= 0;
  return valueSet;
}
</script>

<template>
  <v-layout>
    <v-row>
      <v-col v-for="main in categoriesMain" :key="main.key">
        <v-checkbox
          :ref="'maincb-' + main.key"
          v-model="selectedMains"
          :label="main.descr"
          :value="main.key"
          :indeterminate="isIndeterminate(main.key)"
          hide-details
          dense
          @change="mainSelected(main.key, $event)"
        ></v-checkbox>
        <v-row v-for="cat in subCategories(main.key)" :key="cat.type.key" dense>
          <v-col class="pl-7">
            <v-checkbox v-model="selectedCategories" :label="cat.type.descr" :value="cat.type.key" @change="categorySelected(main.key)" hide-details dense> </v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-layout>
</template>
