<script lang="ts" setup>
import { ref, computed, watch } from "vue";

const props = defineProps({
  height: {
    type: Number,
    default: 150,
  },

  sourceData: {
    type: Object,
    required: true,
  },

  legendPosition: {
    type: String,
    default: "legend-side",
  },
});

const chartData = ref({
  labels: ["Norm 2050", "Huidige status"],
  options: {
    barThickness: 20,
    indexAxis: "y",
  },
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["#0C3AED", "#0C7BF7"],
    },
  ],
});
const options = ref({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        stacked: false,
        ticks: { min: 0 },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
});
const htmlLegend = ref();

const chartStyles = computed(() => {
  return {
    height: `${props.value}px`,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  };
});

const summaryText = computed(
  () =>
    "Op basis van " + props.sourceData.countedHouses + " van " + props.sourceData.totalHouses + " EGW, en " + props.sourceData.countedApartments + " van " + props.sourceData.totalApartments + " MGW"
);

watch(
  () => props.sourceData,
  () => {
    chartData.value.datasets[0].data = [props.sourceData.std2050, props.sourceData.present];
  }
);

function handleChartRendered(payload) {
  htmlLegend.value = payload;
}
</script>

<template>
  <v-row class="vue-chart vue-chart-measures pa-0" :class="props.legendPosition">
    <v-col class="vue-chart-wrapper" cols="12">
      <advanced-horizontal-bar-chart :chartData="chartData" :options="options" :styles="chartStyles" @chart-rendered="handleChartRendered"></advanced-horizontal-bar-chart>
      <br />
      {{ summaryText }}
    </v-col>
    <v-col class="vue-chart-legend"><span v-html="htmlLegend"></span></v-col>
  </v-row>
</template>
