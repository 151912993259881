<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";
import { getHomeTypes } from "@/services/api/chart.api";

const props = defineProps({
  token: {
    type: String,
  },
});

const buildingFunctions = ref({});
const isLoading = ref(true);

watch(
  () => props.token,
  () => {
    fetchBuildingFunctionsData();
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchBuildingFunctionsData() {
  isLoading.value = true;
  const response = await getHomeTypes(props.token);
  if (response) {
    buildingFunctions.value = {};
    buildingFunctions.value["Eengezins"] = { val: response.data.houses, color: "#00a86b" }; // Jade
    buildingFunctions.value["Woningen in een appartementencomplex"] = { val: response.data.apartments, color: "#000080" }; // Navy blue
    isLoading.value = false;
  } else {
    isLoading.value = false;
    proxy.$toaster.error("Ophalen van de buildingFunctions data is mislukt!");
  }
}
</script>

<template>
  <v-layout class="widget-item">
    <header>
      <h2>Overzicht complexen</h2>
      <div class="widget-actions">
        <router-link :to="{ name: 'ComplexOverview' }" class="widget-link">Bekijk totale overzicht</router-link>
        <information-dropdown id="overzicht-panden">
          <template v-slot:header>
            <h2>Titel</h2>
          </template>
          <p>Uw portefeuille kan bestaan uit verschillende type woningen. We tonen hier de verdeling van de type woningen voor alle complexen.</p>
        </information-dropdown>
      </div>
    </header>
    <loader-spinner v-show="isLoading === true" :is-loading="isLoading"></loader-spinner>
    <div class="widget-wrapper" v-show="isLoading === false" :min-height="200">
      <div class="widget-chart">
        <chart-home-types :height="200" :width="200" legendPosition="legend-bottom" :source-data="buildingFunctions"></chart-home-types>
      </div>
    </div>
  </v-layout>
</template>
