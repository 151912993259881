<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";
import { getBuildingSquareMeters, severityCode } from "@/services/api/chart.api";

const props = defineProps({
  token: String,
});

const squareMeters = ref({});
const squareMetersTotal = ref(0);
const isLoading = ref(true);

watch(
  () => props.token,
  () => {
    if (props.token) {
      fetchData();
    } else {
      squareMeters.value = {};
      squareMetersTotal.value = 0;
    }
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  isLoading.value = true;
  const response = await getBuildingSquareMeters(props.token);
  console.log("BuildingOverviewSquareMeters fetchData ", response);
  if (response.severity <= severityCode.warning) {
    squareMetersTotal.value = response.data.total;
    squareMeters.value = { ...response.data.data };
    console.log("BuildingOverviewSquareMeters fetchData ", squareMeters.value);
  } else {
    proxy.$toaster.error("Ophalen van buildingFunctions is mislukt! ", response.message);
  }
  isLoading.value = false;
}
</script>
<template>
  <v-layout class="widget-item">
    <h3>Totaal aantal vierkante meters</h3>
    <div class="widget-wrapper widget-chart">
      <loader-spinner v-if="isLoading === true" :is-loading="isLoading" :min-height="170"></loader-spinner>
      <chart-square-meters v-else :height="170" :sourceData="squareMeters" :total="squareMetersTotal"> </chart-square-meters>
    </div>
  </v-layout>
</template>
