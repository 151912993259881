import Vue from "vue";
import Vuetify from "vuetify/lib";
import nl from "vuetify/lib/locale/nl";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { nl },
    current: "nl"
  },
  theme: {
    themes: {
      light: {
        primary: "#00A3E0",
        secondary: "#033B58"
      }
    }
  }
});
