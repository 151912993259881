function customChartLegendCallback(chart) {
  // console.log("chartLegendOptions callback", chart);
  let legendHtml = [];
  legendHtml.push("<ul>");
  const item = chart.data.datasets[0];
  const sum = item.data.reduce(function add(a, b) {
    return a + b;
  }, 0);
  for (let i = 0; i < item.data.length; i++) {
    const perc = Math.round((100 * item.data[i]) / sum, 0);
    legendHtml.push("<li>");
    legendHtml.push('<span class="chart-legend" style="background-color:' + item.backgroundColor[i] + '"></span>');
    legendHtml.push('<span class="chart-legend-label-wrapper"><span class="chart-legend-label-text">' + chart.data.labels[i] + "</span></span>");
    legendHtml.push('<span class="chart-percentage">' + perc + "%</span>");
    legendHtml.push("</li>");
  }

  legendHtml.push("</ul>");
  return legendHtml.join("");
}

export { customChartLegendCallback };
