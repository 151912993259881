<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import AppliedLoggingOverviewGrid from "@/components/app/measures/AppliedLoggingOverviewGrid.vue";
import { FilterTokenName, FilterStatus, mapFilterResponseStatus } from "@/stores/filtertoken";
import { getAppliedLogFilterOptions } from "@/services/api/measure.api";
import { getAppliedLogFilterToken } from "@/services/api/token.api";
import handleFilterTokens27 from "@/composables/handleFilterTokens27";

const { token, projectToken, getProjectToken, searchToken, mapFilterResult, createFilterToken, resetFilterToken, setRequirePreFilterToken, createPreFilterToken, resetPreFilterToken } =
  handleFilterTokens27(FilterTokenName.AppliedLogging);

const props = defineProps({
  addressId: { type: String, default: null },
  appliedId: { type: String, default: null },
});

const showAddress = computed(() => !props.addressId);

const preFilter = ref([]);

const filters = ref([
  {
    name: "Gebouw",
    fieldName: "address",
    type: "search",
    options: [],
    status: FilterStatus.None,
    show: !props.addressId,
  },
  {
    name: "Maatregel",
    fieldName: "appliedDesc",
    type: "search",
    options: [],
    status: FilterStatus.None,
    show: !props.appliedId,
  },
  {
    name: "Code",
    fieldName: "appliedCode",
    type: "search",
    options: [],
    status: FilterStatus.None,
    show: !props.appliedId,
  },
  {
    name: "Datum",
    fieldName: "mutDate",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
]);

watch(
  () => props.addressId,
  () => {
    if (props.addressId) {
      //console.log("AppliedLoggingOverview watch addressId ", props.addressId);
      setRequirePreFilterToken(true);
      preFilter.value.push({
        fieldName: "addressId",
        exact: [props.addressId],
      });
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => props.appliedId,
  () => {
    if (props.appliedId) {
      //console.log("AppliedLoggingOverview watch appliedId ", props.appliedId);
      setRequirePreFilterToken(true);
      preFilter.value.push({
        fieldName: "appliedId",
        exact: [props.appliedId],
      });
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => [preFilter.value, projectToken.value],
  () => {
    //console.log("AppliedLoggingOverview watch preFilter ", preFilter.value, projectToken.value);
    //voor prefilter is projecttoken nodig, zeker weten dat deze er al is
    if (projectToken.value) {
      setPreFilterToken();
    } else {
      //force projecttoken load en dan komt ie vanzelf wel weer in deze watch
      getProjectToken();
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

async function setPreFilterToken() {
  //console.log("AppliedLoggingOverview, setPreFilterToken", projectToken.value, preFilter.value);
  if (preFilter.value && preFilter.value.length > 0) {
    //voor prefilter is projecttoken nodig, deze ophalen want computed projectToken is nog niet zeker dat deze er is
    //const projectToken = await getProjectToken();
    const getPreFilterToken = getAppliedLogFilterToken(projectToken.value, preFilter.value);
    const success = await createPreFilterToken(getPreFilterToken);
    //console.log("AppliedLoggingOverview, setPreFilterToken success", success);
    if (!success) {
      proxy.$toaster.error("Ophalen van de filter token is mislukt!");
      resetPreFilterToken();
    }
    //console.log("AppliedLoggingOverview, setPreFilterToken token", preFilterToken.value);
    //    preFilterToken.value = { id: response.id };
  } else {
    resetPreFilterToken();

    //  preFilterToken.value = undefined;
  }
}

async function handleFilterSearch(index, text) {
  //console.log("AppliedLoggingOverview handleFilterSearch: ", index, text, token.value, searchToken.value);
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    default:
      {
        const response = await getAppliedLogFilterOptions(searchToken.value, filters.value[index].fieldName, text);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function handleFiltersChanged(filterData) {
  //console.log("AppliedLoggingOverview handleFiltersChanged filterData: ", filterData);
  if (filterData.length > 0) {
    const getFilterToken = getAppliedLogFilterToken(searchToken.value, filterData);
    const success = await createFilterToken(getFilterToken);
    if (!success) {
      proxy.$toaster.error("Ophalen van de filter token is mislukt!");
    }
  } else {
    resetFilterToken();
  }
}
</script>

<template>
  <v-container fluid class="inner">
    <v-row class="overview-wrapper">
      <v-col cols="auto">
        <div class="overview-filters align-items-start">
          <filter-set :name="FilterTokenName.AppliedLogging" :filters="filters" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <!-- In plaats van wachten op token, wachten we op de preFilter omdat deze mogelijk nog een filtering gaat toepassen. Dit voorkomt dubbel laden van het grid -->
    <applied-logging-overview-grid :preFilter="preFilter" :token="token" :showAddress="showAddress"></applied-logging-overview-grid>
  </v-container>
</template>
