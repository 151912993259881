import { ref, computed, watch } from "vue";
import { useFiltertokenStore } from "@/stores/filtertoken";

// options=> {filterName}
export default function useHandleFilterTokens27(filterName) {
  //console.log("useHandleFilterTokens27 initialize: ", filterName);

  const filtertokenStore = useFiltertokenStore();
  const tokenDelayed = ref("");
  const filterToken = ref(filtertokenStore.getFilterToken(filterName));
  const requirePreFilterToken = ref(false);
  const preFilterToken = ref(null);

  const token = computed(() => {
    //console.log("useHandleFilterTokens27 computed token: ", filterName);
    if (filterToken.value.id) {
      return filterToken.value.id;
    } else {
      if (requirePreFilterToken.value) {
        return preFilterToken.value ? preFilterToken.value : null;
      } else {
        if (!filtertokenStore.projectToken) {
          getProjectToken();
        }
        return filtertokenStore.projectToken;
      }
    }
  });

  const projectToken = computed(() => filtertokenStore.projectToken);

  const searchToken = computed(() => {
    if (preFilterToken.value) {
      return preFilterToken.value;
    } else {
      if (!filtertokenStore.projectToken) {
        getProjectToken();
      }
      return filtertokenStore.projectToken;
    }
  });

  watch(
    () => token,
    (newValue) => {
      //console.log("useHandleFilterTokens27 watch token: ", newValue, oldValue);
      //zorgt ervoor dat grafieken iets vertraging krijgen na update zodat eerst grid zichtbaar is en dann pas grafiek
      if (newValue) {
        setTimeout(() => setTokenDelayed(newValue), 1500);
      } else {
        setTokenDelayed(undefined);
      }
    },
    { immediate: true }
  );

  function setTokenDelayed(value) {
    tokenDelayed.value = value;
  }

  //force projecttoken load
  async function getProjectToken() {
    //console.log("useHandleFilterTokens27, load getProjectToken");
    return await filtertokenStore.getProjectToken();
  }

  ///pass call to function welke filtertoken gaat ophalen
  async function createFilterToken(callGetFilterToken) {
    //console.log("HandleFilterTokens27 createFilterToken : ", callGetFilterToken);
    let success = true;
    callGetFilterToken.then((response) => {
      if (response && !response.error) {
        filterToken.value = filtertokenStore.setFilterToken(filterName, response.id, response.expire);
      } else {
        success = false;
      }
    });
    return success;
  }

  function setRequirePreFilterToken(value) {
    requirePreFilterToken.value = value;
  }

  ///pass call to function welke prefiltertoken gaat ophalen
  async function createPreFilterToken(callGetPreFilterToken) {
    //console.log("HandleFilterTokens27 createFilterToken : ", callGetPreFilterToken);
    setRequirePreFilterToken(true);
    let success = true;
    //console.log("HandleFilterTokens27 createPreFilterToken  project: ", projectToken.value);
    await callGetPreFilterToken.then((response) => {
      //console.log("HandleFilterTokens27 createPreFilterToken : ", response);
      if (response && !response.error) {
        preFilterToken.value = response.id;
        //console.log("HandleFilterTokens27 createPreFilterToken  set : ", response.id);
      } else {
        success = false;
      }
    });
    return success;
  }

  function mapFilterResult(result) {
    return [...result.map((item) => ({ id: item.id && item.id.length > 0 ? item.id : item.text, text: item.text, count: item.count }))];
  }

  function resetFilterToken() {
    //console.log("HandleFilterTokens27 resetFilterToken");
    filterToken.value = filtertokenStore.setFilterToken(filterName);
    //console.log("HandleFilterTokens27 resetFilterToken ", filterToken.value, token);
  }

  function resetPreFilterToken() {
    //console.log("HandleFilterTokens27 resetPreFilterToken");
    preFilterToken.value = undefined;
  }

  return {
    token,
    tokenDelayed,
    projectToken,
    filterToken,
    preFilterToken,
    searchToken,
    setTokenDelayed,
    getProjectToken,
    createFilterToken,
    resetFilterToken,
    setRequirePreFilterToken,
    createPreFilterToken,
    resetPreFilterToken,
    mapFilterResult,
  };
}
