<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";

import { getParisProofScore } from "@/services/api/chart.api";
import { getParisProofColorClass } from "@/composables/colors";

const props = defineProps({
  token: {
    type: String,
  },
  refDate: {
    type: Date,
  },
});

const ppmSummary = ref({
  meanScore: 0,
  meanNorm: 0,
  count: 0,
  total: 0,
  percentage: 0,
});
const isLoading = ref(false);
const isLoaded = ref(false);

const hasData = computed(() => isLoaded.value && ppmSummary.value && !Number.isNaN(ppmSummary.value.meanScore) && !Number.isNaN(ppmSummary.value.meanNorm) && ppmSummary.value.count > 0);

watch(
  () => props.token,
  () => {
    fetchData();
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  //console.log("ParisProofScore fetchData ", props.token);
  isLoaded.value = false;
  if (props.token) {
    isLoading.value = true;
    const response = await getParisProofScore(props.token, props.refDate);
    if (response) {
      ppmSummary.value.meanScore = response.data.meanScore;
      ppmSummary.value.meanNorm = response.data.meanNorm;
      ppmSummary.value.count = response.data.count;
      ppmSummary.value.total = response.data.total;
      ppmSummary.value.percentage = response.data.percentage;
      isLoaded.value = true;
    } else {
      console.error("Failed to get Paris Proof summary");
      proxy.$toaster.error("Ophalen van de Paris Proof scores is mislukt!");
    }
    isLoading.value = false;
  }
}
</script>

<template>
  <perfect-scrollbar>
    <h2>Paris Proof Score</h2>
    <information-dropdown>
      <template v-slot:header>
        <h2>Paris Proof?</h2>
      </template>

      <p>U ziet hier uw gewogen gemiddelde Paris Proof Score op basis van de WEii methodiek van al uw gebouwen.</p>

      <p>
        WEii staat voor Werkelijke Energie intensiteit indicator en is een gestandaardiseerde methodiek voor het bepalen van een energie-efficiëntie indicator op basis van het werkelijke
        energiegebruik van een gebouw.
      </p>

      <p>
        In tegenstelling tot NTA 8800 (BENG) is WEii gebaseerd op het werkelijke energiegebruik van een gebouw in gebruik en niet op een berekend gebouwgebonden energiegebruik. Er zijn verschillende
        gebouwtype en klassen ontwikkeld, daarbij is de klasse Paris Proof een gebouw dat voldoet aan de doelstellingen voor 2050.
      </p>

      <p>De Paris Proof Score is berekend op basis van het WEii protocol versie 2.0.</p>

      <p>
        Klik
        <a class="nav-link-light" href="https://www.weii.nl" target="_blank"><b>hier</b></a>
        voor een overzicht van de normering.
      </p>
    </information-dropdown>

    <h5 v-if="isLoaded && ppmSummary.count != 0 && ppmSummary.count != ppmSummary.total">Gebaseerd op {{ ppmSummary.count }} van de {{ ppmSummary.total }} gebouwen</h5>
    <h5 v-if="isLoaded && ppmSummary.count != 0 && ppmSummary.count == ppmSummary.total">Gebaseerd op alle gebouwen</h5>

    <v-container v-if="isLoading === true">
      <loader-spinner :is-loading="isLoading"></loader-spinner>
    </v-container>

    <v-container v-else>
      <p v-if="isLoaded && ppmSummary.count == 0">Er zijn nog geen panden waar de Paris Proof score voor berekend kan worden, doordat er energiedata en/of oppervlaktes missen</p>

      <p v-else class="paris-score">
        <span v-if="hasData" :class="getParisProofColorClass(ppmSummary.meanScore, ppmSummary.meanNorm)">
          Gemiddelde score:
          <number :from="0" :to="ppmSummary.meanScore" :duration="1.6"></number>&nbsp;kWh/m²<br />
          Gemiddelde norm: {{ ppmSummary.meanNorm }}&nbsp;kWh/m²
        </span>
        <span v-else :class="getParisProofColorClass(ppmSummary.meanScore, ppmSummary.meanNorm)">
          Gemiddelde score: -<br />
          Gemiddelde norm: -
        </span>
      </p>
    </v-container>
    <v-container>
      <v-row>
        <v-col md="12" sm="6">
          <div class="paris-proof-score">
            <eiffel-tower :score="ppmSummary.meanScore" :norm="ppmSummary.meanNorm"></eiffel-tower>
          </div>
        </v-col>
        <v-col md="12" sm="6">
          <div class="paris-proof-legend">
            <div class="item"><span class="color color-red"></span> Onvoldoende score</div>
            <div class="item"><span class="color color-orange"></span> Matige score</div>
            <div class="item"><span class="color color-green"></span> Onder of gelijk aan de norm</div>
          </div>

          <div class="paris-proof-about">
            <h2>Over Paris Proof</h2>
            <p>
              <strong>Paris Proof</strong> is gebaseerd op de door Dutch Green Building Council opgestelde doelwaarden voor het realiseren van de 2050-doelstelling van het akkoord van Parijs.
              Uitgangspunt bij de getalswaarde van de Paris Proof doelstelling per gebouwtype is dat met de in 2050 verwachte beschikbare hoeveelheid duurzame energie alle gebouwen van energie kunnen
              worden voorzien als de gebouwen een energiegebruik hebben dat maximaal gelijk is aan de Paris Proof getalswaarden. Daarmee zou voldaan worden aan de doelselling van het akkoord van
              Parijs en het Nederlandse klimaatakkoord.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </perfect-scrollbar>
</template>
