<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getAppliedByToken } from "@/services/api/measure.api";
import GridPagination from "@/components/general/GridPagination.vue";
import MeasureKindLogo from "@/components/app/measures/MeasureKindLogo.vue";
import { formatThousandNoFraction, formatEuroNoFraction } from "@/composables/formatNumbers";
import useGrid27 from "@/composables/grid27.js";
import useDateTimeFormatter from "@/composables/formatDateTime.js";

const { formatDateUtc } = useDateTimeFormatter();

const emits = defineEmits(["changed-selection", "fetch-data-complete"]);
const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
  fetchDataComplete: fetchDataComplete,
});

const props = defineProps({
  token: String,
  showSelect: { type: Boolean, default: false },
  hideInvest: { type: Boolean, default: false },
  hideKind: { type: Boolean, default: false },
  hideEdit: { type: Boolean, default: false },
});

const _headers = [
  {
    text: "",
    align: "left",
    sortable: false,
    value: "edit",
    width: "20",
    showEdit: true,
  },
  { text: "Gebouw", value: "address", width: "20%" },
  { text: "Code", value: "definition.code", width: "80" },
  { text: "Maatregel", value: "definition.name" },
  { text: "Investering (€)", value: "investment", width: "10%", align: "right", showInvest: true },
  { text: "Voorsteldatum", value: "proposalDate", width: "10%", align: "right", show: true },
  { text: "Terugverdientijd (jaren)", value: "recoupedYears", width: "10%", align: "right", showInvest: true },
  { text: "Totale besparing CO₂ (kg)", value: "reductionCo2", width: "10%", align: "right", showInvest: true },
  { text: "Status", value: "status.descr", width: "10%" },
  { text: "Zelfstandig moment", value: "definition.isApplicableAtIndependentMoment", width: "10%" },
  { text: "Soort", value: "kind", width: "80", showKind: true },
];
const headers = computed(() => {
  let newHeaders = _headers;
  if (props.hideEdit) {
    newHeaders = newHeaders.filter((x) => !x.showEdit);
  } else {
    newHeaders = newHeaders.filter((x) => !x.showEdit || x.showEdit == true);
  }
  if (props.hideInvest) {
    newHeaders = newHeaders.filter((x) => !x.showInvest);
  } else {
    newHeaders = newHeaders.filter((x) => !x.showInvest || x.showInvest == true);
  }

  if (props.hideKind) {
    newHeaders = newHeaders.filter((x) => !x.showKind);
  } else {
    newHeaders = newHeaders.filter((x) => !x.showKind || x.showKind == true);
  }
  return newHeaders;
});

const selected = ref([]);

watch(
  () => props.token,
  () => {
    //console.log("AppliedOverviewGrid fetchFirstPage ");
    if (props.showSelect) {
      pageSize.value = 1000;
    }
    fetchFirstPage();
  },
  { immediate: true }
);

watch(
  () => selected.value,
  () => {
    console.log("AppliedOverviewGrid watch selected ", selected.value);
    emits("changed-selection", { selected: selected.value.map((x) => x.guid) });
  },
  { immediate: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  console.log("AppliedOverviewGrid getData ", props.token, pageNumber, pageSize, sortBy, sortDesc);
  if (props.token) {
    const pageNumber0 = pageNumber - 1;
    const response = await getAppliedByToken(props.token, pageNumber0, pageSize, sortBy, sortDesc);
    return response;
  } else {
    return undefined;
  }
}

function fetchDataComplete(payload) {
  emits("fetch-data-complete", payload);
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  console.log("AppliedOverviewGrid fetchError", value);
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
      class="overview-table"
      @update:options="changedOptions"
      :show-select="showSelect"
      v-model="selected"
      item-key="guid"
    >
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn icon small color="primary" :to="{ name: 'AppliedDetails', params: { appliedId: item.guid } }">
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.investment`]="{ item }">
        {{ formatEuroNoFraction(item.investment) }}
      </template>
      <template v-slot:[`item.proposalDate`]="{ item }">
        {{ formatDateUtc(item.proposalDate) }}
      </template>
      <template v-slot:[`item.definition.isApplicableAtIndependentMoment`]="{ item }">
        {{ item.definition.isApplicableAtIndependentMoment === true ? "Ja" : "Nee" }}
      </template>
      <template v-slot:[`item.kind`]="{ item }">
        <MeasureKindLogo :kind="item.definition.kind.key" :description="item.definition.kind.descr"></MeasureKindLogo>
      </template>
      <template v-slot:[`item.reductionCo2`]="{ item }">
        <span v-if="item.reductionCo2 != null">{{ formatThousandNoFraction(item.reductionCo2) }} kg </span>
      </template>
    </v-data-table>
    <grid-pagination v-if="!showSelect" :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
