<script lang="ts" setup>
import { ref, getCurrentInstance } from "vue";
import router from "@/router/index";

const props = defineProps({
  paoId: { type: String, default: "" },
  ecmFunctionCode: { type: String, default: "" },
  ecmFunctionName: { type: String, default: "" },
  certified: { type: Boolean, default: false },
  addressId: { type: String, default: "" },
});

const dialog = ref(false);

function selectMeasure(item) {
  router.push({ name: "AppliedDetailsNew", params: { addressId: props.addressId, definitionId: item.id } });
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function onCancel() {
  proxy.$toaster.warning("Maatregel toevoegen geannuleerd.");
  dialog.value = false;
}
</script>

<template>
  <v-dialog v-model="dialog" scrollable max-width="80%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon dark left>mdi-plus</v-icon>
        Maatregel toevoegen
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <header class="app-welcome-message">
          <h1>Overzicht van {{ certified ? "erkende" : "aanvullende" }} maatregelen</h1>
        </header>
        <v-spacer></v-spacer>
        <v-btn text @click="onCancel()">
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-layout>
          <measures-selection :certified="certified" :ecmFunctionCode="ecmFunctionCode" :ecmFunctionName="ecmFunctionName" @single-select-measure="selectMeasure" />
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-container fluid>
          <v-row>
            <v-col class="form-group">
              <v-btn dark class="primary" title="Annuleren" @click.prevent="onCancel()">
                <v-icon dark left>mdi-cancel</v-icon>
                Annuleren
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
