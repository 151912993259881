const emptyGuid = "00000000-0000-0000-0000-000000000000";

function isEmptyGuid(value) {
  return (
    value == null || // NULL value
    value == undefined || // undefined
    value == "undefined" || // undefined
    value == emptyGuid
  ); // Guid empty
}

const labels = [
  { id: "A+++++", text: "A+++++" },
  { id: "A++++", text: "A++++" },
  { id: "A+++", text: "A+++" },
  { id: "A++", text: "A++" },
  { id: "A+", text: "A+" },
  { id: "A", text: "A" },
  { id: "B", text: "B" },
  { id: "C", text: "C" },
  { id: "D", text: "D" },
  { id: "E", text: "E" },
  { id: "F", text: "F" },
  { id: "G", text: "G" },
];

export { emptyGuid, isEmptyGuid, labels };
