<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  isLoading: Boolean,
  minHeight: Number,
});

const loaderStyles = computed(() =>
  props.minHeight
    ? {
        minHeight: `${props.minHeight}px`,
      }
    : {}
);
</script>

<template>
  <v-container :style="loaderStyles" fill-height>
    <v-row class="overview-loader" align="center">
      <v-col class="text-center">
        <span class="icon-loader-icon" :class="{ spinner: isLoading }"></span>
      </v-col>
    </v-row>
  </v-container>
</template>
