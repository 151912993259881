<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";

import { addCertifiedMeasures, severityCode } from "@/services/api/building.api";
import { getBuildingCategories } from "@/services/api/measure.api";
import CertifiedCategories from "@/components/app/measures/CertifiedCategories.vue";

const emits = defineEmits(["rerender-measures-grid"]);

const props = defineProps({
  addressId: {
    type: String,
    default: null,
  },
});

const dialog = ref(false);
const selectedCategories = ref([]);
const selectedCategoriesProp = ref([]);
const busy = ref(false);

watch(
  () => props.addressId,
  () => {
    if (props.addressId) {
      loadBuildingCategories(props.addressId);
    }
  },
  { immediate: true }
);

function categorySelected(payload) {
  selectedCategories.value = [...payload.selected];
  // console.log("ApplyCertifiedMeasures, categorySelected, selectedCategories ", selectedCategories.value);
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function loadCertifiedMeasures() {
  if (confirm("Weet u zeker dat u alle erkende maatregelen ingeladen moeten worden? Deze actie is niet terug te draaien.")) {
    busy.value = true;
    // console.log("ApplyCertifiedMeasures, loadCertifiedMeasures, selectedCategories: ", selectedCategories.value);
    const response = await addCertifiedMeasures([props.addressId], selectedCategories.value);
    if (response.severity <= severityCode.warning && response.data.results.length == 1 && response.data.results[0].code === "Success") {
      dialog.value = false;
      emits("rerender-measures-grid", true);
    } else {
      proxy.$toaster.error("Er zijn geen erkende maatregelen toegevoegd.");
    }
  }
  busy.value = false;
}

async function loadBuildingCategories(addressId) {
  const response = await getBuildingCategories(addressId);
  // console.log("ApplyCertifiedMeasures, getBuildingCategories, response.data: ", response.data);

  if (response.severity <= severityCode.warning) {
    selectedCategoriesProp.value = [...response.data];
  }
}

async function onCancel() {
  proxy.$toaster.warning("Maatregelen toevoegen geannuleerd.");
  dialog.value = false;
}
</script>

<template>
  <v-dialog v-model="dialog" scrollable max-width="80%">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind:class="'btn-tooltip'">
        <!-- Vuetify staat het niet toe (of het is een bug in hun css) om een dark button te disablen. De button wordt dan onzichtbaar. Vandaar bij dark de omgekeerde conditie als bij disabled -->
        <v-btn color="primary" v-bind="attrs" v-on="on">
          <v-icon dark left>mdi-plus</v-icon>
          Erkende maatregelen inladen
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title>
        <header class="app-welcome-message">
          <h2>Welke categorieen zijn aanwezig in dit gebouw?</h2>
        </header>
        <v-spacer></v-spacer>
        <v-btn text @click="onCancel()">
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <certified-categories :selectedCategoriesProp="selectedCategoriesProp" :resetSelectedCategories="dialog" @changed-selection="categorySelected"></certified-categories>
      </v-card-text>
      <v-card-actions>
        <v-container fluid>
          <v-row>
            <v-col cols="auto" class="form-group">
              <v-btn class="primary" @click="loadCertifiedMeasures" :disabled="busy" :dark="!busy">
                <v-icon dark left>mdi-import</v-icon>
                Importeren
              </v-btn>
            </v-col>
            <v-col class="form-group">
              <v-btn dark class="primary" title="Annuleren" @click.prevent="onCancel()">
                <v-icon dark left>mdi-cancel</v-icon>
                Annuleren
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
