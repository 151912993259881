var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "overview-table",
        attrs: {
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("thead", [
                    _c("tr", [
                      _c("th", {
                        staticStyle: { "border-bottom-style": "none" },
                        attrs: { colspan: "5" },
                      }),
                      _c(
                        "th",
                        {
                          staticStyle: { "border-bottom-style": "solid" },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v("Huidig label (Vabi)")]
                      ),
                      _c("th", {
                        staticStyle: { "border-bottom-style": "none" },
                        attrs: { colspan: "1" },
                      }),
                      _c(
                        "th",
                        {
                          staticStyle: { "border-bottom-style": "solid" },
                          attrs: { colspan: "6" },
                        },
                        [_vm._v("Geregistreerd label (VABI)")]
                      ),
                      _c("th", {
                        staticStyle: { "border-bottom-style": "none" },
                        attrs: { colspan: "1" },
                      }),
                      _c(
                        "th",
                        {
                          staticStyle: { "border-bottom-style": "solid" },
                          attrs: { colspan: "7" },
                        },
                        [_vm._v("Geregistreerd label (EPOnline)")]
                      ),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: `item.dataScore`,
              fn: function ({ item }) {
                return [
                  item.dataScoreChecked == "notOK"
                    ? _c("v-icon", { attrs: { large: "", color: "red" } }, [
                        _vm._v(" mdi-alert-octagon "),
                      ])
                    : item.dataScoreChecked == "warning"
                    ? _c("v-icon", { attrs: { large: "", color: "orange" } }, [
                        _vm._v(" mdi-alert "),
                      ])
                    : item.dataScoreChecked == "OK"
                    ? _c("v-icon", { attrs: { large: "", color: "green" } }, [
                        _vm._v(" mdi-checkbox-marked-circle "),
                      ])
                    : _c("v-icon", [_vm._v("mdi-tilde")]),
                ]
              },
            },
            {
              key: `item.reference`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-layout",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "d-flex align-center justify-space-between",
                                    },
                                    on
                                  ),
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " + _vm._s(item.reference) + " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(item.description) +
                            " (locRef: " +
                            _vm._s(item.locationReference) +
                            ") "
                        ),
                      ]),
                    ]
                  ),
                ]
              },
            },
            {
              key: `item.vabiCurrentLabelBeng2`,
              fn: function ({ item }) {
                return [
                  item.vabiCurrentLabelBeng2 != null
                    ? _c("span", [_vm._v(_vm._s(item.vabiCurrentLabelBeng2))])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.vabiCurrentLabel`,
              fn: function ({ item }) {
                return [
                  _c("energy-label", {
                    staticClass: "small",
                    attrs: { label: item.vabiCurrentLabel, estimate: false },
                  }),
                ]
              },
            },
            {
              key: `item.vabiRegisteredLabelBeng2`,
              fn: function ({ item }) {
                return [
                  item.vabiRegisteredLabelBeng2 != null
                    ? _c("span", [
                        _vm._v(_vm._s(item.vabiRegisteredLabelBeng2)),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.vabiRegisteredLabel`,
              fn: function ({ item }) {
                return [
                  _c("energy-label", {
                    staticClass: "small",
                    attrs: { label: item.vabiRegisteredLabel, estimate: false },
                  }),
                ]
              },
            },
            {
              key: `item.vabiRegisteredLabelRecordedDate`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _setup.formatDateUtc(
                          item.vabiRegisteredLabelRecordedDate
                        )
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.vabiRegistrationDate`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_setup.formatDateUtc(item.vabiRegistrationDate)) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.epOnlineLabelBeng2`,
              fn: function ({ item }) {
                return [
                  item.epOnlineLabelBeng2 != null
                    ? _c("span", [_vm._v(_vm._s(item.epOnlineLabelBeng2))])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.epOnlineLabel`,
              fn: function ({ item }) {
                return [
                  _c("energy-label", {
                    staticClass: "small",
                    attrs: { label: item.epOnlineLabel, estimate: false },
                  }),
                ]
              },
            },
            {
              key: `item.epOnlineRecordedDate`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_setup.formatDateUtc(item.epOnlineRecordedDate)) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.epOnlineRegistrationDate`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _setup.formatDateUtc(item.epOnlineRegistrationDate)
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.documentId`,
              fn: function ({ item }) {
                return [
                  item.documentExists == "true"
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-layout",
                                      _vm._g(
                                        { staticClass: "d-flex align-center" },
                                        on
                                      ),
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _setup.getDocumentUrl(
                                                item.documentId
                                              ),
                                              download:
                                                item.documentDescription,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  large: "",
                                                  color: "primary",
                                                },
                                              },
                                              [_vm._v(" mdi-file ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(item.documentDescription) + " "
                            ),
                          ]),
                        ]
                      )
                    : [
                        item.documentExists == "false"
                          ? _c(
                              "span",
                              [
                                _vm._v(" -"),
                                _c(
                                  "v-icon",
                                  { attrs: { medium: "", color: "gray" } },
                                  [_vm._v(" mdi-file-hidden ")]
                                ),
                                _vm._v("- "),
                              ],
                              1
                            )
                          : _c("v-icon", [_vm._v("mdi-tilde")]),
                      ],
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(_setup.GridPagination, {
        attrs: {
          pageNumber: _setup.pageNumber,
          pageSize: _setup.pageSize,
          pageCount: _setup.pageCount,
        },
        on: { changedPaging: _setup.changedPaging },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }