<script lang="ts" setup>
import { computed } from "vue";
import { getParisProofColorClass } from "@/composables/colors";

const props = defineProps({
  score: {
    type: Number,
    default: 0,
  },
  norm: {
    type: Number,
    default: 0,
  },
});

const height = computed(() => {
  if (props.score === 0) {
    return 0;
  }

  switch (getParisProofColorClass(props.score, props.norm)) {
    case "color-green":
      return 30.3;
    case "color-orange":
      return 60.5;
    case "color-red":
      return 100;
    default:
      return 0;
  }
});

const offset = computed(() => 100 - height.value + "%");
</script>

<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 251.7 441.9"
    style="enable-background: new 0 0 251.7 441.9"
    xml:space="preserve"
  >
    <defs>
      <clipPath id="clipPath">
        <rect x="0" :y="offset" width="100%" :height="height + '%'" />
      </clipPath>
    </defs>
    <g id="fill_1_" style="clip-path: url(#clipPath)">
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="125.9" y1="1077.9" x2="125.9" y2="636.1" gradientTransform="matrix(1 0 0 1 0 -636)">
        <stop offset="0.305" style="stop-color: #809700; stop-opacity: 0.8" />
        <stop offset="0.305" style="stop-color: #829600; stop-opacity: 0.8" />
        <stop offset="0.305" style="stop-color: #899200; stop-opacity: 0.8" />
        <stop offset="0.305" style="stop-color: #948b01; stop-opacity: 0.8" />
        <stop offset="0.305" style="stop-color: #a58101; stop-opacity: 0.8" />
        <stop offset="0.305" style="stop-color: #bb7402; stop-opacity: 0.8" />
        <stop offset="0.305" style="stop-color: #d66503; stop-opacity: 0.8" />
        <stop offset="0.305" style="stop-color: #e75b04; stop-opacity: 0.8" />
        <stop offset="0.5626" style="stop-color: #e75b04; stop-opacity: 0.8" />
        <stop offset="0.605" style="stop-color: #e75b04; stop-opacity: 0.8" />
        <stop offset="0.605" style="stop-color: #e45805; stop-opacity: 0.8" />
        <stop offset="0.605" style="stop-color: #da4d08; stop-opacity: 0.8" />
        <stop offset="0.605" style="stop-color: #ca3b0e; stop-opacity: 0.8" />
        <stop offset="0.605" style="stop-color: #b42216; stop-opacity: 0.8" />
        <stop offset="0.605" style="stop-color: #ac1919; stop-opacity: 0.8" />
        <stop offset="0.6608" style="stop-color: #ac1919; stop-opacity: 0.8" />
        <stop offset="1" style="stop-color: #ac1919; stop-opacity: 0.8" />
      </linearGradient>
      <path
        class="st0"
        d="M247.8,433.8H231c-0.1-0.4-0.2-0.8-0.4-1.2c-13.2-21.2-24.8-43.3-34.7-66.1h10.4c2.2,0,4-1.8,4-4v-24.3
            c0-2.2-1.8-4-4-4h-23.4c-3.2-8.9-6.4-17.9-9.3-27.1c-2.8-8.8-5.2-17.6-7.6-26.6h12c2.2,0,4-1.8,4-4v-20.6c0-2.2-1.8-4-4-4h-19
            c-5.8-25.7-10-51.6-12.8-77.7l0,0c-2.3-21.5-3.6-43.1-3.8-64.7h8.9c2.2,0,4-1.8,4-4V84.9c0-2.2-1.8-4-4-4h-6.7c0,0,0-0.3,0-0.4
            V63.1c0-1.1-0.4-2.1-1.2-2.8l-14.1-13.7V4.1c0-2.2-1.8-4-4-4s-4,1.8-4,4v42.5l-14,13.7c-0.8,0.7-1.2,1.8-1.2,2.8v17.4
            c0,0.2,0,0.3,0,0.4h-6.4c-2.2,0-4,1.8-4,4v20.7c0,2.2,1.8,4,4,4h8.7c-0.2,21.6-1.5,43.2-3.8,64.7l0,0v0.1
            c-2.8,26.1-7,51.9-12.8,77.5h-19c-2.2,0-4,1.8-4,4v20.7c0,2.2,1.8,4,4,4h12c-2.4,9-4.9,17.8-7.6,26.6l0,0c-2.9,9.2-6,18.2-9.2,27.1
            H44.5c-2.2,0-4,1.8-4,4v24.3c0,2.2,1.8,4,4,4h10.4c-9.9,22.9-21.5,45-34.6,66.1c-0.2,0.4-0.4,0.8-0.5,1.2H4c-2.2,0-4,1.8-4,4
            s1.8,4,4,4h96.2c2.2,0,4-1.8,4-4s-1.8-4-4-4h-28c10.7-30.4,44-46.4,74.4-35.7c16.7,5.9,29.8,19,35.7,35.7h-31.6c-2.2,0-4,1.8-4,4
            s1.8,4,4,4h97.1c2.2,0,4-1.8,4-4S250,433.8,247.8,433.8z M107.3,334.2c3.2-8.9,5.8-17.9,8-27.1l0,0c2.1-8.8,3.7-17.6,4.8-26.6h10.4
            c1.1,9,2.8,17.8,4.8,26.6c2.2,9.2,4.9,18.2,8.1,27.1H107.3z"
      />
    </g>
    <g id="outline_1_">
      <g id="outline">
        <g>
          <g>
            <polygon class="st1" points="76.9,272.6 173.8,272.6 174,260 76.9,260 				" />
            <path class="st1" d="M150.6,252c-5.7-25.7-9.9-51.6-12.6-77.7h-25.3c-2.7,26.1-6.9,52.1-12.6,77.7H150.6L150.6,252z" />
            <path
              class="st2"
              d="M150.6,252H100c5.7-25.7,9.8-51.4,12.5-77.5h-8c-2.8,26.1-6.9,51.9-12.7,77.5h-19c-2.2,0-4,1.8-4,4v20.7
					c0,2.2,1.8,4,4,4h12c-2.4,9-5,17.8-7.7,26.6h8.3c2.7-8.6,5.2-17.3,7.5-26.1h-0.2c0,0,0-0.3,0-0.5h19c-1.2,9-2.9,17.8-5,26.6h8.5
					c2.1-8.8,3.7-17.6,4.8-26.6h10.4c1.1,9,2.8,17.8,4.8,26.6h8.4c-2.1-8.8-3.8-17.6-5-26.6h19c0,0.2,0,0.3,0,0.5
					c2.3,8.8,4.9,17.5,7.6,26.1h8.4c-2.8-8.8-5.3-17.6-7.7-26.6h12c2.2,0,4-1.8,4-4V256c0-2.2-1.8-4-4-4h-19
					c-5.8-25.7-10-51.4-12.8-77.5H138C140.8,200.5,144.9,226.3,150.6,252z M174,260l-0.2,12.7H76.9V260H174z"
            />
          </g>
          <g>
            <path class="st1" d="M114,80.8h22.5c0-0.2,0-0.4,0-0.4V64.7l-11.3-11l-11.3,11v15.7h0.2C114.1,80.5,114.1,80.7,114,80.8z" />
            <polygon class="st1" points="147.2,88.8 103.5,88.8 103.7,101.5 147.2,101.5 				" />
            <path class="st1" d="M116.4,109.5c-0.2,21.6-1.5,43.2-3.7,64.7H138c-2.2-21.5-3.5-43.1-3.8-64.7H116.4z" />
            <g>
              <path
                class="st3"
                d="M151.2,109.5c2.2,0,4-1.8,4-4V84.8c0-2.2-1.8-4-4-4h-6.7c0,0,0-0.3,0-0.4V63c0-1.1-0.4-2.1-1.2-2.8
						l-14.1-13.7V4c0-2.2-1.8-4-4-4s-4,1.8-4,4v42.5l-14,13.7c-0.8,0.7-1.2,1.8-1.2,2.8v17.4c0,0.2,0,0.3,0,0.4h-6.4
						c-2.2,0-4,1.8-4,4v20.7c0,2.2,1.8,4,4,4h8.7c-0.2,21.6-1.5,43.5-3.8,65h8c2.2-21.5,3.5-43.4,3.7-65H134c0.2,21.6,1.7,43.5,4,65
						h8.1c-2.3-21.5-3.7-43.4-3.9-65C142.4,109.5,151.2,109.5,151.2,109.5z M114,64.7l11.3-11l11.3,11v15.7c0,0,0,0.3,0,0.4H114
						c0.1-0.1,0.1-0.3,0.1-0.4H114C114,80.4,114,64.7,114,64.7z M103.7,101.5l-0.2-12.7h43.8v12.7H103.7z"
              />
            </g>
          </g>
          <g>
            <path
              class="st4"
              d="M44.5,334.3c-2.2,0-4,1.8-4,4v24.3c0,2.2,1.8,4,4,4h10.4c-9.9,22.9-21.5,45-34.6,66.1
					c-0.2,0.4-0.4,0.8-0.5,1.2H4c-2.2,0-4,1.8-4,4s1.8,4,4,4h96.2c2.2,0,4-1.8,4-4s-1.8-4-4-4h-28c10.7-30.4,44-46.4,74.4-35.7
					c16.7,5.9,29.8,19,35.7,35.7h-31.6c-2.2,0-4,1.8-4,4s1.8,4,4,4h97.1c2.2,0,4-1.8,4-4s-1.8-4-4-4H231c-0.1-0.4-0.2-0.8-0.4-1.2
					c-13.2-21.2-24.8-43.3-34.7-66.1h10.4c2.2,0,4-1.8,4-4v-24.3c0-2.2-1.8-4-4-4h-23.4c-3.2-8.9-6.4-18-9.3-27.2h-8.4
					c2.9,9.2,6,18.3,9.2,27.2H152c-3.3-8.9-6.1-18-8.3-27.2h-8.4c2.2,9.2,5,18.3,8.1,27.2h-36.1c3.2-8.9,5.8-18,8-27.2h-8.5
					c-2.2,9.2-5,18.3-8.2,27.2H76.4c3.2-8.9,6.3-18,9.2-27.2h-8.3c-2.9,9.2-6.1,18.3-9.3,27.2H44.5z M222,433.9h-0.2h-31.3
					c0-0.2,0-0.4,0-0.6c-11.1-35-48.6-54.3-83.5-43.1c-20.5,6.5-36.6,22.6-43.2,43.1c0,0.2,0,0.4,0,0.6H29
					c13.2-21.5,24.8-44,34.8-67.3h123.5C197.1,389.9,208.8,412.4,222,433.9z M202.2,342.3v16.3H48.5v-16.3H202.2z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: url(#SVGID_1_);
}

.st1 {
  fill: none;
}

.st2 {
  fill: #e75b04;
}

.st3 {
  fill: #ac1919;
}

.st4 {
  fill: #809700;
}

#clipPath rect {
  transition: all ease 2s;
}
</style>
