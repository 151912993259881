var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", "max-width": "80%" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", dark: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v("mdi-plus"),
                  ]),
                  _vm._v(" Maatregel toevoegen "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _setup.dialog,
        callback: function ($$v) {
          _setup.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("header", { staticClass: "app-welcome-message" }, [
                _c("h1", [
                  _vm._v(
                    "Overzicht van " +
                      _vm._s(_vm.certified ? "erkende" : "aanvullende") +
                      " maatregelen"
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _setup.onCancel()
                    },
                  },
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                [
                  _c("measures-selection", {
                    attrs: {
                      certified: _vm.certified,
                      ecmFunctionCode: _vm.ecmFunctionCode,
                      ecmFunctionName: _vm.ecmFunctionName,
                    },
                    on: { "single-select-measure": _setup.selectMeasure },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { dark: "", title: "Annuleren" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _setup.onCancel()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-cancel"),
                              ]),
                              _vm._v(" Annuleren "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }