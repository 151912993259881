var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "widget-item" },
    [
      _c("header", [
        _c("h2", [_vm._v("Overzicht panden")]),
        _c(
          "div",
          { staticClass: "widget-actions" },
          [
            _c(
              "router-link",
              {
                staticClass: "widget-link",
                attrs: { to: { name: "BuildingOverview" } },
              },
              [_vm._v("Bekijk totale overzicht")]
            ),
            _c(
              "information-dropdown",
              {
                attrs: { id: "overzicht-panden" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [_c("h2", [_vm._v("Gebouwenoverzicht")])]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("p", [
                  _vm._v(
                    " Uw portefeuille kan bestaan uit verschillende type gebouwen. We tonen hier de verdeling van uw gebouwen op basis van de functie die het heeft. De functies die we gebruiken zijn gebaseerd op de bedrijfstakken die RVO gebruikt voor de informatieplicht. "
                  ),
                  _c("br"),
                  _c("br"),
                ]),
                _c("h4", [_vm._v("Informatieplicht")]),
                _c("p", [
                  _vm._v(
                    " Hoeveel van uw gebouwen zijn informatieplichtig? Het percentage van uw informatieplichtige gebouwen wordt hier getoond. Niet al uw gebouwen zitten mogelijk boven de norm van de informatieplicht van 50.000 kWh elektriciteit of 25.000 m³ aardgas(equivalent). "
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      !_setup.dataLoaded
        ? _c("loader-spinner", { attrs: { "is-loading": true } })
        : _c(
            "div",
            { staticClass: "widget-wrapper", attrs: { "min-height": 200 } },
            [
              _c(
                "div",
                { staticClass: "widget-chart" },
                [
                  _c("chart-building-functions", {
                    attrs: {
                      height: 200,
                      width: 200,
                      legendPosition: "legend-bottom",
                      sourceData: _setup.buildingFunctions,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "widget-info" }, [
                _c("h3", [
                  _vm._v(" Informatieplichtig "),
                  _c(
                    "span",
                    [
                      _c("number", {
                        attrs: {
                          to: _setup.percentageOfInformationObligation,
                          duration: 1.6,
                        },
                      }),
                      _vm._v("% "),
                    ],
                    1
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Verbruikt uw gebouw per jaar meer dan 50.000 kWh elektriciteit of 25.000 m³ aardgas(equivalent)?"
                  ),
                ]),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }