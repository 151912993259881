var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "inner", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [_c("header", [_c("h2", [_vm._v("Maatregelen")])])]),
          _c("v-col", { attrs: { cols: "auto" } }, [
            _setup.userStore.hasRoleUser && _setup.userStore.isPartyUtility
              ? _c(
                  "span",
                  { staticClass: "pa-2" },
                  [
                    _c(_setup.ApplyCertifiedMeasures, {
                      attrs: {
                        addressId: _setup.props.addressId,
                        ecmFunctionCode: _setup.props.ecmFunctionCode,
                        ecmFunctionName: _setup.props.ecmFunctionName,
                      },
                      on: {
                        "rerender-measures-grid":
                          _setup.handleRerenderMeasuresGrid,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _setup.userStore.hasRoleUser
              ? _c(
                  "span",
                  { staticClass: "pa-2" },
                  [
                    _c("add-measure-modal", {
                      attrs: { addressId: _setup.props.addressId },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "span",
              { staticClass: "pa-2" },
              [
                _c("applied-logging-modal", {
                  attrs: { addressId: _setup.props.addressId },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "overview-filters align-items-start mt-4" },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("filter-set", {
                attrs: {
                  name: _setup.FilterTokenName.Applied,
                  filters: _setup.filters,
                },
                on: {
                  "filter-search": _setup.handleFilterSearch,
                  "filters-changed27": _setup.handleFiltersChanged,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(_setup.BuildingAppliedGrid, {
                attrs: {
                  addressId: _setup.props.addressId,
                  filterData: _setup.filterData,
                  currentPartyType: _setup.userStore.currentPartyType,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }