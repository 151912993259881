var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "callback", attrs: { "fill-height": "" } },
    [
      _c(
        "v-row",
        [_c("v-col", [_c("div", [_vm._v("Aanmelding voltooien...")])])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }