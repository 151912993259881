<script lang="ts" setup>
import { computed } from "vue";

import innaxLogo from "@/assets/img/mapMarkerINNAX.png";
import rvoLogo from "@/assets/img/logo-rvo.png";
import rvoLogo2019 from "@/assets/img/logo-rvo-2019.png";

const props = defineProps({
  kind: {
    type: String,
  },
  description: {
    type: String,
  },
});

const logo = computed(() => {
  switch (props.kind) {
    case "EML":
      return rvoLogo2019;
    case "EML2023":
      return rvoLogo;
    case "IM":
      return innaxLogo;
    default:
      return innaxLogo;
  }
});
</script>

<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <div class="definition-kind" v-on="on">
        <img :src="logo" />
      </div>
    </template>
    <span>{{ props.description }}</span>
  </v-tooltip>
</template>
