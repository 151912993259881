<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import { customChartLegendCallback } from "@/composables/customChartLegend.js";

const props = defineProps({
  sourceData: {
    type: Object,
    required: true,
  },

  width: {
    type: Number,
    default: 150,
  },
  height: {
    type: Number,
    default: 150,
  },

  legendPosition: {
    type: String,
    default: "legend-side",
  },
});

const htmlLegend = ref(null);
const chart = ref({
  labels: null,
  data: null,
  backgroundColors: null,
});
const options = ref({
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 2000,
  },
  legend: {
    display: false,
  },
  legendCallback: customChartLegendCallback,
});

const totalHomes = ref(0);

const chartStyles = computed(() => {
  return {
    width: `${props.width}px`,
    height: `${props.height}px`,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  };
});

function handleChartRendered(payload) {
  htmlLegend.value = payload;
}

watch(
  () => props.sourceData,
  () => {
    let backgroundColors = [];
    let values = [];
    let th = 0;

    Object.values(props.sourceData).forEach((data) => {
      backgroundColors.push(data.color ? data.color : "#CCCCCC");
      values.push(data.val);
      th += data.val;
    });

    chart.value.backgroundColors = backgroundColors;
    chart.value.labels = Object.keys(props.sourceData);
    chart.value.data = values;

    totalHomes.value = th;
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <v-container class="vue-chart vue-chart-building-functions inline-text" pa-0 :class="legendPosition">
    <v-row>
      <v-col class="vue-chart-wrapper" cols="auto">
        <advanced-doughnut-chart :data-set="chart" :options="options" :styles="chartStyles" @chart-rendered="handleChartRendered"></advanced-doughnut-chart>
        <div class="vue-chart-text">
          <span
            >Totaal <strong>{{ totalHomes }}</strong> woningen</span
          >
        </div>
      </v-col>
      <v-col class="vue-chart-legend"><span v-html="htmlLegend"></span></v-col>
    </v-row>
  </v-container>
</template>
