<script>
import { Doughnut } from "vue-chartjs";

export default {
  name: "AdvancedDoughnutChart",

  extends: Doughnut,

  props: ["dataSet", "options"],

  data() {
    return {
      htmlLegend: null,
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            borderColor: "#FFFFFF",
            borderWidth: 2,
          },
        ],
      },
    };
  },

  mounted() {
    //console.log("AdvancedDoughnutChart options", this.options);

    this.renderChart(this.chartData, this.options);
    this.htmlLegend = this.generateLegend();
  },

  watch: {
    dataSet: {
      deep: true,
      immediate: true,
      handler(chartData) {
        //console.log("AdvancedDoughnutChart chart ", chartData);

        this.$set(this.chartData, "labels", chartData.labels);
        this.$set(this.chartData.datasets[0], "data", chartData.data);
        this.$set(this.chartData.datasets[0], "backgroundColor", chartData.backgroundColors);

        if (this.$data._chart) {
          this.$data._chart.update();
        }

        this.htmlLegend = this.generateLegend();
      },
    },

    htmlLegend: function () {
      this.$emit("chart-rendered", this.htmlLegend);
    },
  },
};
</script>
