<script lang="ts" setup>
import { ref, watch } from "vue";
//import _ from "lodash";
import { formatEuroNoFraction, formatThousandNoFraction } from "@/composables/formatNumbers";

const props = defineProps({
  height: {
    type: Number,
    default: 250,
  },

  years: {
    type: Array,
    default: () => [],
  },

  investments: {
    type: Array,
    default: () => [],
  },

  savings: {
    type: Array,
    default: () => [],
  },
});

const initialDataLoaded = ref(false);
const chartdata = ref({});
const options = ref({
  responsive: true,
  maintainAspectRatio: false,
  hoverMode: "index",
  stacked: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Jaartal",
          fontStyle: "bold",
        },
      },
    ],
    yAxes: [
      {
        id: "y-axis-1",
        display: true,
        type: "linear",
        position: "left",
        scaleLabel: {
          display: true,
          labelString: "Verbruik in kWh/m²",
          fontStyle: "bold",
        },
      },
      {
        type: "linear",
        display: true,
        position: "right",
        id: "y-axis-2",
        ticks: {
          min: 0,
        },
        gridLines: {
          drawOnChartArea: false,
        },
        scaleLabel: {
          display: true,
          labelString: "Investering in euro's",
          fontStyle: "bold",
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const label = data.datasets[tooltipItem.datasetIndex].label;
        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        let valueTxt = value.toLocaleString();

        if (tooltipItem.datasetIndex === 0) {
          valueTxt = formatThousandNoFraction(value);
        }

        if (tooltipItem.datasetIndex === 1) {
          valueTxt = formatEuroNoFraction(value);
        }

        return `${label}: ${valueTxt}`;
      },
    },
  },
});
const htmlLegend = ref();

watch(
  () => props.years,
  () => {
    updateChart();
  },
  { deep: true }
);

watch(
  () => props.savings,
  () => {
    updateChart();
  },
  { deep: true }
);

watch(
  () => props.investments,
  () => {
    updateChart();
  },
  { deep: true }
);

function handleChartRendered(payload) {
  htmlLegend.value = payload;
}

function updateChart() {
  chartdata.value = {
    labels: props.years,
    datasets: [
      {
        label: "Verbruik (kWh/m²)",
        fill: false,
        yAxisID: "y-axis-1",
        borderColor: "#809700",
        backgroundColor: "#809700",
        data: props.savings,
      },
      {
        label: "Investering",
        borderColor: "#E75B04",
        backgroundColor: "#E75B04",
        fill: false,
        yAxisID: "y-axis-2",
        data: props.investments,
      },
    ],
  };

  initialDataLoaded.value = true;
}
</script>

<template>
  <v-container fluid pa-0>
    <v-row class="vue-chart vue-chart-investment-vs-savings">
      <v-col class="vue-chart-legend"><span v-html="htmlLegend"></span></v-col>
      <v-col class="vue-chart-wrapper" cols="auto">
        <advanced-line-chart v-if="initialDataLoaded" :chart-data="chartdata" :options="options" @chart-rendered="handleChartRendered"></advanced-line-chart>
      </v-col>
    </v-row>
  </v-container>
</template>
