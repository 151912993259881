import { apiClient, getRequest, postRequest, severityCode } from "./httpClient";

const END_POINT = "building";

// you can pass arguments to use as request parameters/data
// const getBuilding = async (buidlingId) => await apiClient.get(END_POINT, { buidlingId });

const getBuildingsByToken = async (token, currentPage, pageSize, sortBy, sortDesc) => {
  const response = await apiClient.get(`${END_POINT}/${token}/list`, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } });
  return response;
};

const getComplexesByToken = async (token, currentPage, pageSize, sortBy, sortDesc) => {
  const response = await apiClient.get(`${END_POINT}/${token}/complexes/list`, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } });
  return response;
};

const getSubLocationsByToken = async (token, currentPage, pageSize, sortBy, sortDesc) => {
  const response = await apiClient.get(`${END_POINT}/${token}/sublocations/list`, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } });
  return response;
};

const getBuildingFilterOptions = async (token, fieldName, text) => {
  return await getRequest(`${END_POINT}/${token}/autocomplete/${fieldName}`, { text: text });
};

const getSubLocationsFilterOptions = async (token, fieldName, text) => {
  return await getRequest(`${END_POINT}/${token}/sublocations/autoComplete/${fieldName}`, { text: text });
};

const getBuildingEcmFunctions = async (token) => {
  const response = await apiClient.get(`${END_POINT}/${token}/ecmfunctions`);
  return response.data;
};

const getBuildingPartyRoles = async (token) => {
  const response = await apiClient.get(`${END_POINT}/${token}/partyRoles`);
  return response.data;
};

const getBuilding = async (addressId) => {
  const response = await getRequest(`${END_POINT}/details`, { partyAddressableObjectId: addressId });
  return response;

  // let errorResponse = null;

  // let response = await apiClient.get(`${END_POINT}/details`, { params: { partyAddressableObjectId: addressId } }).catch((error) => {
  //   errorResponse = error.toString();
  // });

  // if (errorResponse) {
  //   response.data = "";
  // }
  // return { data: response.data, error: errorResponse };
};

const getStartingPoint = async (partyAddressableObjectId) => {
  return getRequest(`${END_POINT}/${partyAddressableObjectId}/startingPoint`);
};

const getStartingPointOptions = async () => {
  let response = await apiClient.get(`${END_POINT}/startingPointOptions`);
  return response.data;
};

const calculateMeasures = async (partyAddressableObjectId, ambition, strategy, condition) => {
  const response = await postRequest(`${END_POINT}/${partyAddressableObjectId}/calculateMeasures`, { ambition: ambition, strategy: strategy, condition: condition });
  console.log("building.api, calculateMeasures response: ", response);
  if (response.severity <= severityCode.warning) {
    if (response.data.status === "Error") {
      response.severity = severityCode.error;
      response.message = response.data.message;
    }
  }
  return response;
};

const getComplex = async (addressId) => {
  let errorResponse = null;

  let response = await apiClient.get(`${END_POINT}/complexDetails`, { params: { partyAddressableObjectId: addressId } }).catch((error) => {
    errorResponse = error.toString();
  });

  if (errorResponse) {
    response.data = "";
  }
  return { data: response.data, error: errorResponse };
};

const getParisProofScore = async (partyAddressableObjectId) => {
  const response = await getRequest(`${END_POINT}/${partyAddressableObjectId}/parisproofscore`);
  console.log("building.api, getParisProofScore response: ", response);
  return response;
};

const getBuildingYearlyUsages = async (partyAddressableObjectId) => {
  return getRequest(`${END_POINT}/${partyAddressableObjectId}/yearlyUsages`);
};

const getBuildingRegisters = async (partyAddressableObjectId, year) => {
  return getRequest(`${END_POINT}/${partyAddressableObjectId}/registers`, { year: year });
};

const addCertifiedMeasures = async (partyAddressableObjectIds, categoryKeys) => {
  const response = await postRequest(`${END_POINT}/addCertifiedMeasures`, { partyAddressableObjectIds: [...partyAddressableObjectIds], categoryKeys: [...categoryKeys] });
  return response;
};

const addAppliedMeasure = async (partyAddressableObjectId, appliedData) => {
  const response = await apiClient.post(`${END_POINT}/${partyAddressableObjectId}/addAppliedMeasure`, { ...appliedData });
  return response;
};

export {
  getBuildingsByToken,
  getComplexesByToken,
  getSubLocationsByToken,
  getBuildingFilterOptions,
  getSubLocationsFilterOptions,
  getBuildingPartyRoles,
  getBuildingEcmFunctions,
  getBuilding,
  getStartingPointOptions,
  getStartingPoint,
  calculateMeasures,
  getComplex,
  getParisProofScore,
  getBuildingYearlyUsages,
  getBuildingRegisters,
  addCertifiedMeasures,
  addAppliedMeasure,
  severityCode,
};
