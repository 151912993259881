<script lang="ts" setup>
import { ref, computed } from "vue";
import { useUserStore } from "@/stores/user";
import { useFiltertokenStore } from "@/stores/filtertoken";

const userStore = useUserStore();
const filtertokenStore = useFiltertokenStore();

const refDate = ref(new Date()); // Kan later gewijzigd worden mbv een tijdlijn

const welcomeName = computed(() => {
  const name = userStore.friendlyName;
  return name.length > 0 ? ` ${name}` : "";
});

const token = computed(() => {
  if (!filtertokenStore.projectToken) {
    loadProjectToken();
  }
  return filtertokenStore.projectToken;
});

async function loadProjectToken() {
  await filtertokenStore.getProjectToken();
}
</script>

<template>
  <v-container fluid pa-0 fill-height>
    <v-row>
      <v-col lg="3" md="4" sm="12" class="app-paris-proof-sidebar">
        <net-heat-requirement :token="token" :refDate="refDate"></net-heat-requirement>
      </v-col>
      <v-col lg="9" md="8" sm="12" class="app-dashboard-overview">
        <v-container fluid px-0 py-0>
          <v-row>
            <v-col class="app-welcome-message">
              <h1>Welkom{{ welcomeName }},</h1>
              <p>Hieronder wordt uw persoonlijke dashboard weergegeven</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <masonry class="app-dashboard-widget-overview" :cols="{ default: 2, 1264: 1 }" :gutter="24">
                <v-layout class="inner">
                  <data-availability v-if="token" :token="token"></data-availability>
                </v-layout>

                <v-layout class="inner">
                  <complex-functions v-if="token" :token="token"></complex-functions>
                </v-layout>

                <!-- todo tijdelijk uitgezet omdat Max geen lege widget wilde voor demo -->
                <!-- <v-layout class="inner" v-if="currentPartyType == 'Homes'">
                  <co2-emissions v-if="token" :token="token" :refDate="refDate"></co2-emissions>
                </v-layout> -->

                <v-layout class="inner">
                  <building-energylabels-status v-if="token" :token="token" :partyType="userStore.currentPartyType" labelSource="EPOnline"></building-energylabels-status>
                </v-layout>

                <v-layout class="inner">
                  <sustainability-radar v-if="userStore.currentPartyReference" :partyReference="userStore.currentPartyReference"></sustainability-radar>
                </v-layout>

                <v-layout class="inner">
                  <building-measures v-if="token" :token="token" :certified="false" :refDate="refDate">
                    <template v-slot:headerwidget>
                      <h2>Maatregelen</h2>
                    </template>
                    <template v-slot:infotext>
                      <p>In dit overzicht wordt getoond op basis van al uw complexen wat de voortgang is.</p>
                    </template>
                    <template v-slot:nomeasurestext>
                      <p>Er zijn nog geen maatregelen aan dit project toegevoegd</p>
                    </template>
                  </building-measures>
                </v-layout>

                <v-layout class="inner">
                  <homes-gasless v-if="token" :token="token"></homes-gasless>
                </v-layout>
              </masonry>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
