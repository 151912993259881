<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";

import { getHomesGaslessDashboard } from "@/services/api/chart.api";

const props = defineProps({
  token: {
    type: String,
  },
});

const chart = ref({
  homesGasless: {},
});
const isLoading = ref(true);

watch(
  () => props.token,
  () => {
    fetchHomesGaslessData();
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchHomesGaslessData() {
  isLoading.value = true;
  console.log("HomesGasless calling  (2) getHomesGaslessDashboard(" + props.token + ")");
  const response = await getHomesGaslessDashboard(props.token);
  if (response) {
    chart.value.homesGasless = {};
    chart.value.homesGasless["Gasloze woningen"] = { val: response.data.homesNotUsingGas, color: "#007500" };
    chart.value.homesGasless["Woningen op gas"] = { val: response.data.homesUsingGas, color: "yellow" };
    isLoading.value = false;
  } else {
    isLoading.value = false;
    proxy.$toaster.error("Ophalen van de homesGasless data is mislukt!");
  }
}
</script>

<template>
  <v-layout class="widget-item">
    <header>
      <h2>Overzicht woningen op gas</h2>
      <div class="widget-actions">
        <!--<router-link :to="{ name: 'HomesOverview' }" class="widget-link">Bekijk totale overzicht</router-link>-->
        <information-dropdown id="overzicht-panden">
          <template v-slot:header>
            <h2>Overzicht woningen op gas</h2>
          </template>
          <p>
            De Rijksoverheid wil de CO2-uitstoot verminderen door over te stappen op duurzame energie. Daarom kunt u vanaf 2050 in principe niet meer koken op gas of stoken op aardgas. Dan kan dit
            alleen nog met duurzame installaties.
          </p>
        </information-dropdown>
      </div>
    </header>
    <loader-spinner v-show="isLoading === true" :is-loading="isLoading"></loader-spinner>
    <div class="widget-wrapper" v-show="isLoading === false" :min-height="200">
      <div class="widget-chart">
        <chart-homes-gasless :height="200" :width="200" legendPosition="legend-bottom" :source-data="chart.homesGasless"></chart-homes-gasless>
      </div>
    </div>
  </v-layout>
</template>
