var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "overview-table",
        attrs: {
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: !_vm.token || _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.mutDate`,
              fn: function ({ item }) {
                return [
                  _vm._v(" " + _vm._s(_setup.formatDateUtcDT(item.mutDate))),
                ]
              },
            },
            {
              key: `item.field`,
              fn: function ({ item }) {
                return [
                  _vm._v(" " + _vm._s(_setup.msgApplied(item.field)) + " "),
                ]
              },
            },
            {
              key: `item.valOld`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        Number.isNaN(parseInt(item.valOld))
                          ? _setup.msgApplied(item.valOld)
                          : item.valOld
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.valNew`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        Number.isNaN(parseInt(item.valNew))
                          ? _setup.msgApplied(item.valNew)
                          : item.valNew
                      ) +
                      " "
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(_setup.GridPagination, {
        attrs: {
          pageNumber: _setup.pageNumber,
          pageSize: _setup.pageSize,
          pageCount: _setup.pageCount,
        },
        on: { changedPaging: _setup.changedPaging },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }