var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "widget-item" },
    [
      _c("header", [
        _c("h2", [_vm._v("Status van labels")]),
        _c(
          "div",
          { staticClass: "widget-actions" },
          [
            _c(
              "information-dropdown",
              {
                attrs: { id: "status-labels" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [_c("h2", [_vm._v("Status van labels")])]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("p", [
                  _vm._v(
                    " Hier worden de labels en leeftijd getoond van de energielabels van al uw " +
                      _vm._s(
                        _setup.props.partyType == "Homes"
                          ? "complexen"
                          : "gebouwen"
                      ) +
                      ". Een energielabel laat de energieprestatie van een " +
                      _vm._s(
                        _setup.props.partyType == "Homes" ? "complex" : "gebouw"
                      ) +
                      " zien. Ook maakt het energielabel duidelijk welke energiebesparende maatregelen mogelijk zijn. De labelklasse voor " +
                      _vm._s(
                        _setup.props.partyType == "Homes"
                          ? "complexen"
                          : "utiliteitsbouw"
                      ) +
                      " loopt van A++++ t/m G, van weinig naar veel besparingsmogelijkheden. "
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("loader-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _setup.isLoading === true,
            expression: "isLoading === true",
          },
        ],
        attrs: { "is-loading": _setup.isLoading, "min-height": 200 },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isLoading === false,
              expression: "isLoading === false",
            },
          ],
          staticClass: "widget-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "widget-chart" },
            [
              _c("chart-building-energy-labels", {
                attrs: {
                  width: 200,
                  height: 200,
                  legendPosition: "legend-bottom",
                  "source-data": _setup.labelData,
                  partyType: _setup.props.partyType,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "widget-info" },
            [
              _c("h3", [_vm._v("Leeftijd labels")]),
              _c("table-expiration-date-age", {
                attrs: { "table-data": _setup.tableData },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }