var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-data-table", {
    staticClass: "label-table",
    attrs: {
      dense: "",
      headers: _setup.headers,
      "hide-default-footer": "",
      items: _setup.expireData,
      "disable-sort": "",
    },
    scopedSlots: _vm._u(
      [
        {
          key: `item.value`,
          fn: function ({ item }) {
            return [
              item.value >= 1
                ? _c("span", [_vm._v(_vm._s(item.value))])
                : _c("span", [_vm._v("-")]),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }