import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import { createPinia, PiniaVuePlugin } from "pinia";
import VueRouter from "vue-router";

Vue.config.productionTip = false;
Vue.prototype.$showMenu = false;

/**
 * Vendor
 */
import VueGtag from "vue-gtag";

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "../node_modules/vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

import lodash from "lodash";
Vue.prototype._ = lodash;

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
Vue.component("vueDropzone", vue2Dropzone);

import Toaster from "v-toaster";
import "v-toaster/dist/v-toaster.css";

import VueNumber from "vue-number-animation";

import * as VeeValidate from "vee-validate";
import "@/mixins/vee-validate";

import VueMasonry from "vue-masonry-css";

import "@/styles/app.scss";

/**
 * Account
 *
 */
Vue.component("account-login", require("@/components/account/AccountLogin").default);
Vue.component("account-info", require("@/components/account/AccountInfo").default);

/**
 * General
 *
 */
//import { useAuthStore } from "@/stores/auth";
import httpClient from "@/services/api/httpClient";
import httpClientUnauthorized from "@/services/api/httpClientUnauthorized";

Vue.component("eiffel-tower", require("@/components/general/EiffelTower").default);
Vue.component("house-widget", require("@/components/general/HouseWidget").default);
Vue.component("information-dropdown", require("@/components/general/InformationDropdown").default);
Vue.component("warning-dropdown", require("@/components/general/WarningDropdown").default);
Vue.component("information-modal", require("@/components/general/InformationModal").default);
Vue.component("warning-modal", require("@/components/general/WarningModal").default);
Vue.component("paris-proof-score", require("@/components/general/ParisProofScore").default);
Vue.component("net-heat-requirement", require("@/components/general/NetHeatRequirement").default);

// Vue.component('year-slider', require('@/components/general/YearSlider').default);
Vue.component("loader-spinner", require("@/components/general/LoaderSpinner").default);

Vue.component("filter-set", require("@/components/general/filters/FilterSet").default);
Vue.component("filter-item", require("@/components/general/filters/FilterItem").default);

Vue.component("table-expiration-date-age", require("@/components/general/TableExpirationDateAge").default);
Vue.component("energy-label", require("@/components/general/EnergyLabel").default);

Vue.component("data-availability", require("@/components/general/DataAvailability").default);

Vue.component("grid-pagination", require("@/components/general/GridPagination").default);

Vue.component("inn-breadcrumbs", require("@/components/general/InnBreadcrumbs").default);

/**
 * Charts
 */
Vue.component("simple-doughnut-chart", require("@/components/app/charts/SimpleDoughnutChart").default);
Vue.component("advanced-doughnut-chart", require("@/components/app/charts/AdvancedDoughnutChart").default);
Vue.component("percentage-chart", require("@/components/app/charts/PercentageChart").default);
Vue.component("chart-measures", require("@/components/app/charts/ChartMeasures").default);
Vue.component("chart-co2-emissions", require("@/components/app/charts/ChartCO2Emissions").default);
Vue.component("advanced-horizontal-bar-chart", require("@/components/app/charts/AdvancedHorizontalBarChart").default);
Vue.component("chart-investment-vs-savings", require("@/components/app/charts/ChartInvestmentVsSavings").default);
Vue.component("advanced-line-chart", require("@/components/app/charts/AdvancedLineChart").default);
Vue.component("advanced-combined-bar-line-chart", require("@/components/app/charts/AdvancedCombinedBarLineChart").default);
Vue.component("chart-square-meters", require("@/components/app/charts/ChartSquareMeters").default);
Vue.component("chart-building-functions", require("@/components/app/charts/ChartBuildingFunctions").default);
Vue.component("chart-homes-gasless", require("@/components/app/charts/ChartHomesGasless").default);
Vue.component("chart-home-types", require("@/components/app/charts/ChartHomeTypes").default);
Vue.component("chart-building-energy-labels", require("@/components/app/charts/ChartBuildingEnergyLabels").default);
Vue.component("chart-applied-status", require("@/components/app/charts/ChartAppliedStatus").default);
Vue.component("chart-applied-types", require("@/components/app/charts/ChartAppliedTypes").default);
Vue.component("chart-applied-return-on-investment", require("@/components/app/charts/ChartAppliedReturnOnInvestment").default);
Vue.component("chart-measure-consumption", require("@/components/app/charts/ChartMeasureConsumption").default);
Vue.component("chart-roadmap-investment-vs-pps", require("@/components/app/charts/ChartRoadMapInvestmentVsPPS").default);
Vue.component("chart-roadmap-investment-vs-homes-meeting-netheat-requirement", require("@/components/app/charts/ChartRoadMapInvestmentVsHomesMeetingNetHeatRequirement").default);
Vue.component("chart-roadmap-co2emission-vs-saving-utility", require("@/components/app/charts/ChartRoadMapCO2EmissionVsSavingUtility").default);
Vue.component("chart-roadmap-co2emission-vs-saving-home", require("@/components/app/charts/ChartRoadMapInvestmentAndCO2Home").default);
Vue.component("simple-radar-chart", require("@/components/app/charts/SimpleRadarChart").default);
Vue.component("sustainability-radar", require("@/components/app/charts/SustainabilityRadar").default);
Vue.component("energy-compass", require("@/components/app/charts/EnergyCompass").default);

/**
 * Buildings
 */
Vue.component("building-paris-proof-score", require("@/components/app/buildings/BuildingParisProofScore").default);
Vue.component("building-energylabels-status", require("@/components/app/buildings/BuildingEnergyLabelStatus").default);
Vue.component("building-energylabels-age", require("@/components/app/buildings/BuildingEnergyLabelAge").default);
Vue.component("building-functions", require("@/components/app/buildings/BuildingFunctions").default);
Vue.component("building-measures", require("@/components/app/buildings/BuildingMeasures").default);
Vue.component("building-applied-overview", require("@/components/app/buildings/BuildingAppliedOverview").default);
Vue.component("building-overview-energy-labels", require("@/components/app/buildings/BuildingOverviewEnergyLabels").default);
Vue.component("building-overview-functions", require("@/components/app/buildings/BuildingOverviewFunctions").default);
Vue.component("building-overview-percentage-parisProof", require("@/components/app/buildings/BuildingOverviewPercentageParisProof").default);
Vue.component("building-overview-square-meters", require("@/components/app/buildings/BuildingOverviewSquareMeters").default);
Vue.component("building-yearly-usages", require("@/components/app/buildings/BuildingYearlyUsages").default);

/**
 * Homes / Complexes / EnergyLabels
 */
Vue.component("homes-gasless", require("@/components/app/homes/HomesGasless").default);
Vue.component("co2-emissions", require("@/components/app/homes/CO2Emissions").default);
Vue.component("complex-functions", require("@/components/app/homes/ComplexFunctions").default);
Vue.component("complex-overview-percentage-standard", require("@/components/app/homes/ComplexOverviewPercentageStandard").default);
Vue.component("complex-overview-gasless", require("@/components/app/homes/ComplexOverviewGasless").default);
Vue.component("complex-overview-co2-norm", require("@/components/app/homes/ComplexOverviewCO2Norm").default);
Vue.component("complex-net-heat-requirement", require("@/components/app/homes/ComplexNetHeatRequirement").default);

/**
 * Measures
 */
Vue.component("applied-overview", require("@/views/project/AppliedOverview.vue").default);
Vue.component("applied-overview-grid", require("@/components/app/measures/AppliedOverviewGrid").default);
Vue.component("measures-selection", require("@/components/app/measures/MeasureSelection.vue").default);
Vue.component("add-measure-modal", require("@/components/app/measures/AddMeasureModal").default);
Vue.component("applied-logging-modal", require("@/components/app/measures/AppliedLoggingModal").default);
Vue.component("applied-overview-types", require("@/components/app/measures/AppliedOverviewTypes").default);
Vue.component("applied-overview-return-on-investment", require("@/components/app/measures/AppliedOverviewReturnOnInvestment").default);
Vue.component("applied-overview-statusses", require("@/components/app/measures/AppliedOverviewStatusses").default);
Vue.component("roadmap-investment-vs-pps", require("@/components/app/measures/RoadMapInvestmentVsPPS").default);
Vue.component("roadmap-co2emission-vs-saving-utility", require("@/components/app/measures/RoadMapCO2EmissionVsSavingUtility").default);
Vue.component("roadmap-co2emission-vs-saving-home", require("@/components/app/measures/RoadMapInvestmentAndCO2Home").default);
Vue.component("roadmap-investment-vs-homes-meeting-netheat-requirement", require("@/components/app/measures/RoadMapInvestmentVsHomesMeetingNetHeatRequirement").default);

const hostUrl = window.location.origin + "/";
//normaal staat portal in de root maar voor dev is dat anders daarom wordt vanuit env bepaald
const portalUrl = process.env.VUE_APP_PORTAL_URL ? process.env.VUE_APP_PORTAL_URL : hostUrl;
console.log("%cConfig starting ", "background: green; color: yellow", portalUrl);

import { getConfiguration } from "@/stores/appconfig";

getConfiguration(hostUrl, portalUrl).then((appConfig) => {
  Vue.prototype.$appConfig = {
    title: appConfig.title,
    hostUrl: hostUrl,
    portalUrl: portalUrl,
    identityUrl: appConfig.identityUrl,
  };
  console.log("%cConfig loaded ", "background: green; color: yellow", JSON.stringify(Vue.prototype.$appConfig));

  Vue.use(
    VueGtag,
    {
      config: {
        id: "G-P4J3SF0ZDL", //zou indien nodig voor OTAP ook uit config kunnen komen
        params: {
          send_page_view: false,
        },
      },
    },
    router
  );
  Vue.use(PerfectScrollbar, { options: { wheelPropagation: false } });
  Vue.use(lodash);

  Vue.use(Toaster, { timeout: 5000 });
  Vue.use(VueNumber);
  Vue.use(VeeValidate);
  Vue.use(VueMasonry);

  Vue.use(PiniaVuePlugin);
  const pinia = createPinia();
  //Vue.use(pinia);

  Vue.use(VueRouter);

  // const authStore = useAuthStore(pinia);
  // authStore.init(appConfig);

  Vue.use(httpClient, { portalUrl: appConfig.portalUrl });
  Vue.use(httpClientUnauthorized, { portalUrl: appConfig.portalUrl });

  new Vue({
    router,
    pinia,
    vuetify,
    lodash,
    render: (h) => h(App),
  }).$mount("#app");
});
