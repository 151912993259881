<script>
import { Radar } from "vue-chartjs";

export default {
  extends: Radar,
  mounted () {
    this.renderChart(this.chartdata, this.options);
  },

  data() {
    return {
      chartdata: {
        labels: ['Strategie', 'Inzicht', 'Gedeelde waarden', 'Status', 'Slagkracht'],

        datasets: [
          {
            label: "score",
            backgroundColor: 'rgba(179,181,198,0.2)',
            borderColor: 'rgba(179,181,198,1)',
            pointBackgroundColor: 'rgba(179,181,198,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(179,181,198,1)',
            //data: [1.1, 2.2, 3.3, 4.4, 5.5]
            data: this.sourceData
          }
        ]
      },

      options: {
        scale: {
          ticks: {
            min: 0,
            max: 7
          },
        },
        legend: {
          display: false
        }
      },
    };
  },

  props: {
    sourceData: {
      type: Array,
      required: true,
      default: function() { return []; }
    }
  },

  watch: {
    sourceData: function(sourceData) {
      this.chartdata.datasets[0].data = sourceData;
      this.$data._chart.update();
    }
  }
}
</script>