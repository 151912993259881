var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "energy-label-wrap",
      attrs: { title: "Geschatte of ontbrekende labels zijn grijs" },
    },
    [
      _c("span", {
        staticClass: "icon-energy-label",
        class: _setup.colorClass,
      }),
      _vm._v(" " + _vm._s(_setup.formattedLabel) + " "),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }