<script lang="ts" setup>
import { ref, computed } from "vue";
import { useUserStore, PartyType } from "@/stores/user";
import { useAuthStore } from "@/stores/auth";
import { useAppConfigurationStore } from "@/stores/appconfig";
import router from "@/router/index";

const drawerLeft = ref(false);
const drawerRight = ref(false);

const userStore = useUserStore();
const authStore = useAuthStore();
const appconfigStore = useAppConfigurationStore();

const showMenuItemBeheer = computed(() => userStore.isAdministrator || userStore.hasRoleManager);
const showMenuItemInformatieplicht = computed(() => userStore.isPartyUtility && userStore.hasRoleManager);
const logoName = computed(() => appconfigStore.data.portalUrl + "asset/logo/" + userStore.currentPartyReference);
const projectList = computed(() => {
  const items = userStore.getAllProjecs
    .map((el) => ({
      reference: el.partyReference,
      name: userStore.currentPartyReference === el.partyReference ? `${el.description} (huidig)` : el.description,
      logoName: el.logoName,
      isManager: el.isManager,
      partyType: el.partyTypeKey,
    }))
    .sort(compareName);
  console.log("AppBase projectList ", userStore.getAllProjecs, items);
  return items;
});

const breadcrumbs = computed(() => {
  let list = userStore.breadcrumbList;
  return list;
});

function logout() {
  console.log("AppBase logout ");
  if (authStore.logoutDemo()) {
    router.push({ name: "Account" });
  } else {
    authStore.logout();
  }
}

function compareName(a, b) {
  const itemA = a.name.toUpperCase();
  const itemB = b.name.toUpperCase();

  let comparison = 0;
  if (itemA > itemB) {
    comparison = 1;
  } else if (itemA < itemB) {
    comparison = -1;
  }
  return comparison;
}

async function switchProject(partyReference) {
  console.log("%cAppBase switchProject", "background: lightgreen; color: black");
  let switchTo = router.currentRoute.name;
  if (router.currentRoute.meta.afterSwitchProjectGoTo) {
    switchTo = router.currentRoute.meta.afterSwitchProjectGoTo;
  }
  //console.log("beforeEach next 1 => ", switchTo);
  if (!switchTo || switchTo == "") {
    switchTo = "Dashboard";
  }

  // const newParty = userStore.party(partyReference);
  // if (router.currentRoute.meta.allowedFor) {
  //   const allow = router.currentRoute.meta.allowedFor.some((x) => x == newParty.partyTypeKey);
  //   if (!allow) {
  //     switchTo = "Dashboard";
  //   }
  // }
  //altijd switchen naar dashboard
  switchTo = "Dashboard";

  // eerst partytype weghalen zodat met de volgende router.push niet alvast pagina geladen zal worden voordat party switch daarna gebeurt
  userStore.resetPartyType();

  console.log("beforeEach next 3 => ", switchTo);
  if (switchTo != router.currentRoute.name) {
    router.push({ name: switchTo });
  }

  await userStore.setParty(partyReference);
}
</script>

<template>
  <v-layout>
    <v-app-bar app color="white" class="navbar">
      <v-app-bar-nav-icon @click="drawerLeft = true" class="d-flex d-md-none"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <router-link :to="{ name: 'Dashboard' }">
          <v-img alt="INNAX Logo" class="shrink mr-2 brand" contain src="@/assets/img/logo.png" />
        </router-link>
      </div>
      <v-layout class="d-none d-md-flex">
        <v-btn text :to="{ name: 'Dashboard' }">
          <!-- <v-icon v-if="userStore.isPartyEnergyLabels">mdi-label-outline</v-icon>
          <v-icon v-else-if="userStore.isPartyHome">mdi-home-outline</v-icon>
          <v-icon v-else-if="userStore.isPartyUtility">mdi-office-building-outline</v-icon> -->
          Dashboard</v-btn
        >
        <v-btn v-if="userStore.isPartyUtility" text :to="{ name: 'BuildingOverview' }">Gebouwen</v-btn>
        <v-btn v-else-if="userStore.isPartyHome" text :to="{ name: 'ComplexOverview' }">Complexen</v-btn>
        <v-btn v-if="userStore.isPartyUtility || userStore.isPartyHome" text :to="{ name: 'AppliedOverview' }">Maatregelen</v-btn>
        <v-btn v-if="userStore.isPartyUtility" text :to="{ name: 'RoadMapUtility' }">Routekaart</v-btn>
        <v-btn v-if="userStore.isPartyHome" text :to="{ name: 'RoadMapHome' }">Routekaart</v-btn>
        <v-btn v-if="userStore.isPartyUtility || userStore.isPartyHome" text :to="{ name: 'Rapportage' }">Rapportage</v-btn>

        <v-menu v-if="showMenuItemBeheer" offset-y>
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn text v-bind="attrs" v-on="on">
              Beheer
              <v-icon v-if="value" right>mdi-chevron-up</v-icon>
              <v-icon v-else right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="navbar">
            <v-list-item v-if="showMenuItemInformatieplicht" text :to="{ name: 'ProjectCreateBulkUploadXML' }">
              <v-list-item-title>Informatieplicht</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :to="{ name: 'Info' }">
              <v-list-item-icon><v-icon>mdi-information-outline</v-icon> </v-list-item-icon>
              <v-list-item-title>Info</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-layout>

      <v-spacer></v-spacer>
      <v-layout justify-end flex-grow-0 class="d-none d-lg-flex">
        <v-menu v-if="projectList.length > 1" offset-y origin="top right">
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn text v-bind="attrs" v-on="on">
              <div>
                {{ userStore.currentProjectName }}<v-icon v-if="userStore.hasRoleManager" right>mdi-account-star</v-icon>
                <v-icon v-if="value" right>mdi-chevron-up</v-icon>
                <v-icon v-else right>mdi-chevron-down</v-icon>
              </div>
            </v-btn>
          </template>
          <v-list class="navbar projects">
            <v-list-item v-for="project in projectList" v-bind:value="project.reference" :key="project.reference" link @click="switchProject(project.reference)">
              <v-list-item-title>
                <v-icon v-if="project.partyType == PartyType.EnergyLabels">mdi-label-outline</v-icon>
                <v-icon v-else-if="project.partyType == PartyType.Homes">mdi-home-outline</v-icon>
                <v-icon v-else-if="project.partyType == PartyType.Utility || project.partyType == PartyType.None">mdi-office-building-outline</v-icon>

                {{ project.name }}
              </v-list-item-title>
              <v-list-item-icon v-if="project.isManager"><v-icon>mdi-account-star</v-icon></v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-else class="v-btn">
          {{ userStore.currentProjectName }}
        </div>
      </v-layout>
      <v-layout justify-end flex-grow-0 class="logo-project">
        <img :src="logoName" />
      </v-layout>
      <v-layout justify-end flex-grow-0 class="d-none d-lg-flex">
        <v-menu offset-y origin="top right">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              {{ userStore.getUserFullname }}
            </v-btn>
          </template>
          <v-list class="navbar">
            <v-list-item link @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Afmelden</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-layout>
      <v-app-bar-nav-icon @click="drawerRight = true" class="d-flex d-lg-none"><v-icon>mdi-account-outline</v-icon></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawerLeft" absolute temporary>
      <v-list nav>
        <v-list-item text :to="{ name: 'Dashboard' }">
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-if="userStore.isPartyUtility || userStore.isPartyHome" text :to="{ name: 'BuildingOverview' }">
          <v-list-item-title v-if="userStore.isPartyUtility">Gebouwen</v-list-item-title>
          <v-list-item-title v-else-if="userStore.isPartyHome">Complexen</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="userStore.isPartyUtility || userStore.isPartyHome" text :to="{ name: 'AppliedOverview' }">
          <v-list-item-title>Maatregelen</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="userStore.isPartyUtility" text :to="{ name: 'RoadMapUtility' }">
          <v-list-item-title>Routekaart</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="userStore.isPartyHome" text :to="{ name: 'RoadMapHome' }">
          <v-list-item-title>Routekaart</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="userStore.isPartyUtility || userStore.isPartyHome" text :to="{ name: 'Rapportage' }">
          <v-list-item-title>Rapportage</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-group v-if="showMenuItemBeheer" :value="false">
          <template v-slot:activator>
            <v-list-item-title>Beheer</v-list-item-title>
          </template>
          <v-list-item v-if="showMenuItemInformatieplicht" text :to="{ name: 'ProjectCreateBulkUploadXML' }">
            <v-list-item-title>Informatieplicht</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item :to="{ name: 'Info' }">
            <v-list-item-icon><v-icon>mdi-information-outline</v-icon> </v-list-item-icon>
            <v-list-item-title>Info</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer v-model="drawerRight" absolute temporary right>
      <v-list nav>
        <v-list-item-title class="ma-2"> {{ userStore.getUserFullname }} </v-list-item-title>
        <v-divider></v-divider>
        <v-list-group v-if="projectList.length > 1" :value="false">
          <template v-slot:activator>
            <v-list-item-title>Projecten</v-list-item-title>
          </template>
          <v-list-item v-for="project in projectList" v-bind:value="project.reference" :key="project.reference" link @click="switchProject(project.reference)">
            <v-list-item-title>
              {{ project.name }}
            </v-list-item-title>
            <v-list-item-icon v-if="project.isManager"><v-icon>mdi-account-star</v-icon></v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <div v-else class="v-btn">
          {{ userStore.currentProjectName }}
        </div>
        <v-divider></v-divider>
        <v-list-item link @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Afmelden</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <inn-breadcrumbs :items="breadcrumbs"></inn-breadcrumbs>
      <router-view></router-view>
    </v-main>
    <v-footer app fixed>
      <v-container fluid pa-0>
        <v-row>
          <v-col align="center" class="pa-0"> &copy; {{ new Date().getFullYear() }} - Paris Proof Monitor </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-layout>
</template>
