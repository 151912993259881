var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "widget-item" },
    [
      _c("header", [
        _c("h2", [_vm._v("CO₂")]),
        _c(
          "div",
          { staticClass: "widget-actions" },
          [
            _c(
              "information-dropdown",
              {
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [_c("h2", [_vm._v("CO₂")])]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("p", [
                  _vm._v(
                    " De huidige status van het CO₂ verbruik wordt berekend aan de hand van de energieverbruiken van de woningen. De norm voor 2050 is gebaseerd op het klimaatakkoord waarbij is afgesproken dat uitstoot van broeikasgassen met 95% afgenomen moet zijn ten opzichte van 1990. Het energieverbruik in 1990 is teruggerekend in een onderzoek door INNAX. "
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _setup.isLoading === true
        ? _c("loader-spinner", {
            attrs: { "is-loading": _setup.isLoading, "min-height": 200 },
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isLoading === false && _setup.chart.any == true,
              expression: "isLoading === false && chart.any == true",
            },
          ],
        },
        [
          _c("div", { staticClass: "widget-wrapper" }, [
            _c(
              "div",
              { staticClass: "widget-chart measures" },
              [
                _c("chart-co2-emissions", {
                  attrs: { "source-data": _setup.chart, height: 150 },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isLoading === false && _setup.chart.any == false,
              expression: "isLoading === false && chart.any == false",
            },
          ],
        },
        [
          _c("p", [
            _vm._v("Er zijn geen CO₂ waarden beschikbaar voor dit project"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }