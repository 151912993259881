var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "information", attrs: { "flex-column": "" } },
    [
      _c("article", [
        _c("header", [
          _c("h1", { staticClass: "small" }, [_vm._v("Paris Proof Monitor")]),
        ]),
        _c("p", [
          _vm._v("De Paris Proof Monitor"),
          _c("sup", [_vm._v("©")]),
          _vm._v(
            " is een intelligent dataplatform dat u overzicht en inzicht geeft in uw vastgoed en de meest effectieve verduurzamingsroute."
          ),
        ]),
        _c("p", [
          _vm._v(
            " Onze data-driven software is gebaseerd op een unieke combinatie van domeinkennis en moderne data science technieken. Werkelijke energieverbruiken worden gecombineerd met gebouwgegevens, de erkende maatregelen en maatregelen die voortkomen uit een routekaart. "
          ),
        ]),
        _c("p", [
          _vm._v(
            "De benodigde WEii-indicatoren worden verzameld om zo te komen tot de Paris Proof Score van een gebouw en of gehele portefeuille, met een doorkijk naar 2030 en 2050."
          ),
        ]),
        _c(
          "p",
          [
            _c(
              "v-btn",
              {
                staticClass: "primary",
                attrs: { href: "https://www.innax.nl/paris-proof-monitor" },
              },
              [
                _c("v-icon", { attrs: { dark: "", left: "" } }, [
                  _vm._v("mdi-arrow-right"),
                ]),
                _vm._v(" Demo"),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "article",
        [
          _c("header", [
            _c("h1", { staticClass: "small" }, [
              _vm._v("Paris Proof Partnership"),
            ]),
          ]),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", [
                _c("p", [
                  _vm._v(
                    " De Monitor is onderdeel van het Paris Proof Partnership. Daarin leggen we de route naar Paris Proof samen met onze klanten af, in heldere stappen en met focus op de ambitie en doelstellingen. "
                  ),
                ]),
                _c(
                  "p",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary",
                        attrs: {
                          href: "https://www.innax.nl/oplossingen/toekomstvaste-gebouwen/paris-proof-partnership",
                          target: "_blank",
                        },
                      },
                      [
                        _c("v-icon", { attrs: { dark: "", left: "" } }, [
                          _vm._v("mdi-arrow-right"),
                        ]),
                        _vm._v(" Lees verder"),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-img", {
                    staticClass: "logo-ppp",
                    attrs: {
                      src: require("@/assets/img/logo-ppp.png"),
                      alt: "Paris Proof Partnership",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        [
          _c("p", [
            _vm._v(
              "De Paris Proof Monitor is ontwikkeld met de Topsector Energie subsidie van het Ministerie van Economische zaken. In samenwerking met:"
            ),
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "coop-image tno" },
                [
                  _c("v-img", {
                    attrs: {
                      contain: "",
                      src: require("@/assets/img/logo-tno.png"),
                      alt: "TNO innovation for life",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "coop-image vabi" },
                [
                  _c("v-img", {
                    attrs: {
                      contain: "",
                      src: require("@/assets/img/logo-vabi.png"),
                      alt: "VABI",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "coop-image en" },
                [
                  _c("v-img", {
                    attrs: {
                      contain: "",
                      src: require("@/assets/img/logo-energy-navigator.png"),
                      alt: "ENOLIS",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }