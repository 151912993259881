<script lang="ts" setup>
import { ref, computed } from "vue";
import { useUserStore } from "@/stores/user";
import { useFiltertokenStore } from "@/stores/filtertoken";

const userStore = useUserStore();
const filtertokenStore = useFiltertokenStore();

const refDate = ref(new Date()); // Kan later gewijzigd worden mbv een tijdlijn

const welcomeName = computed(() => {
  const name = userStore.friendlyName;
  return name.length > 0 ? ` ${name}` : "";
});

const token = computed(() => {
  if (!filtertokenStore.projectToken) {
    loadProjectToken();
  }
  return filtertokenStore.projectToken;
});

async function loadProjectToken() {
  await filtertokenStore.getProjectToken();
}
</script>

<template>
  <v-container fluid pa-0 fill-height>
    <v-row>
      <v-col lg="3" md="4" sm="12" class="app-paris-proof-sidebar">
        <paris-proof-score :token="token" :refDate="refDate"></paris-proof-score>
      </v-col>
      <v-col lg="9" md="8" sm="12" class="app-dashboard-overview">
        <v-container fluid px-0 py-0>
          <v-row>
            <v-col class="app-welcome-message">
              <h1>Welkom{{ welcomeName }},</h1>
              <p>Hieronder wordt uw persoonlijke dashboard weergegeven</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <masonry class="app-dashboard-widget-overview" :cols="{ default: 2, 1264: 1 }" :gutter="24">
                <v-layout class="inner">
                  <data-availability v-if="token" :token="token"></data-availability>
                </v-layout>

                <v-layout class="inner">
                  <building-functions v-if="token" :token="token"></building-functions>
                </v-layout>

                <v-layout class="inner">
                  <energy-compass v-if="token" :token="token" :refDate="refDate"></energy-compass>
                </v-layout>

                <v-layout class="inner">
                  <building-energylabels-status v-if="token" :token="token" :partyType="userStore.currentPartyType" labelSource="EPOnline"></building-energylabels-status>
                </v-layout>

                <v-layout class="inner">
                  <sustainability-radar v-if="userStore.currentPartyReference" :partyReference="userStore.currentPartyReference"></sustainability-radar>
                </v-layout>

                <v-layout class="inner">
                  <building-measures v-if="token" :token="token" :certified="true" :refDate="refDate">
                    <template v-slot:headerwidget>
                      <h2>Erkende Maatregelen</h2>
                    </template>
                    <template v-slot:infotext>
                      <p>Indien een gebouw informatieplichtig is, moet bekend zijn per erkende maatregel wat de status hiervan is.</p>
                      <p>In dit overzicht wordt getoond op basis van al uw gebouwen wat de voortgang is.</p>
                    </template>
                    <template v-slot:nomeasurestext>
                      <p>Er zijn nog geen erkende maatregelen aan dit project toegevoegd</p>
                    </template>
                  </building-measures>
                </v-layout>

                <v-layout class="inner">
                  <building-measures v-if="token" :token="token" :certified="false" :refDate="refDate">
                    <template v-slot:headerwidget>
                      <h2>Aanvullende maatregelen</h2>
                    </template>
                    <template v-slot:infotext>
                      <template>
                        <p>Naast erkende maatregelen kunt u ook aanvullende maatregelen inplannen en uitvoeren.</p>
                        <p>In dit overzicht wordt getoond op basis van al uw gebouwen wat de voortgang is.</p>
                      </template>
                    </template>
                    <template v-slot:nomeasurestext>
                      <p>Er zijn nog geen aanvullende maatregelen aan dit project toegevoegd</p>
                    </template>
                  </building-measures>
                </v-layout>
              </masonry>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
