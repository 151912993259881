<script lang="ts" setup>
import { ref, onMounted, watch, getCurrentInstance } from "vue";
import { getAppliedFilterOptions } from "@/services/api/measure.api";
import { getAppliedFilterToken } from "@/services/api/token.api";
import { getAppliedStatusses, getKinds } from "@/services/api/measure.api";
import { hasCertifiedMeasures, createCsv } from "@/services/api/project.api";
import { FilterTokenName, FilterStatus, mapFilterResponseStatus } from "@/stores/filtertoken";
import handleFilterTokens27 from "@/composables/handleFilterTokens27";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();
const { token, tokenDelayed, searchToken, mapFilterResult, createFilterToken, resetFilterToken } = handleFilterTokens27(FilterTokenName.Applied);

const filters = ref([
  {
    name: "Gebouw",
    fieldName: "address",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Code",
    fieldName: "code",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Maatregel",
    fieldName: "definitionName",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Investering",
    fieldName: "investment",
    type: "range",
    options: [],
  },
  {
    name: "Terugverdientijd",
    type: "range",
    fieldName: "recoupedYears",
    options: [],
  },
  {
    name: "Status",
    fieldName: "status",
    type: "list",
    options: [],
  },
  {
    name: "Zelfstandig moment",
    fieldName: "isApplicableAtIndependentMoment",
    type: "list",
    options: [
      { id: "true", text: "Ja" },
      { id: "false", text: "Nee" },
    ],
  },
  {
    name: "Soort",
    fieldName: "kind",
    type: "list",
    options: [],
  },
]);

const projectHasCertifiedMeasures = ref(false);
const appliedCount = ref(0);
const filteredEml2023 = ref(false);

const chartData = ref({
  years: [],
  savings: [],
  investments: [],
});

onMounted(() => {
  createFilters();
});

watch(
  () => userStore.currentPartyType,
  () => {
    createFilters();
  },
  {
    deep: true,
  }
);
watch(
  () => userStore.currentPartyReference,
  (newValue) => {
    console.log("AppliedOverview, watch, currentPartyReference: ", newValue);
    if (userStore.hasRoleManager) {
      checkProjectHasCertifiedMeasures(newValue);
    } else {
      projectHasCertifiedMeasures.value = false;
    }
  },
  {
    immediate: true,
  }
);

async function handleFilterSearch(index, text) {
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    case "status":
      {
        const response = await getAppliedStatusses(searchToken.value);
        result = response.data;
      }
      break;

    case "kind":
      {
        const response = await getKinds(searchToken.value);
        result = response.data;
      }
      break;

    default:
      if (text && text.length > 0) {
        const response = await getAppliedFilterOptions(searchToken.value, filters.value[index].fieldName, text);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
}

function createFilters() {
  // Na het selecteren van een ander project wordt "filters" niet opnieuw geinitialiseerd.
  // Vandaar Soort uit "filters" verwijderen als deze al bestaat om deze daarna conditioneel toe te voegen.
  const kindFilter = filters.value.find((x) => x.name == "Soort");
  if (userStore.isPartyUtility) {
    if (!kindFilter) {
      filters.value.push({
        name: "Soort",
        type: "list",
        fieldName: "kind",
        options: [],
      });
    }
  } else {
    if (kindFilter) {
      removeFilterOption(kindFilter);
    }
  }
}

function removeFilterOption(item) {
  filters.value = filters.value.filter((x) => x.name != item.name);
}

async function handleFiltersChanged(filterData) {
  if (filterData.length > 0) {
    //bepaal of er gefilterd is eml 2023
    const kindFilter = filterData.find((x) => x.fieldName == "kind");
    filteredEml2023.value = kindFilter && kindFilter.exact.length == 1 && kindFilter.exact[0] === "EML2023";

    console.log("AppliedOverview, handleFiltersChanged getFilterToken: ", searchToken.value, filterData);
    const getFilterToken = getAppliedFilterToken(searchToken.value, filterData);
    const success = await createFilterToken(getFilterToken);
    if (!success) {
      proxy.$toaster.error("Ophalen van de filter token is mislukt!");
    }
  } else {
    resetFilterToken();
  }
}

async function checkProjectHasCertifiedMeasures(partyReference) {
  console.log("AppliedOverview, checkProjectHasCertifiedMeasures: ", partyReference);
  if (partyReference) {
    const result = await hasCertifiedMeasures(partyReference);
    projectHasCertifiedMeasures.value = result.data;
  }
}

function appliedLoaded(payload) {
  //console.log("AppliedOverview, appliedLoaded : ", payload);
  appliedCount.value = payload.totalCount;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function doCreateCsv() {
  const response = await createCsv(tokenDelayed.value, userStore.currentPartyReference.toString());

  await saveToFile(response.data.csvString, userStore.currentProjectName + "_" + response.data.fileName);

  if (response) {
    proxy.$toaster.success("Csv bestand is successvol gecreëerd voor " + response.data.totalNumberOfMeasures + " maatregelen. (zie Downloads)", { timeout: 10000 });
  }
}

async function saveToFile(data, filename) {
  let blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), data], { type: "text/csv; charset=utf-8" });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>

<template>
  <v-container fluid class="app-measures-overview">
    <v-row>
      <v-col class="app-welcome-message">
        <h1>Maatregelen</h1>
        <p>In dit overzicht worden alle maatregelen voor uw portefeuille getoond. Aan de hand van het filter kunt u uw gewenste inzicht creëren.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" sm="3">
        <applied-overview-types :token="tokenDelayed"></applied-overview-types>
      </v-col>
      <v-col md="4" sm="3">
        <applied-overview-return-on-investment :token="tokenDelayed"></applied-overview-return-on-investment>
      </v-col>
      <v-col md="4" sm="3">
        <applied-overview-statusses :token="tokenDelayed"></applied-overview-statusses>
      </v-col>
    </v-row>
    <v-row class="d-none">
      <v-col>
        <chart-investment-vs-savings :years="chartData.years" :savings="chartData.savings" :investments="chartData.investments" class="app-measures-chart"></chart-investment-vs-savings>
      </v-col>
    </v-row>

    <v-row class="overview-wrapper">
      <v-col>
        <article class="inner">
          <v-row class="overview-filters align-items-start">
            <v-col cols="auto">
              <filter-set :name="FilterTokenName.Applied" :filters="filters" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="text-right">
              <v-btn dark class="primary" @click="doCreateCsv()">
                <v-icon dark left>mdi-file-excel</v-icon>
                Exporteer
              </v-btn>
            </v-col>
            <v-col cols="auto" class="text-right">
              <v-btn v-if="projectHasCertifiedMeasures" dark class="primary" :to="{ name: 'ProjectCreateBulkUploadXML' }">
                <v-icon dark left>mdi-file-upload</v-icon>
                Informatieplicht
              </v-btn>
            </v-col>
            <v-col cols="auto" class="text-right">
              <v-tooltip bottom :disabled="appliedCount >= 2 && filteredEml2023">
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="d-inline-block">
                    <!-- Geef verborgen parameter door zoadat alleen via deze button de pagina opgeroepen kan worden -->
                    <v-btn :disabled="appliedCount < 2 || !filteredEml2023" class="primary" :to="{ name: 'AppliedMutation', params: { allow: true } }"> Bulk wijzigen </v-btn>
                  </div>
                </template>
                <span v-if="!filteredEml2023">Soort moet gefilterd zijn op 'EML 2023'</span>
                <span v-else-if="appliedCount < 2">Onvoldoende maatregelen om in bulk te wijzigen</span>
              </v-tooltip>
            </v-col>

            <v-col cols="auto">
              <applied-logging-modal :token="token"></applied-logging-modal>
            </v-col>
          </v-row>

          <applied-overview-grid v-if="token" :token="token" :hide-kind="userStore.isPartyHome" @fetch-data-complete="appliedLoaded"></applied-overview-grid>
        </article>
      </v-col>
    </v-row>
  </v-container>
</template>
