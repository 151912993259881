<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";
import { getHomesGaslessDashboard } from "@/services/api/chart.api";

const props = defineProps({
  token: {
    type: String,
  },
});

const homesGasless = ref({});

const isLoading = ref(true);

watch(
  () => props.token,
  () => {
    if (props.token) {
      fetchHomesGaslessData();
    } else {
      homesGasless.value = {};
    }
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchHomesGaslessData() {
  isLoading.value = true;
  console.log("ComplexGasless calling  getHomesGaslessDashboard(" + props.token + ")");
  const response = await getHomesGaslessDashboard(props.token);
  console.log("ComplexGasless  getHomesGaslessDashboard response", response);
  if (response) {
    homesGasless.value = {};
    homesGasless.value["Gasloze woningen"] = { val: response.data.homesNotUsingGas, color: "#007500" };
    homesGasless.value["Woningen op gas"] = { val: response.data.homesUsingGas, color: "yellow" };
    isLoading.value = false;
  } else {
    isLoading.value = false;
    proxy.$toaster.error("Ophalen van de homesGasless data is mislukt!");
  }
}
</script>

<template>
  <v-layout class="widget-item">
    <h3>Hoeveel procent is gasloos</h3>
    <div class="widget-wrapper widget-chart">
      <loader-spinner v-show="isLoading === true" :is-loading="isLoading" :min-height="170"></loader-spinner>
      <chart-homes-gasless v-show="isLoading === false" :height="170" :sourceData="homesGasless"> </chart-homes-gasless>
    </div>
  </v-layout>
</template>
