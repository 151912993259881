<script lang="ts" setup>
import { ref, watch } from "vue";
import { GetSustainabilityRadarData } from "@/services/api/chart.api";

const props = defineProps({
  partyReference: undefined,
});

const numbers = ref(undefined);

watch(
  () => props.partyReference,
  () => {
    getRadarData();
  },
  { immediate: true }
);

async function getRadarData() {
  try {
    let resp = await GetSustainabilityRadarData(props.partyReference);
    if (resp.data == null || resp.data == "") numbers.value = undefined;
    else numbers.value = [resp.data.radarStrategy, resp.data.radarInsight, resp.data.radarSharedValues, resp.data.radarStatus, resp.data.radarStrikingPower];
  } catch (e) {
    console.log(e);
    numbers.value = undefined;
  }
}
</script>

<template>
  <v-layout class="widget-item">
    <header>
      <h2>Verduurzamingsradar</h2>
      <div class="widget-actions">
        <information-dropdown>
          <template v-slot:header>
            <h2>Verduurzamingsradar</h2>
          </template>
          <p>
            De Verduurzamingsradar is een bedrijfskundig model. Het doel hiervan is om u én onszelf bewust te maken van de status van de energietransitie binnen uw organisatie, gerelateerd aan de
            Nederlandse klimaatdoelen. Op basis van dit bewustzijn kan worden bepaald of professionalisering is gewenst en hoe dit bewerkstelligd zou kunnen worden.
          </p>
        </information-dropdown>
      </div>
    </header>
    <v-container v-if="numbers != undefined" class="vue-chart vue-sustainability-radar inline-text" pa-0>
      <v-row>
        <v-col class="vue-chart-wrapper" cols="auto">
          <simple-radar-chart :sourceData="numbers"></simple-radar-chart>
          <!--<simple-radar-chart :source-data="numbers" :percentage="percentage" :color="color" :styles="chartStyles"></simple-radar-chart>-->
        </v-col>
        <!--<v-col class="vue-chart-legend"><span v-html="htmlLegend"></span></v-col>-->
      </v-row>
    </v-container>
    <v-container v-else class="vue-chart vue-sustainability-radar inline-text" pa-0>
      <p>Geen gegevens beschikbaar voor deze partij</p>
    </v-container>
  </v-layout>
</template>
