<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getBuildingEcmFunctions, severityCode } from "@/services/api/chart.api";
import { getPartyEnergyEfficiencyObligatory } from "@/services/api/project.api";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

const props = defineProps({
  token: {
    type: String,
  },
});

const buildingFunctions = ref({});
const percentageOfInformationObligation = ref(0);
const isLoadingFunction = ref(true);
const isLoadingObligation = ref(true);

const dataLoaded = computed(() => !isLoadingFunction.value && !isLoadingObligation.value);

watch(
  () => props.token,
  () => {
    if (props.token) {
      loadFunctions();
      loadObligation();
    } else {
      buildingFunctions.value = {};
    }
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function loadFunctions() {
  isLoadingFunction.value = true;
  const response = await getBuildingEcmFunctions(props.token);
  console.log("Buildingfunctions loadFunctions ", response);
  if (response.severity <= severityCode.warning) {
    buildingFunctions.value = response.data;
  } else {
    proxy.$toaster.error("Ophalen van buildingFunctions is mislukt! ", response.message);
  }
  isLoadingFunction.value = false;
}

async function loadObligation() {
  isLoadingObligation.value = true;
  const response = await getPartyEnergyEfficiencyObligatory(userStore.currentPartyReference);
  console.log("Buildingfunctions loadObligation ", response);
  if (response.severity <= severityCode.warning) {
    percentageOfInformationObligation.value = Math.trunc((100 * response.data.mandatory) / response.data.total);
  } else {
    proxy.$toaster.error("Ophalen van informatieplicht is mislukt! ", response.message);
  }
  isLoadingObligation.value = false;
}
</script>

<template>
  <v-layout class="widget-item">
    <header>
      <h2>Overzicht panden</h2>
      <div class="widget-actions">
        <router-link :to="{ name: 'BuildingOverview' }" class="widget-link">Bekijk totale overzicht</router-link>
        <information-dropdown id="overzicht-panden">
          <template v-slot:header>
            <h2>Gebouwenoverzicht</h2>
          </template>
          <p>
            Uw portefeuille kan bestaan uit verschillende type gebouwen. We tonen hier de verdeling van uw gebouwen op basis van de functie die het heeft. De functies die we gebruiken zijn gebaseerd
            op de bedrijfstakken die RVO gebruikt voor de informatieplicht.
            <br /><br />
          </p>
          <h4>Informatieplicht</h4>
          <p>
            Hoeveel van uw gebouwen zijn informatieplichtig? Het percentage van uw informatieplichtige gebouwen wordt hier getoond. Niet al uw gebouwen zitten mogelijk boven de norm van de
            informatieplicht van 50.000 kWh elektriciteit of 25.000 m³ aardgas(equivalent).
          </p>
        </information-dropdown>
      </div>
    </header>
    <loader-spinner v-if="!dataLoaded" :is-loading="true"></loader-spinner>
    <div class="widget-wrapper" v-else :min-height="200">
      <div class="widget-chart">
        <chart-building-functions :height="200" :width="200" legendPosition="legend-bottom" :sourceData="buildingFunctions"></chart-building-functions>
      </div>
      <div class="widget-info">
        <h3>
          Informatieplichtig
          <span> <number :to="percentageOfInformationObligation" :duration="1.6"></number>% </span>
        </h3>
        <p>Verbruikt uw gebouw per jaar meer dan 50.000 kWh elektriciteit of 25.000 m³ aardgas(equivalent)?</p>
      </div>
    </div>
  </v-layout>
</template>
