<script lang="ts" setup>
import { ref, getCurrentInstance } from "vue";
import BuildingAppliedGrid from "@/components/app/buildings/BuildingAppliedGrid.vue";
import ApplyCertifiedMeasures from "@/components/app/measures/ApplyCertifiedMeasures.vue";
import { getBuildingAppliedFilterOptions } from "@/services/api/measure.api";
import { getBuildingAppliedStatusses, getBuildingAppliedKinds } from "@/services/api/measure.api";
import { FilterTokenName, FilterStatus, mapFilterResponseStatus } from "@/stores/filtertoken";
import { useUserStore } from "@/stores/user";
import handleFilterTokens27 from "@/composables/handleFilterTokens27";

const emits = defineEmits(["rerender-building-applied-overview"]);
const userStore = useUserStore();
const { mapFilterResult } = handleFilterTokens27(FilterTokenName.Applied);

const props = defineProps({
  addressId: { type: String, default: null },
  ecmFunctionCode: { type: String, default: null },
  ecmFunctionName: { type: String, default: null },
});

const filters = ref([
  {
    name: "Maatregel",
    fieldName: "definitionName",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Code",
    fieldName: "code",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Investering",
    fieldName: "investment",
    type: "range",
    options: [],
  },
  {
    name: "Terugverdientijd",
    type: "range",
    fieldName: "recoupedYears",
    options: [],
  },
  {
    name: "Status",
    type: "list",
    fieldName: "status",
    options: [],
  },
  {
    name: "Soort",
    type: "list",
    fieldName: "kind",
    options: [],
    show: userStore.isPartyUtility,
  },
]);

const filterData = ref(undefined);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function handleFilterSearch(index, text) {
  //console.log("BuildingAppliedOverview handleFilterSearch: ", filterIndex, text);
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    case "status":
      {
        const response = await getBuildingAppliedStatusses(props.addressId);
        result = response.data;
      }
      break;
    case "kind":
      {
        const response = await getBuildingAppliedKinds(props.addressId);
        result = response.data;
      }
      break;
    default:
      if (text && text.length > 0) {
        const response = await getBuildingAppliedFilterOptions(props.addressId, filters.value[index].fieldName, text);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  console.log("BuildingAppliedOverview handleFilterSearch response: ", result);
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
  //console.log("BuildingAppliedOverview getFilterOptions options: ", options);
}

async function handleFiltersChanged(newFilterData) {
  console.log("BuildingAppliedOverview handleFiltersChanged: ", newFilterData);
  filterData.value = [...newFilterData];
}

function handleRerenderMeasuresGrid(payload) {
  console.log("BuildingAppliedOverview, handleRerenderMeasuresGrid");
  emits("rerender-building-applied-overview", payload);
}
</script>

<template>
  <v-container fluid class="inner">
    <v-row>
      <v-col>
        <header>
          <h2>Maatregelen</h2>
        </header>
      </v-col>
      <v-col cols="auto">
        <span class="pa-2" v-if="userStore.hasRoleUser && userStore.isPartyUtility">
          <apply-certified-measures
            :addressId="props.addressId"
            :ecmFunctionCode="props.ecmFunctionCode"
            :ecmFunctionName="props.ecmFunctionName"
            @rerender-measures-grid="handleRerenderMeasuresGrid"
          />
        </span>
        <span class="pa-2" v-if="userStore.hasRoleUser">
          <add-measure-modal :addressId="props.addressId"></add-measure-modal>
        </span>
        <span class="pa-2">
          <applied-logging-modal :addressId="props.addressId"></applied-logging-modal>
        </span>
      </v-col>
    </v-row>
    <v-row class="overview-filters align-items-start mt-4">
      <v-col cols="auto">
        <filter-set :name="FilterTokenName.Applied" :filters="filters" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <building-applied-grid :addressId="props.addressId" :filterData="filterData" :currentPartyType="userStore.currentPartyType"></building-applied-grid>
      </v-col>
    </v-row>
  </v-container>
</template>
