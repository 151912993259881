<script>
    import { HorizontalBar } from "vue-chartjs";

    export default {
        name: "AdvancedHorizontalBarChart",

        extends: HorizontalBar,

        props: ['chartData', 'options'],

        data() {
            return {
                htmlLegend: null
            }
        },

        mounted() {
            this.htmlLegend = this.generateLegend();
        },

        watch: {
            htmlLegend: function () {
                this.$emit('chart-rendered', this.htmlLegend);
            },

            chartData: {
                deep: true,
                handler(chartData) {
                    this.renderChart(chartData, this.options);
                }
            }
        }
    }
</script>