var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c("header", [
        _c("h4", [
          _vm._v("Overzicht van meters in " + _vm._s(_setup.props.year)),
        ]),
      ]),
      _c(
        "v-data-table",
        {
          staticClass: "overview-table usage-table",
          attrs: {
            options: _setup.gridOptions,
            headers: _setup.headers,
            "hide-default-footer": "",
            dense: "",
            items: _setup.gridData,
            loading: _setup.gridIsLoading,
            "items-per-page": _setup.pageSize,
            "server-items-length": _setup.totalItems,
            "footer-props": { usage: 123 },
          },
          on: {
            "update:options": [
              function ($event) {
                _setup.gridOptions = $event
              },
              _setup.changedOptions,
            ],
          },
          scopedSlots: _vm._u(
            [
              {
                key: `item.value`,
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_setup.formatThousandNoFraction(item.value)) +
                        " " +
                        _vm._s(item.unitTypeUnit) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: `item.valuePps`,
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_setup.formatThousandNoFraction(item.valuePps)) +
                        " kWh "
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _c("template", { slot: "body.append" }, [
            _c("tr", { staticClass: "totals" }, [
              _c(
                "td",
                { staticClass: "description", attrs: { colspan: "4" } },
                [_vm._v("Totaal:")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(_setup.formatThousandNoFraction(_setup.ppsUsage)) +
                    " kWh"
                ),
              ]),
            ]),
            _c("tr", { staticClass: "totals" }, [
              _c(
                "td",
                { staticClass: "description", attrs: { colspan: "4" } },
                [_vm._v("Oppervlakte:")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(_setup.formatThousandNoFraction(_setup.surface)) +
                    " m²"
                ),
              ]),
            ]),
            _c("tr", { staticClass: "totals" }, [
              _c(
                "td",
                { staticClass: "description", attrs: { colspan: "4" } },
                [_vm._v("Paris Proof Score:")]
              ),
              _c("td", [_vm._v(_vm._s(_setup.ppScore) + " kWh/m²")]),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }