<script lang="ts" setup>
import { computed } from "vue";

import _ from "lodash";

const props = defineProps({
  label: {
    type: String,
    default: "?",
  },

  estimate: {
    type: Boolean,
    default: false,
  },
});

const formattedLabel = computed(() => {
  if (["A+++++", "A++++", "A+++", "A++", "A+", "A", "B", "C", "D", "E", "F", "G"].includes(_.toUpper(props.label))) {
    return _.toUpper(props.label);
  }

  return "?";
});

const colorClass = computed(() => {
  if (props.estimate === true) {
    return "color-unknown";
  }

  switch (_.toLower(props.label)) {
    case "a+++++":
      return "color-a-plus-plus-plus-plus-plus";
    case "a++++":
      return "color-a-plus-plus-plus-plus";
    case "a+++":
      return "color-a-plus-plus-plus";
    case "a++":
      return "color-a-plus-plus";
    case "a+":
      return "color-a-plus";
    case "a":
      return "color-a";
    case "b":
      return "color-b";
    case "c":
      return "color-c";
    case "d":
      return "color-d";
    case "e":
      return "color-e";
    case "f":
      return "color-f";
    case "g":
      return "color-g";
    default:
      return "color-unknown";
  }
});
</script>
<template>
  <div class="energy-label-wrap" title="Geschatte of ontbrekende labels zijn grijs">
    <span class="icon-energy-label" :class="colorClass"></span>
    {{ formattedLabel }}
  </div>
</template>
