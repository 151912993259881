<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  items: undefined,
});

const breadcrumbs = computed(() => {
  const list = [
    {
      icon: "mdi-home",
      disabled: false,
      exact: true,
      text: "<v-icon>mdi-account</v-icon>",
      to: { name: "Dashboard" },
      params: null,
    },
  ];

  if (props.items) {
    props.items.forEach((element) => { 
      list.push({
        disabled: false,
        exact: true,
        text: element.text,
        to: { name: element.to, params: element.params },
        params: element.params,
      });
    })
  }

  return list;
});
</script>

<template>
  <v-breadcrumbs class="pb-0" :items="breadcrumbs" divider=">">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :to="item.to">
        <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
        <template v-else>{{ item.text }}</template>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
