<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  percentage: {
    type: Number,
    default: undefined,
  },
  color: {
    type: String,
  },
  width: {
    type: Number,
    default: 150,
  },
  height: {
    type: Number,
    default: 150,
  },
});

const chartStyles = computed(() => {
  return {
    width: `${props.width}px`,
    height: `${props.height}px`,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  };
});
</script>

<template>
  <v-container class="vue-chart vue-percentage-chart inline-text" pa-0>
    <v-row>
      <v-col class="vue-chart-wrapper" cols="auto">
        <simple-doughnut-chart :percentage="props.percentage" :color="props.color" :styles="chartStyles"></simple-doughnut-chart>
        <div class="textual-percentage"><number :to="props.percentage" :duration="1.6"></number>%</div>
      </v-col>
    </v-row>
  </v-container>
</template>
