var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "", "fill-height": "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "app-paris-proof-sidebar",
              attrs: { lg: "3", md: "4", sm: "12" },
            },
            [
              _c("net-heat-requirement", {
                attrs: { token: _setup.token, refDate: _setup.refDate },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "app-dashboard-overview",
              attrs: { lg: "9", md: "8", sm: "12" },
            },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "px-0": "", "py-0": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "app-welcome-message" }, [
                        _c("h1", [
                          _vm._v("Welkom" + _vm._s(_setup.welcomeName) + ","),
                        ]),
                        _c("p", [
                          _vm._v(
                            "Hieronder wordt uw persoonlijke dashboard weergegeven"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "masonry",
                            {
                              staticClass: "app-dashboard-widget-overview",
                              attrs: {
                                cols: { default: 2, 1264: 1 },
                                gutter: 24,
                              },
                            },
                            [
                              _c(
                                "v-layout",
                                { staticClass: "inner" },
                                [
                                  _setup.token
                                    ? _c("data-availability", {
                                        attrs: { token: _setup.token },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                { staticClass: "inner" },
                                [
                                  _setup.token
                                    ? _c("complex-functions", {
                                        attrs: { token: _setup.token },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                { staticClass: "inner" },
                                [
                                  _setup.token
                                    ? _c("building-energylabels-status", {
                                        attrs: {
                                          token: _setup.token,
                                          partyType:
                                            _setup.userStore.currentPartyType,
                                          labelSource: "EPOnline",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                { staticClass: "inner" },
                                [
                                  _setup.userStore.currentPartyReference
                                    ? _c("sustainability-radar", {
                                        attrs: {
                                          partyReference:
                                            _setup.userStore
                                              .currentPartyReference,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                { staticClass: "inner" },
                                [
                                  _setup.token
                                    ? _c("building-measures", {
                                        attrs: {
                                          token: _setup.token,
                                          certified: false,
                                          refDate: _setup.refDate,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "headerwidget",
                                              fn: function () {
                                                return [
                                                  _c("h2", [
                                                    _vm._v("Maatregelen"),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "infotext",
                                              fn: function () {
                                                return [
                                                  _c("p", [
                                                    _vm._v(
                                                      "In dit overzicht wordt getoond op basis van al uw complexen wat de voortgang is."
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "nomeasurestext",
                                              fn: function () {
                                                return [
                                                  _c("p", [
                                                    _vm._v(
                                                      "Er zijn nog geen maatregelen aan dit project toegevoegd"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1219127987
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                { staticClass: "inner" },
                                [
                                  _setup.token
                                    ? _c("homes-gasless", {
                                        attrs: { token: _setup.token },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }