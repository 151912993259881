<script lang="ts" setup>
import { ref, computed, watch } from "vue";

import _ from "lodash";

const props = defineProps({
  height: {
    type: Number,
    default: 150,
  },

  sourceData: {
    type: Object,
    required: true,
  },

  legendPosition: {
    type: String,
    default: "legend-side",
  },
});

const refreshKey = ref(0);
const chartData = ref({
  labels: [],
  datasets: [
    {
      label: "Investering",
      type: "bar",
      yAxisID: "right",
      data: [],
      order: 1,
      backgroundColor: "#00A2DF",
    },
    {
      label: "Aantal woningen dat voldoet aan warmtebehoefte",
      type: "line",
      fill: "false",
      borderWidth: "3",
      borderColor: "orange",
      pointRadius: "0",
      pointStyle: "line",
      lineTension: "0",
      yAxisID: "left",
      data: [],
      order: 2,
      backgroundColor: "orange",
    },
  ],
});
const options = ref({
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    labels: {
      padding: 30,
      usePointStyle: true,
    },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let tooltipData = data.datasets[tooltipItem.datasetIndex];
        let label = tooltipData.label;
        let dataValue = tooltipData.data[tooltipItem.index].toLocaleString("nl-NL", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        let type = label === "Investering" ? " €" : "";
        return label + ": " + dataValue + type;
      },
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        id: "left",
        type: "linear",
        position: "left",
        ticks: {
          callback: function (value) {
            return value.toLocaleString();
          },
        },
        gridLines: {
          drawOnChartArea: false,
        },
        scaleLabel: {
          display: "true",
          labelString: "Aantal woningen",
          fontSize: "16",
        },
      },
      {
        id: "right",
        type: "linear",
        position: "right",
        ticks: {
          callback: function (value) {
            return value.toLocaleString("nl-NL", { style: "currency", currency: "EUR", minimumFractionDigits: 0, maximumFractionDigits: 0 });
          },
        },
        gridLines: {
          borderDash: [15, 15],
          drawOnChartArea: true,
        },
        scaleLabel: {
          display: "true",
          labelString: "Investering in €",
          fontSize: "16",
        },
      },
    ],
  },
});

const chartStyles = computed(() => {
  return {
    height: `${props.height}px`,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  };
});

watch(
  () => props.sourceData,
  () => {
    if (props.sourceData.yearlyInvestmentCumulative && props.sourceData.yearlyHomesMeetingNetHeatRequirement) {
      let keys = [];
      if (props.sourceData.yearlyInvestmentCumulative) {
        keys = _.merge(keys, _.keys(props.sourceData.yearlyInvestmentCumulative));
      }
      if (props.sourceData.yearlyHomesMeetingNetHeatRequirement) {
        keys = _.merge(keys, _.keys(props.sourceData.yearlyHomesMeetingNetHeatRequirement));
      }
      chartData.value.labels = keys;

      if (props.sourceData.yearlyInvestmentCumulative) {
        chartData.value.datasets[0].data = _.values(props.sourceData.yearlyInvestmentCumulative);
      }
      if (props.sourceData.yearlyHomesMeetingNetHeatRequirement) {
        chartData.value.datasets[1].data = _.values(props.sourceData.yearlyHomesMeetingNetHeatRequirement);
      }
      refreshKey.value++;
    }
  }
);
</script>

<template>
  <v-row class="vue-chart vue-chart-roadmap-investment-vs-homes-meeting-netheat-requirement pa-0" :class="props.legendPosition">
    <v-col class="vue-chart-wrapper" cols="12">
      <advanced-combined-bar-line-chart :chartData="chartData" :options="options" :styles="chartStyles" :key="refreshKey"></advanced-combined-bar-line-chart>
    </v-col>
  </v-row>
</template>
