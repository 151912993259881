<script lang="ts" setup>
import { watch, ref, getCurrentInstance } from "vue";
import { getComplex } from "@/services/api/building.api";

const props = defineProps({
  addressId: String,
});

const isLoading = ref(false);

const nhSummary = ref({
  total: 0,
  counted: 0,
  percentage: "",
  colorClass: "",
});

watch(
  () => props.addressId,
  () => {
    fetchData();
  },
  { immediate: true }
);

const warningText = ref(null);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  isLoading.value = true;
  const comp = await getComplex(props.addressId);
  if (comp && !comp.error) {
    console.log("ComplexDetails, fetchData, response: ", comp.data);
    nhSummary.value.counted = comp.data.netHeatRequirement.homesCounted;
    nhSummary.value.total = comp.data.netHeatRequirement.homesTotal;
    let pct = Math.trunc((100 * comp.data.netHeatRequirement.homesMeetingNetHeatRequirement) / comp.data.netHeatRequirement.homesTotal + 0.5);
    if (Number.isNaN(pct)) {
      nhSummary.value.colorClass = "color-grey";
    } else if (pct < 50) {
      nhSummary.value.colorClass = "color-red";
    } else if (pct < 85) {
      nhSummary.value.colorClass = "color-orange";
    } else {
      nhSummary.value.colorClass = "color-green";
    }
    if (Number.isNaN(pct)) {
      nhSummary.value.percentage = "-";
    } else {
      nhSummary.value.percentage = pct + "%";
    }

    if (nhSummary.value.counted < nhSummary.value.total) {
      warningText.value = `De netto warmtebehoefte is nog niet bekend van alle woningen in dit complex.\n We hebben gegevens voor ${nhSummary.value.counted} van de ${nhSummary.value.total} woningen.`;
    } else {
      warningText.value = null;
    }
  } else {
    nhSummary.value = { counted: 0, total: 0, percentage: "-", colorClass: "color-grey" };
    proxy.$toaster.error("Ophalen van de Netto Warmtebehoefte is mislukt!");
    // errored = true;
    // error = comp.error;
  }
  isLoading.value = false;
}
</script>

<template>
  <perfect-scrollbar>
    <h2>Netto warmtebehoefte</h2>

    <information-dropdown>
      <template v-slot:header>
        <h2>Netto warmtebehoefte</h2>
      </template>

      <p>Dit toont het percentage van de woningen waarvan de netto warmtebehoefte voldoet aan de standaard.</p>

      <p>
        De <b>Netto warmtevraag/behoefte</b> is een getal dat vertelt hoeveel energie er nodig is voor het verwarmen van de woning en wordt uitgedrukt in kilowattuur per vierkante meter per jaar
        (kWh/m²/jaar).
      </p>

      <p>
        De <b>Standaard</b> richt zich op de definitie van een zogenaamde “spijtvrije” verbouwing waarmee de gebouweigenaar ervan verzekerd is dat later, bij aansluiting op duurzame bronnen met een
        lagere temperatuur, niet nogmaals een ingrijpende aanpassing aan dezelfde bouwdelen noodzakelijk is.
      </p>
    </information-dropdown>

    <warning-dropdown v-if="warningText">
      <template v-slot:header>
        <h2>Ontbrekende data</h2>
      </template>
      <p>{{ warningText }}</p>
    </warning-dropdown>

    <span v-if="isLoading">
      <loader-spinner :is-loading="true"></loader-spinner>
    </span>
    <div v-else class="paris-score">
      <span :class="nhSummary.colorClass"> Percentage van het aantal woningen dat voldoet aan de warmtebehoefte: {{ nhSummary.percentage }} </span>
    </div>

    <v-container>
      <v-row>
        <v-col md="12" sm="6">
          <div class="paris-proof-score">
            <house-widget :colorClass="nhSummary.colorClass"></house-widget>
          </div>
        </v-col>
        <v-col md="12" sm="6">
          <div class="paris-proof-legend">
            <div class="item"><span class="color color-red"></span> <b>0 - 49%</b></div>
            <div class="item"><span class="color color-orange"></span> <b>50 - 84%</b></div>
            <div class="item"><span class="color color-green"></span> <b>85 - 100%</b></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </perfect-scrollbar>
</template>
