import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { setCurrentUser, setCurrentProject } from "@/services/api/user.api";
import { useFiltertokenStore } from "@/stores/filtertoken";

const PartyType = {
  None: "None",
  Utility: "Buildings",
  Homes: "Homes",
  EnergyLabels: "EnergyLabels",
};

const Permission = {
  Admin: "Admin",
  Manager: "Manager",
  User: "User",
  Guest: "Guest",
  ShowComputeCoreValues: "ShowComputeCoreValues",
};

const useUserStore = defineStore("user", () => {
  const filtertokenStore = useFiltertokenStore();
  const { resetAllTokens } = filtertokenStore;

  const userProfile = ref({
    userId: "",
    userName: "",
    firstname: "",
    middlename: "",
    lastname: "",
    fullName: "",
    emailAddress: "",
  });

  const userProject = ref({
    projectId: "",
    description: "",
    logoName: "",
    partyReference: 0,
    partyType: "",
    permissions: [],
  });

  const selectableProjects = ref([]);

  const userAccess = ref({
    permissions: [],
    roles: [],
    isActive: false,
    lastLogin: {
      type: Date,
      default: null,
    },
  });

  const getUserFullname = computed(() => userProfile.value.fullName);

  const friendlyName = computed(() => {
    let name = "";
    if (userProfile.value.firstName != null) {
      name = userProfile.value.firstName;
    } else {
      if (userProfile.value.fullName != null) {
        name = userProfile.value.fullName;
      }
    }
    return name;
  });

  const isAdministrator = computed(() => matchingAppPermission([Permission.Admin]));

  const hasRoleGuest = computed(() => matchingProjectPermission([Permission.Guest]));
  const hasRoleUser = computed(() => matchingProjectPermission([Permission.User]));
  const hasRoleManager = computed(() => matchingProjectPermission([Permission.Manager]));

  const breadcrumbList = ref([{ default: "Dashboard" }]);


  function matchingProjectPermission(authorize) {
    return userProject.value.permissions ? userProject.value.permissions.some((r) => authorize.indexOf(r) >= 0) : false;
  }

  function matchingAppPermission(authorize) {
    return userAccess.value.permissions ? userAccess.value.permissions.some((r) => authorize.indexOf(r) >= 0) : false;
  }

  const hasShowComputeCoreValues = computed(() => matchingAppPermission([Permission.ShowComputeCoreValues]));

  const currentPartyReference = computed(() => userProject.value.partyReference);
  const currentPartyType = computed(() => userProject.value.partyType);
  const currentProjectName = computed(() => userProject.value.description);

  const isPartyUtility = computed(() => userProject.value.partyType == PartyType.None || userProject.value.partyType == PartyType.Utility);
  const isPartyHome = computed(() => userProject.value.partyType == PartyType.Homes);
  const isPartyEnergyLabels = computed(() => userProject.value.partyType == PartyType.EnergyLabels);

  function party(partyReference) {
    return selectableProjects.value.find((p) => p.partyReference == partyReference);
  }

  const getAllProjecs = computed(() => selectableProjects.value);

  async function checkStoreState() {
    //const profile = userProfile;
    //const id = profile.userId;
    //console.log("userStore, checkStoreState", userProfile, id);
    if (!userProfile.value.userId || userProfile.value.userId == "") {
      console.log("userStore, checkStoreState no userprofile", userProfile.value, userProfile.value.userId);
      await setUserAccessData();
    }
  }

  async function setUserAccessData() {
    console.log("userStore, setUserAccessData ");
    const userData = await setCurrentUser();
    console.log("userStore, setUserAccessData userdata: ", userData);

    // De opgehaalde UserAccess data wordt in store bewaard.
    userProfile.value.userId = userData.data.profile.userId;
    userProfile.value.firstname = userData.data.profile.firstname;
    userProfile.value.middlename = userData.data.profile.middlename;
    userProfile.value.lastname = userData.data.profile.lastname;
    userProfile.value.fullName = userData.data.profile.fullName;
    userProfile.value.emailAddress = userData.data.profile.email;

    if (userData.data.access) {
      console.log("userStore, mapUserAccess ", userData.data.access);
      userAccess.value.permissions = [...userData.data.access.permissions];
    }
    if (userData.data.projects) {
      //console.log("userStore, selectableProjects ", userData.data.projects);
      selectableProjects.value = [...userData.data.projects];
    }
    if (userData.data.currentProject) {
      updateUserProject(userData.data.currentProject);
    }
  }

  function isPartyAllowed(partyReference) {
    return selectableProjects.value.some((p) => p.partyReference == partyReference);
  }

  async function setParty(partyReference) {
    const userData = await setCurrentProject(partyReference);
    resetAllTokens();
    //console.log("userStore, setParty ", userData, userData.data.currentProject.description);
    // De opgehaalde UserAccess data wordt in store bewaard.
    updateUserProject(userData.data.currentProject);
  }

  async function isAuthorized(authorize) {
    await checkStoreState();
    //console.log("userStore, isAuthorized, checken op: ", authorize);
    const matchingProjectPermissions = matchingProjectPermission(authorize);
    //console.log("userStore, isAuthorized, ", matchingProjectPermissions);
    return matchingProjectPermissions;
  }

  function resetPartyType() {
    userProject.value.partyType = PartyType.None;
  }

  function updateUserProject(data) {
    console.log("userStore, mapUserProject ", data);
    userProject.value.partyReference = data.partyReference;
    userProject.value.partyType = data.partyTypeKey;
    userProject.value.description = data.description;
    userProject.value.logoName = data.logoName;
    userProject.value.permissions = [...data.permissions];
  }

  function resetBreadcrumb(breadcrumb) {
    breadcrumbList.value = [{
      text: breadcrumb.meta.breadCrumb.text, 
      to: breadcrumb.meta.breadCrumb.to, 
      params: breadcrumb.params.value,
    }];
  }

  function pushBreadcrumb(breadcrumb) {
    // console.log("InBreadcrumb, pushBreadcrumb, breadcrumb: ", breadcrumb)
    let index = breadcrumbList.value.findIndex(x => x.to == breadcrumb.name);
    if (index > 0) {
      breadcrumbList.value.splice(index);
    }
    breadcrumbList.value.push({
      text: breadcrumb.meta.breadCrumb.text, 
      to: breadcrumb.meta.breadCrumb.to, 
      params: breadcrumb.params,
    });
  }

  return {
    checkStoreState,
    setUserAccessData,
    getAllProjecs,
    isPartyAllowed,
    setParty,
    getUserFullname,
    friendlyName,
    matchingProjectPermission,
    isAdministrator,
    hasRoleGuest,
    hasRoleUser,
    hasRoleManager,
    hasShowComputeCoreValues,
    currentPartyReference,
    currentPartyType,
    currentProjectName,
    isPartyUtility,
    isPartyHome,
    isPartyEnergyLabels,
    isAuthorized,
    resetPartyType,
    party,
    breadcrumbList,
    resetBreadcrumb,
    pushBreadcrumb,
  };
});

export { useUserStore, Permission, PartyType };
