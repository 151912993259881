var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-locationlabel-overview", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "app-welcome-message" }, [
            _c("h1", [_vm._v("Overzicht van energielabels")]),
            _c("p", [
              _vm._v(
                "In dit overzicht worden alle Vabi en EPOnline energielabels van gebouwen in uw portefeuille getoond. Aan de hand van het filter kunt u uw gewenste inzicht creëren."
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "3", sm: "6" } },
            [
              _setup.partyReference && _setup.gridLoaded
                ? _c("building-overview-energy-labels", {
                    attrs: {
                      partyReference: _setup.partyReference,
                      filterData: _setup.filterData,
                      partyType: "EnergyLabels",
                      labelSource: "VabiApi",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "headerwidget",
                          fn: function () {
                            return [
                              _c("h3", [
                                _vm._v("Verdeling huidige VABI labels"),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3322807740
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "3", sm: "6" } },
            [
              _setup.partyReference && _setup.gridLoaded
                ? _c("building-overview-energy-labels", {
                    attrs: {
                      partyReference: _setup.partyReference,
                      filterData: _setup.filterData,
                      partyType: "EnergyLabels",
                      labelSource: "EPOnline",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "headerwidget",
                          fn: function () {
                            return [
                              _c("h3", [_vm._v("Verdeling EPOnline labels")]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2028822817
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "2", sm: "3" } },
            [
              _setup.partyReference && _setup.gridLoaded
                ? _c("building-energylabels-age", {
                    attrs: {
                      partyReference: _setup.partyReference,
                      filterData: _setup.filterData,
                      labelSource: "EPOnline",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "headerwidget",
                          fn: function () {
                            return [
                              _c("h3", [_vm._v("Leeftijd EPOnline labels")]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2590579476
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c("v-col", [
            _c(
              "article",
              { staticClass: "inner" },
              [
                _c(
                  "v-row",
                  { staticClass: "overview-filters align-items-start" },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c("filter-set", {
                          attrs: {
                            name: _setup.FilterTokenName.LocationLabel,
                            filters: _setup.filters,
                          },
                          on: {
                            "filter-search": _setup.handleFilterSearch,
                            "filters-changed27": _setup.handleFiltersChanged,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary",
                            attrs: { disabled: _setup.downloadBusy },
                            on: {
                              click: function ($event) {
                                return _setup.downloadDocuments()
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { dark: "", left: "" } }, [
                              _vm._v(_vm._s(_setup.mdiFileDownload)),
                            ]),
                            _vm._v(" download (meerdere) labels "),
                            _setup.downloadBusy
                              ? _c("span", { staticClass: "loadingDots" })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(_setup.LocationLabelGrid, {
                  attrs: {
                    partyReference: _setup.partyReference,
                    filterData: _setup.filterData,
                  },
                  on: { "fetch-data-complete": _setup.dataLoaded },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }