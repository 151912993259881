var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "warning-modal",
      class: { "has-button": _setup.props.buttonType === "text" },
    },
    [
      _setup.props.buttonType === "info"
        ? _c(
            "a",
            {
              attrs: {
                href: "#",
                type: "button",
                "data-toggle": "modal",
                "data-target": "#" + _setup.props.id,
              },
            },
            [_c("span", { staticClass: "icon-warning-icon" })]
          )
        : _vm._e(),
      _setup.props.buttonType === "text"
        ? _c(
            "a",
            {
              staticClass: "widget-link",
              attrs: {
                href: "#",
                type: "button",
                "data-toggle": "modal",
                "data-target": "#" + _setup.props.id,
              },
            },
            [_vm._t("button-txt")],
            2
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: _setup.props.id,
            "data-backdrop": "static",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": _setup.props.id + "-label",
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered text-modal",
              attrs: { role: "document" },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c(
                    "h2",
                    {
                      staticClass: "modal-title",
                      attrs: { id: _setup.props.id + "-label" },
                    },
                    [_vm._t("header")],
                    2
                  ),
                  _vm._m(0),
                ]),
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [_c("v-row", [_c("v-col", [_vm._t("default")], 2)], 1)],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [
        _vm._v("Sluiten "),
        _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }