var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    [
      _c(
        "v-app-bar",
        { staticClass: "navbar", attrs: { app: "", color: "white" } },
        [
          _c("v-app-bar-nav-icon", {
            staticClass: "d-flex d-md-none",
            on: {
              click: function ($event) {
                _setup.drawerLeft = true
              },
            },
          }),
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Dashboard" } } },
                [
                  _c("v-img", {
                    staticClass: "shrink mr-2 brand",
                    attrs: {
                      alt: "INNAX Logo",
                      contain: "",
                      src: require("@/assets/img/logo.png"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "d-none d-md-flex" },
            [
              _c("v-btn", { attrs: { text: "", to: { name: "Dashboard" } } }, [
                _vm._v(" Dashboard"),
              ]),
              _setup.userStore.isPartyUtility
                ? _c(
                    "v-btn",
                    { attrs: { text: "", to: { name: "BuildingOverview" } } },
                    [_vm._v("Gebouwen")]
                  )
                : _setup.userStore.isPartyHome
                ? _c(
                    "v-btn",
                    { attrs: { text: "", to: { name: "ComplexOverview" } } },
                    [_vm._v("Complexen")]
                  )
                : _vm._e(),
              _setup.userStore.isPartyUtility || _setup.userStore.isPartyHome
                ? _c(
                    "v-btn",
                    { attrs: { text: "", to: { name: "AppliedOverview" } } },
                    [_vm._v("Maatregelen")]
                  )
                : _vm._e(),
              _setup.userStore.isPartyUtility
                ? _c(
                    "v-btn",
                    { attrs: { text: "", to: { name: "RoadMapUtility" } } },
                    [_vm._v("Routekaart")]
                  )
                : _vm._e(),
              _setup.userStore.isPartyHome
                ? _c(
                    "v-btn",
                    { attrs: { text: "", to: { name: "RoadMapHome" } } },
                    [_vm._v("Routekaart")]
                  )
                : _vm._e(),
              _setup.userStore.isPartyUtility || _setup.userStore.isPartyHome
                ? _c(
                    "v-btn",
                    { attrs: { text: "", to: { name: "Rapportage" } } },
                    [_vm._v("Rapportage")]
                  )
                : _vm._e(),
              _setup.showMenuItemBeheer
                ? _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs, value }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { text: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(" Beheer "),
                                    value
                                      ? _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v("mdi-chevron-up"),
                                        ])
                                      : _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v("mdi-chevron-down"),
                                        ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3951387447
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "navbar" },
                        [
                          _setup.showMenuItemInformatieplicht
                            ? _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    text: "",
                                    to: { name: "ProjectCreateBulkUploadXML" },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Informatieplicht"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-divider"),
                          _c(
                            "v-list-item",
                            { attrs: { to: { name: "Info" } } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-information-outline"),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Info")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-layout",
            {
              staticClass: "d-none d-lg-flex",
              attrs: { "justify-end": "", "flex-grow-0": "" },
            },
            [
              _setup.projectList.length > 1
                ? _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "", origin: "top right" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs, value }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { text: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "div",
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _setup.userStore
                                                .currentProjectName
                                            )
                                        ),
                                        _setup.userStore.hasRoleManager
                                          ? _c(
                                              "v-icon",
                                              { attrs: { right: "" } },
                                              [_vm._v("mdi-account-star")]
                                            )
                                          : _vm._e(),
                                        value
                                          ? _c(
                                              "v-icon",
                                              { attrs: { right: "" } },
                                              [_vm._v("mdi-chevron-up")]
                                            )
                                          : _c(
                                              "v-icon",
                                              { attrs: { right: "" } },
                                              [_vm._v("mdi-chevron-down")]
                                            ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3011125603
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "navbar projects" },
                        _vm._l(_setup.projectList, function (project) {
                          return _c(
                            "v-list-item",
                            {
                              key: project.reference,
                              attrs: { value: project.reference, link: "" },
                              on: {
                                click: function ($event) {
                                  return _setup.switchProject(project.reference)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  project.partyType ==
                                  _setup.PartyType.EnergyLabels
                                    ? _c("v-icon", [
                                        _vm._v("mdi-label-outline"),
                                      ])
                                    : project.partyType ==
                                      _setup.PartyType.Homes
                                    ? _c("v-icon", [_vm._v("mdi-home-outline")])
                                    : project.partyType ==
                                        _setup.PartyType.Utility ||
                                      project.partyType == _setup.PartyType.None
                                    ? _c("v-icon", [
                                        _vm._v("mdi-office-building-outline"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(project.name) + " "),
                                ],
                                1
                              ),
                              project.isManager
                                ? _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-account-star"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", { staticClass: "v-btn" }, [
                    _vm._v(
                      " " + _vm._s(_setup.userStore.currentProjectName) + " "
                    ),
                  ]),
            ],
            1
          ),
          _c(
            "v-layout",
            {
              staticClass: "logo-project",
              attrs: { "justify-end": "", "flex-grow-0": "" },
            },
            [_c("img", { attrs: { src: _setup.logoName } })]
          ),
          _c(
            "v-layout",
            {
              staticClass: "d-none d-lg-flex",
              attrs: { "justify-end": "", "flex-grow-0": "" },
            },
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", origin: "top right" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { text: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_setup.userStore.getUserFullname) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "navbar" },
                    [
                      _c(
                        "v-list-item",
                        {
                          attrs: { link: "" },
                          on: {
                            click: function ($event) {
                              return _setup.logout()
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-logout")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Afmelden")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-app-bar-nav-icon",
            {
              staticClass: "d-flex d-lg-none",
              on: {
                click: function ($event) {
                  _setup.drawerRight = true
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-account-outline")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { absolute: "", temporary: "" },
          model: {
            value: _setup.drawerLeft,
            callback: function ($$v) {
              _setup.drawerLeft = $$v
            },
            expression: "drawerLeft",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "" } },
            [
              _c(
                "v-list-item",
                { attrs: { text: "", to: { name: "Dashboard" } } },
                [_c("v-list-item-title", [_vm._v("Dashboard")])],
                1
              ),
              _c("v-divider"),
              _setup.userStore.isPartyUtility || _setup.userStore.isPartyHome
                ? _c(
                    "v-list-item",
                    { attrs: { text: "", to: { name: "BuildingOverview" } } },
                    [
                      _setup.userStore.isPartyUtility
                        ? _c("v-list-item-title", [_vm._v("Gebouwen")])
                        : _setup.userStore.isPartyHome
                        ? _c("v-list-item-title", [_vm._v("Complexen")])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _setup.userStore.isPartyUtility || _setup.userStore.isPartyHome
                ? _c(
                    "v-list-item",
                    { attrs: { text: "", to: { name: "AppliedOverview" } } },
                    [_c("v-list-item-title", [_vm._v("Maatregelen")])],
                    1
                  )
                : _vm._e(),
              _setup.userStore.isPartyUtility
                ? _c(
                    "v-list-item",
                    { attrs: { text: "", to: { name: "RoadMapUtility" } } },
                    [_c("v-list-item-title", [_vm._v("Routekaart")])],
                    1
                  )
                : _vm._e(),
              _setup.userStore.isPartyHome
                ? _c(
                    "v-list-item",
                    { attrs: { text: "", to: { name: "RoadMapHome" } } },
                    [_c("v-list-item-title", [_vm._v("Routekaart")])],
                    1
                  )
                : _vm._e(),
              _setup.userStore.isPartyUtility || _setup.userStore.isPartyHome
                ? _c(
                    "v-list-item",
                    { attrs: { text: "", to: { name: "Rapportage" } } },
                    [_c("v-list-item-title", [_vm._v("Rapportage")])],
                    1
                  )
                : _vm._e(),
              _c("v-divider"),
              _setup.showMenuItemBeheer
                ? _c(
                    "v-list-group",
                    {
                      attrs: { value: false },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [_vm._v("Beheer")]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1494228922
                      ),
                    },
                    [
                      _setup.showMenuItemInformatieplicht
                        ? _c(
                            "v-list-item",
                            {
                              attrs: {
                                text: "",
                                to: { name: "ProjectCreateBulkUploadXML" },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v("Informatieplicht"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        { attrs: { to: { name: "Info" } } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-information-outline")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Info")]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { absolute: "", temporary: "", right: "" },
          model: {
            value: _setup.drawerRight,
            callback: function ($$v) {
              _setup.drawerRight = $$v
            },
            expression: "drawerRight",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "" } },
            [
              _c("v-list-item-title", { staticClass: "ma-2" }, [
                _vm._v(" " + _vm._s(_setup.userStore.getUserFullname) + " "),
              ]),
              _c("v-divider"),
              _setup.projectList.length > 1
                ? _c(
                    "v-list-group",
                    {
                      attrs: { value: false },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [_vm._v("Projecten")]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3437788569
                      ),
                    },
                    _vm._l(_setup.projectList, function (project) {
                      return _c(
                        "v-list-item",
                        {
                          key: project.reference,
                          attrs: { value: project.reference, link: "" },
                          on: {
                            click: function ($event) {
                              return _setup.switchProject(project.reference)
                            },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(" " + _vm._s(project.name) + " "),
                          ]),
                          project.isManager
                            ? _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-account-star")])],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c("div", { staticClass: "v-btn" }, [
                    _vm._v(
                      " " + _vm._s(_setup.userStore.currentProjectName) + " "
                    ),
                  ]),
              _c("v-divider"),
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function ($event) {
                      return _setup.logout()
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-logout")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Afmelden")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c("inn-breadcrumbs", { attrs: { items: _setup.breadcrumbs } }),
          _c("router-view"),
        ],
        1
      ),
      _c(
        "v-footer",
        { attrs: { app: "", fixed: "" } },
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "pa-0": "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { align: "center" } },
                    [
                      _vm._v(
                        " © " +
                          _vm._s(new Date().getFullYear()) +
                          " - Paris Proof Monitor "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }