var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "overview-table",
        attrs: {
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
          "show-select": _vm.showSelect,
          "item-key": "guid",
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.edit`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        small: "",
                        color: "primary",
                        to: {
                          name: "AppliedDetails",
                          params: { appliedId: item.guid },
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-pencil "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.investment`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_setup.formatEuroNoFraction(item.investment)) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.proposalDate`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.proposalDate)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.definition.isApplicableAtIndependentMoment`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.definition.isApplicableAtIndependentMoment === true
                          ? "Ja"
                          : "Nee"
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.kind`,
              fn: function ({ item }) {
                return [
                  _c(_setup.MeasureKindLogo, {
                    attrs: {
                      kind: item.definition.kind.key,
                      description: item.definition.kind.descr,
                    },
                  }),
                ]
              },
            },
            {
              key: `item.reductionCo2`,
              fn: function ({ item }) {
                return [
                  item.reductionCo2 != null
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _setup.formatThousandNoFraction(item.reductionCo2)
                          ) + " kg "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _setup.selected,
          callback: function ($$v) {
            _setup.selected = $$v
          },
          expression: "selected",
        },
      }),
      !_vm.showSelect
        ? _c(_setup.GridPagination, {
            attrs: {
              pageNumber: _setup.pageNumber,
              pageSize: _setup.pageSize,
              pageCount: _setup.pageCount,
            },
            on: { changedPaging: _setup.changedPaging },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }