<script lang="ts" setup>
import { ref, watch } from "vue";

import { getAppliedOverviewTypes } from "@/services/api/chart.api";

const props = defineProps({
  token: {
    type: String,
  },
});

const types = ref({});
const isLoading = ref(true);

watch(
  () => props.token,
  () => {
    if (props.token) {
      fetchData();
    } else {
      types.value = {};
    }
  }
);

async function fetchData() {
  isLoading.value = true;
  const response = await getAppliedOverviewTypes(props.token);
  if (response) {
    types.value = response;
  }
  isLoading.value = false;
}
</script>

<template>
  <v-layout class="widget-item">
    <h3>Verdeling van de maatregelen</h3>
    <div class="widget-wrapper widget-chart">
      <loader-spinner v-show="isLoading === true" :is-loading="isLoading" :min-height="170"></loader-spinner>
      <chart-applied-types v-show="isLoading === false" :height="170" :sourceData="types"> </chart-applied-types>
    </div>
  </v-layout>
</template>
