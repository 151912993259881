<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";
import { getBuildingEnergyLabels, getBuildingEnergyLabelsParty } from "@/services/api/chart.api";

const props = defineProps({
  token: String,
  partyReference: String,
  filterData: { type: Array, default: () => [] },
  partyType: String,
  labelSource: String,
});

const energyLabels = ref({});
const isLoading = ref(true);

watch(
  () => props.token,
  () => {
    if (props.token) {
      fetchTokenData();
    } else {
      energyLabels.value = {};
    }
  }
);

watch(
  () => [props.partyReference, props.filterData],
  () => {
    if (props.partyReference) {
      fetchBuildingEnergyLabelStatusData();
    } else {
      //this.tableData = {};
    }
  },
  { immediate: true }
);

async function fetchTokenData() {
  isLoading.value = true;
  const response = await getBuildingEnergyLabels(props.token, props.labelSource);
  isLoading.value = false;
  if (response) {
    energyLabels.value = response;
  }
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchBuildingEnergyLabelStatusData() {
  isLoading.value = true;
  const response = await getBuildingEnergyLabelsParty(props.partyReference, props.filterData, props.labelSource);
  isLoading.value = false;

  if (response) {
    energyLabels.value = response;
  } else {
    proxy.$toaster.error("Ophalen van de building EnergyLabels data is mislukt!");
  }
}
</script>

<template>
  <v-layout class="widget-item">
    <slot name="headerwidget"></slot>
    <div class="widget-wrapper widget-chart">
      <loader-spinner v-show="isLoading === true" :is-loading="isLoading" :min-height="170"></loader-spinner>
      <chart-building-energy-labels v-show="isLoading === false" :height="170" :sourceData="energyLabels" :partyType="partyType"> </chart-building-energy-labels>
    </div>
  </v-layout>
</template>
