var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "widget-item" },
    [
      _c("h3", [
        _vm._v("Hoeveel procent voldoet aan "),
        _c("b", [_vm._v("Standaard")]),
      ]),
      _c("loader-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _setup.isLoading === true,
            expression: "isLoading === true",
          },
        ],
        attrs: { "is-loading": _setup.isLoading, "min-height": 170 },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isLoading === false,
              expression: "isLoading === false",
            },
          ],
          staticClass: "widget-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "widget-chart ppm-widget" },
            [
              _c("percentage-chart", {
                attrs: { percentage: _setup.percentage, height: 170 },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }