<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";
import { getBuildingEcmFunctions, severityCode } from "@/services/api/chart.api";

const props = defineProps({
  token: {
    type: String,
  },
});

const buildingFunctions = ref({});
const isLoading = ref(true);

watch(
  () => props.token,
  () => {
    if (props.token) {
      fetchData();
    } else {
      buildingFunctions.value = {};
    }
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  isLoading.value = true;
  const response = await getBuildingEcmFunctions(props.token);
  if (response.severity <= severityCode.warning) {
    buildingFunctions.value = response.data;
  } else {
    proxy.$toaster.error("Ophalen van buildingFunctions is mislukt!");
  }
  isLoading.value = false;
}
</script>

<template>
  <v-layout class="widget-item">
    <h3>Functies van de gebouwen</h3>
    <div class="widget-wrapper widget-chart">
      <loader-spinner v-if="isLoading === true" :is-loading="isLoading" :min-height="170"></loader-spinner>
      <chart-building-functions v-else :height="170" :sourceData="buildingFunctions"> </chart-building-functions>
    </div>
  </v-layout>
</template>
