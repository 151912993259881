<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";
import { getBuildingEnergyLabelSummary } from "@/services/api/chart.api";

const props = defineProps({
  partyReference: String,
  filterData: { type: Array, default: () => [] },
  labelSource: String,
});

const chart = ref({
  tableData: {
    zeroToThreeFour: 0,
    fourToSevenYears: 0,
    sevenYears: 0,
    eightYears: 0,
    nineYears: 0,
    expired: 0,
    noLabel: 0,
    totalNumberOfBuildings: 0,
  },
});
const isLoading = ref(true);

watch(
  () => [props.partyReference, props.filterData],
  () => {
    if (props.partyReference) {
      fetchBuildingEnergyLabelStatusData();
    } else {
      //this.tableData = {};
    }
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchBuildingEnergyLabelStatusData() {
  isLoading.value = true;
  const response = await getBuildingEnergyLabelSummary(props.partyReference, props.filterData, props.labelSource);
  isLoading.value = false;

  if (response) {
    chart.value.tableData = response.data;
  } else {
    proxy.$toaster.error("Ophalen van de buildingEnergyLabelStatus data is mislukt!");
  }
}
</script>

<template>
  <v-layout class="widget-item">
    <h3><slot name="headerwidget"></slot></h3>
    <loader-spinner v-show="isLoading === true" :is-loading="isLoading" :min-height="200"></loader-spinner>
    <div class="widget-wrapper" v-show="isLoading === false">
      <div class="widget-info">
        <table-expiration-date-age :table-data="chart.tableData"></table-expiration-date-age>
      </div>
    </div>
  </v-layout>
</template>
