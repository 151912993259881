import { apiClient, getRequest, postRequest, postRequestBlob, severityCode } from "./httpClient";

const END_POINT = "locationlabel";

const getLocationLabels = async (partyReference, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  console.log("locationlabelapi getLocationLabels", partyReference, currentPage, pageSize, sortBy, sortDesc, filterData);

  return postRequest(`${END_POINT}/${partyReference}/list`, { filterData: filterData }, { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc });
};

const getLocationLabelFilterOptions = async (partyReference, fieldName, text, filterData) => {
  console.log("locationlabelapi getLocationLabelFilterOptions", fieldName, text, filterData);
  return postRequest(`${END_POINT}/${partyReference}/autoComplete/${fieldName}`, { filterData: filterData }, { text: text });
};

const getDocumentIds = async (locRef, type) => {
  const response = await getRequest(`${END_POINT}/${locRef}/documentIds`, { type: type });
  return response.data;
};

const getDocumentFileById = async (documentId) => {
  const response = await apiClient.get(`${END_POINT}/document/${documentId}`);
  return response;
};

const getLabelDocuments = async (partyReference, filterData) => {
  const response = await postRequestBlob(`${END_POINT}/${partyReference}/documents`, { filterData: filterData });
  return response;
};

export { getLocationLabels, getLocationLabelFilterOptions, getDocumentIds, getDocumentFileById, getLabelDocuments, severityCode };
