var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-layout", { staticClass: "widget-item" }, [
    _c("h3", [_vm._v("Hoeveel procent is gasloos")]),
    _c(
      "div",
      { staticClass: "widget-wrapper widget-chart" },
      [
        _c("loader-spinner", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isLoading === true,
              expression: "isLoading === true",
            },
          ],
          attrs: { "is-loading": _setup.isLoading, "min-height": 170 },
        }),
        _c("chart-homes-gasless", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isLoading === false,
              expression: "isLoading === false",
            },
          ],
          attrs: { height: 170, sourceData: _setup.homesGasless },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }