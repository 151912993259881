<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  tableData: {
    type: Object,
  },
});

const headers = [
  { text: "Label", value: "name" },
  { text: "Aantal", value: "value", align: "end" },
];

const expireData = computed(() => {
  return [
    { name: "0 t/m 3 jaar", value: props.tableData.zeroToFourYears },
    { name: "4 t/m 6 jaar", value: props.tableData.fourToSevenYears },
    { name: "7 jaar", value: props.tableData.sevenYears },
    { name: "8 jaar", value: props.tableData.eightYears },
    { name: "9 jaar", value: props.tableData.nineYears },
    { name: "Verlopen", value: props.tableData.expired },
    { name: "Geen label", value: props.tableData.noLabel },
  ];
});
</script>

<template>
  <v-data-table dense :headers="headers" hide-default-footer :items="expireData" disable-sort class="label-table">
    <template v-slot:[`item.value`]="{ item }">
      <span v-if="item.value >= 1">{{ item.value }}</span>
      <span v-else>-</span>
    </template>
  </v-data-table>
</template>
