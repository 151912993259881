var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c("div", _vm._g({ staticClass: "definition-kind" }, on), [
                _c("img", { attrs: { src: _setup.logo } }),
              ]),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v(_vm._s(_setup.props.description))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }