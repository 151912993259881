import { defineStore } from "pinia";

let initData = {
  title: String,
  hostUrl: String,
  portalUrl: String,
  apiUrl: String,
  identityUrl: String,
};

export const getConfiguration = async (hostUrl, portalUrl) => {
  const data = await fetch(`${portalUrl}settings/vue`);
  const config = await data.json();
  const appConfig = {
    title: config.title,
    hostUrl: hostUrl,
    portalUrl: portalUrl,
    apiUrl: config.apiUrl,
    identityUrl: config.identityUrl,
  };
  initData = { ...appConfig };
  return appConfig;
};

export const useAppConfigurationStore = defineStore("appConfiguration", {
  state: () => {
    return { data: initData };
  },
});
