var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "widget-item" },
    [
      _c(
        "header",
        [
          _vm._t("headerwidget"),
          _c(
            "div",
            { staticClass: "widget-actions" },
            [
              _c(
                "router-link",
                {
                  staticClass: "widget-link",
                  attrs: { to: { name: "AppliedOverview" } },
                },
                [_vm._v("Bekijk alle maatregelen")]
              ),
              _c(
                "information-dropdown",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [_vm._t("headerwidget")]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_vm._t("infotext")],
                2
              ),
            ],
            1
          ),
        ],
        2
      ),
      _setup.isLoading === true
        ? _c("loader-spinner", {
            attrs: { "is-loading": _setup.isLoading, "min-height": 200 },
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isLoading === false && _setup.chart.any == true,
              expression: "isLoading === false && chart.any == true",
            },
          ],
        },
        [
          _c("div", { staticClass: "widget-wrapper" }, [
            _c(
              "div",
              { staticClass: "widget-chart measures" },
              [
                _c("chart-measures", {
                  attrs: { "source-data": _setup.chart, height: 150 },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "widget-wrapper" }, [
            _c(
              "div",
              { staticClass: "widget-chart" },
              [
                _c("chart-applied-status", {
                  attrs: {
                    width: 200,
                    height: 200,
                    legendPosition: "legend-right",
                    "source-data": _setup.chart.status,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isLoading === false && _setup.chart.any == false,
              expression: "isLoading === false && chart.any == false",
            },
          ],
        },
        [_vm._t("nomeasurestext")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }