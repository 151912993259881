<script lang="ts" setup>
import { ref, watch } from "vue";

import { getAppliedOverviewReturnOnInvestment } from "@/services/api/chart.api";

const props = defineProps({
  token: {
    type: String,
  },
});

const data = ref({});

const isLoading = ref(true);

watch(
  () => props.token,
  () => {
    if (props.token) {
      fetchData();
    } else {
      data.value = {};
    }
  }
);

async function fetchData() {
  isLoading.value = true;
  const response = await getAppliedOverviewReturnOnInvestment(props.token);
  if (response) {
    data.value = response;
  }
  isLoading.value = false;
}
</script>

<template>
  <v-layout class="widget-item">
    <h3>Terugverdientijden</h3>
    <div class="widget-wrapper widget-chart">
      <loader-spinner v-show="isLoading === true" :is-loading="isLoading" :min-height="170"></loader-spinner>
      <chart-applied-return-on-investment v-show="isLoading === false" :height="170" :sourceData="data"> </chart-applied-return-on-investment>
    </div>
  </v-layout>
</template>
