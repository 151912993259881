var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "filter-item" },
    [
      _c(
        "div",
        {
          staticClass: "btn-filter",
          class: { active: _setup.expanded || _setup.active },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _setup.toggle.apply(null, arguments)
            },
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.name) + " "),
          _setup.active
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-5",
                  attrs: { icon: "", "x-small": "" },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _setup.reset()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(" mdi-close "),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-container",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.expanded === true,
              expression: "expanded === true",
            },
          ],
          staticClass: "filter-dropdown",
        },
        [
          _c("header", [
            _c("h3", [_vm._v("Filteren op " + _vm._s(_setup.nameLowerCase))]),
          ]),
          _setup.props.type == "search" || _setup.props.type == "list"
            ? [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "2" } },
                      [
                        _setup.canCheckAll
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "btn-flex",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _setup.selectAll.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm.searchOptions.length > 0
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          dark: "",
                                          title: "Alles aanvinken",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "mdi-checkbox-multiple-marked-outline"
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "v-icon",
                                      {
                                        attrs: { title: "Ophalen keuzelijst" },
                                      },
                                      [_vm._v("mdi-sort-alphabetical-variant")]
                                    ),
                              ],
                              1
                            )
                          : _c(
                              "v-btn",
                              {
                                staticClass: "btn-flex",
                                attrs: { title: "Selectie wissen" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _setup.deselectAll.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("v-icon", [
                                  _vm._v("mdi-checkbox-multiple-blank-outline"),
                                ]),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                    _c("v-col", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _setup.query,
                            expression: "query",
                          },
                        ],
                        staticClass: "filter-search",
                        attrs: {
                          type: "search",
                          placeholder: "Filter op " + _setup.nameLowerCase,
                        },
                        domProps: { value: _setup.query },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _setup.query = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c(
                      "v-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-flex",
                            attrs: { slot: "append" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _setup.clearSearch.apply(null, arguments)
                              },
                            },
                            slot: "append",
                          },
                          [
                            _c("v-icon", { attrs: { title: "Wis zoekveld" } }, [
                              _vm._v("mdi-close"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _setup.props.searchStatus === _setup.FilterStatus.TooMany &&
                _setup.query !== undefined
                  ? _c(
                      "v-row",
                      [
                        _c("v-col", [
                          _vm._v(" Er zijn teveel resultaten"),
                          _c("br"),
                          _vm._v("Maak de filter specifieker "),
                        ]),
                      ],
                      1
                    )
                  : _setup.props.searchStatus === _setup.FilterStatus.Empty
                  ? _c(
                      "v-row",
                      [
                        _c("v-col", [
                          _vm._v(" Er zijn geen resultaten"),
                          _c("br"),
                          _vm._v("Maak de filter minder specifiek "),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _setup.filteredSearchOptions.length > 0
                  ? _c(
                      "div",
                      { staticClass: "list-overview" },
                      [
                        _c(
                          "perfect-scrollbar",
                          _vm._l(
                            _setup.filteredSearchOptions,
                            function (option) {
                              return _c(
                                "div",
                                {
                                  key: `searchOption-${option.text}`,
                                  staticClass: "form-check",
                                },
                                [
                                  _c("input", {
                                    staticClass: "form-check-input",
                                    attrs: {
                                      type: "checkbox",
                                      id: `checkedOption-${_setup.nameLowerCase}-${option.id}`,
                                    },
                                    domProps: {
                                      checked: _setup.isChecked(option),
                                      value: option.id,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _setup.toggleSelect(option)
                                      },
                                    },
                                  }),
                                  _c("span", { staticClass: "checkmark" }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-check-label",
                                      attrs: {
                                        for: `checkedOption-${_setup.nameLowerCase}-${option.id}`,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(option.text) + " ")]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _setup.remainingCheckedOptions.length > 0
                  ? _c(
                      "div",
                      { staticClass: "list-overview" },
                      [
                        _vm.searchOptions.length > 0
                          ? _c("hr", { staticClass: "white mb-5" })
                          : _vm._e(),
                        _c(
                          "perfect-scrollbar",
                          _vm._l(
                            _setup.remainingCheckedOptions,
                            function (option) {
                              return _c(
                                "div",
                                {
                                  key: `checkedOption-${_setup.nameLowerCase}-${option.id}`,
                                  staticClass: "form-check",
                                },
                                [
                                  _c("input", {
                                    staticClass: "form-check-input",
                                    attrs: {
                                      type: "checkbox",
                                      id: `checkedOption-${_setup.nameLowerCase}-${option.id}`,
                                    },
                                    domProps: {
                                      checked: _setup.isChecked(option),
                                      value: option.id,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _setup.removeFilterOption(option)
                                      },
                                    },
                                  }),
                                  _c("span", { staticClass: "checkmark" }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-check-label",
                                      attrs: {
                                        for: `checkedOption-${_setup.nameLowerCase}-${option.id}`,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(option.text) + " ")]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _setup.props.type == "range"
            ? [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "6" } }, [
                      _c(
                        "label",
                        { attrs: { for: `filter-${_vm.name}-minimum` } },
                        [_vm._v("minimum")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _setup.min,
                            expression: "min",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "filter-search",
                        attrs: {
                          type: "number",
                          min: "0",
                          for: `filter-${_vm.name}-minimum`,
                        },
                        domProps: { value: _setup.min },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _setup.min = _vm._n($event.target.value)
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _c("v-col", { attrs: { cols: "6" } }, [
                      _c(
                        "label",
                        { attrs: { for: `filter-${_vm.name}-maximum` } },
                        [_vm._v("maximum")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _setup.max,
                            expression: "max",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "filter-search",
                        attrs: {
                          type: "number",
                          min: "0",
                          for: `filter-${_vm.name}-maximum`,
                        },
                        domProps: { value: _setup.max },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _setup.max = _vm._n($event.target.value)
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "footer",
            [
              _c(
                "v-btn",
                {
                  attrs: { light: "", title: "Filter opslaan" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _setup.save()
                    },
                  },
                },
                [_vm._v("Opslaan")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { light: "", title: "Filter wissen" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _setup.reset()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-delete")])],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }