var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "widget-item" },
    [
      _c("header", [
        _c("h2", [_vm._v("Overzicht woningen op gas")]),
        _c(
          "div",
          { staticClass: "widget-actions" },
          [
            _c(
              "information-dropdown",
              {
                attrs: { id: "overzicht-panden" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [_c("h2", [_vm._v("Overzicht woningen op gas")])]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("p", [
                  _vm._v(
                    " De Rijksoverheid wil de CO2-uitstoot verminderen door over te stappen op duurzame energie. Daarom kunt u vanaf 2050 in principe niet meer koken op gas of stoken op aardgas. Dan kan dit alleen nog met duurzame installaties. "
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("loader-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _setup.isLoading === true,
            expression: "isLoading === true",
          },
        ],
        attrs: { "is-loading": _setup.isLoading },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isLoading === false,
              expression: "isLoading === false",
            },
          ],
          staticClass: "widget-wrapper",
          attrs: { "min-height": 200 },
        },
        [
          _c(
            "div",
            { staticClass: "widget-chart" },
            [
              _c("chart-homes-gasless", {
                attrs: {
                  height: 200,
                  width: 200,
                  legendPosition: "legend-bottom",
                  "source-data": _setup.chart.homesGasless,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }