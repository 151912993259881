var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-row",
        { staticClass: "vue-chart vue-chart-investment-vs-savings" },
        [
          _c("v-col", { staticClass: "vue-chart-legend" }, [
            _c("span", { domProps: { innerHTML: _vm._s(_setup.htmlLegend) } }),
          ]),
          _c(
            "v-col",
            { staticClass: "vue-chart-wrapper", attrs: { cols: "auto" } },
            [
              _setup.initialDataLoaded
                ? _c("advanced-line-chart", {
                    attrs: {
                      "chart-data": _setup.chartdata,
                      options: _setup.options,
                    },
                    on: { "chart-rendered": _setup.handleChartRendered },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }