var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "inner", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c(
              "div",
              { staticClass: "overview-filters align-items-start" },
              [
                _c("filter-set", {
                  attrs: {
                    name: _setup.FilterTokenName.MeasureSelection,
                    filters: _setup.filters,
                  },
                  on: {
                    "filter-search": _setup.handleFilterSearch,
                    "filters-changed27": _setup.handleFiltersChanged,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(_setup.MeasureGrid, {
        attrs: {
          filterData: _setup.filterData,
          ecmFunctionCode: _vm.ecmFunctionCode,
          certified: _vm.certified,
        },
        on: { "single-select-measure": _setup.selectMeasure },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }