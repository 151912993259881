var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "perfect-scrollbar",
    [
      _c("h2", [_vm._v("Paris Proof Score")]),
      _c(
        "information-dropdown",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("h2", [_vm._v("Paris Proof?")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("p", [
            _vm._v(
              "U ziet hier uw gewogen gemiddelde Paris Proof Score op basis van de WEii methodiek van al uw gebouwen."
            ),
          ]),
          _c("p", [
            _vm._v(
              " WEii staat voor Werkelijke Energie intensiteit indicator en is een gestandaardiseerde methodiek voor het bepalen van een energie-efficiëntie indicator op basis van het werkelijke energiegebruik van een gebouw. "
            ),
          ]),
          _c("p", [
            _vm._v(
              " In tegenstelling tot NTA 8800 (BENG) is WEii gebaseerd op het werkelijke energiegebruik van een gebouw in gebruik en niet op een berekend gebouwgebonden energiegebruik. Er zijn verschillende gebouwtype en klassen ontwikkeld, daarbij is de klasse Paris Proof een gebouw dat voldoet aan de doelstellingen voor 2050. "
            ),
          ]),
          _c("p", [
            _vm._v(
              "De Paris Proof Score is berekend op basis van het WEii protocol versie 2.0."
            ),
          ]),
          _c("p", [
            _vm._v(" Klik "),
            _c(
              "a",
              {
                staticClass: "nav-link-light",
                attrs: { href: "https://www.weii.nl", target: "_blank" },
              },
              [_c("b", [_vm._v("hier")])]
            ),
            _vm._v(" voor een overzicht van de normering. "),
          ]),
        ]
      ),
      _setup.isLoaded &&
      _setup.ppmSummary.count != 0 &&
      _setup.ppmSummary.count != _setup.ppmSummary.total
        ? _c("h5", [
            _vm._v(
              "Gebaseerd op " +
                _vm._s(_setup.ppmSummary.count) +
                " van de " +
                _vm._s(_setup.ppmSummary.total) +
                " gebouwen"
            ),
          ])
        : _vm._e(),
      _setup.isLoaded &&
      _setup.ppmSummary.count != 0 &&
      _setup.ppmSummary.count == _setup.ppmSummary.total
        ? _c("h5", [_vm._v("Gebaseerd op alle gebouwen")])
        : _vm._e(),
      _setup.isLoading === true
        ? _c(
            "v-container",
            [
              _c("loader-spinner", {
                attrs: { "is-loading": _setup.isLoading },
              }),
            ],
            1
          )
        : _c("v-container", [
            _setup.isLoaded && _setup.ppmSummary.count == 0
              ? _c("p", [
                  _vm._v(
                    "Er zijn nog geen panden waar de Paris Proof score voor berekend kan worden, doordat er energiedata en/of oppervlaktes missen"
                  ),
                ])
              : _c("p", { staticClass: "paris-score" }, [
                  _setup.hasData
                    ? _c(
                        "span",
                        {
                          class: _setup.getParisProofColorClass(
                            _setup.ppmSummary.meanScore,
                            _setup.ppmSummary.meanNorm
                          ),
                        },
                        [
                          _vm._v(" Gemiddelde score: "),
                          _c("number", {
                            attrs: {
                              from: 0,
                              to: _setup.ppmSummary.meanScore,
                              duration: 1.6,
                            },
                          }),
                          _vm._v(" kWh/m²"),
                          _c("br"),
                          _vm._v(
                            " Gemiddelde norm: " +
                              _vm._s(_setup.ppmSummary.meanNorm) +
                              " kWh/m² "
                          ),
                        ],
                        1
                      )
                    : _c(
                        "span",
                        {
                          class: _setup.getParisProofColorClass(
                            _setup.ppmSummary.meanScore,
                            _setup.ppmSummary.meanNorm
                          ),
                        },
                        [
                          _vm._v(" Gemiddelde score: -"),
                          _c("br"),
                          _vm._v(" Gemiddelde norm: - "),
                        ]
                      ),
                ]),
          ]),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "12", sm: "6" } }, [
                _c(
                  "div",
                  { staticClass: "paris-proof-score" },
                  [
                    _c("eiffel-tower", {
                      attrs: {
                        score: _setup.ppmSummary.meanScore,
                        norm: _setup.ppmSummary.meanNorm,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("v-col", { attrs: { md: "12", sm: "6" } }, [
                _c("div", { staticClass: "paris-proof-legend" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "color color-red" }),
                    _vm._v(" Onvoldoende score"),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "color color-orange" }),
                    _vm._v(" Matige score"),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "color color-green" }),
                    _vm._v(" Onder of gelijk aan de norm"),
                  ]),
                ]),
                _c("div", { staticClass: "paris-proof-about" }, [
                  _c("h2", [_vm._v("Over Paris Proof")]),
                  _c("p", [
                    _c("strong", [_vm._v("Paris Proof")]),
                    _vm._v(
                      " is gebaseerd op de door Dutch Green Building Council opgestelde doelwaarden voor het realiseren van de 2050-doelstelling van het akkoord van Parijs. Uitgangspunt bij de getalswaarde van de Paris Proof doelstelling per gebouwtype is dat met de in 2050 verwachte beschikbare hoeveelheid duurzame energie alle gebouwen van energie kunnen worden voorzien als de gebouwen een energiegebruik hebben dat maximaal gelijk is aan de Paris Proof getalswaarden. Daarmee zou voldaan worden aan de doelselling van het akkoord van Parijs en het Nederlandse klimaatakkoord. "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }