var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "widget-item" },
    [
      _c("header", [
        _c("h2", [_vm._v("Verduurzamingsradar")]),
        _c(
          "div",
          { staticClass: "widget-actions" },
          [
            _c(
              "information-dropdown",
              {
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [_c("h2", [_vm._v("Verduurzamingsradar")])]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("p", [
                  _vm._v(
                    " De Verduurzamingsradar is een bedrijfskundig model. Het doel hiervan is om u én onszelf bewust te maken van de status van de energietransitie binnen uw organisatie, gerelateerd aan de Nederlandse klimaatdoelen. Op basis van dit bewustzijn kan worden bepaald of professionalisering is gewenst en hoe dit bewerkstelligd zou kunnen worden. "
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _setup.numbers != undefined
        ? _c(
            "v-container",
            {
              staticClass: "vue-chart vue-sustainability-radar inline-text",
              attrs: { "pa-0": "" },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "vue-chart-wrapper",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c("simple-radar-chart", {
                        attrs: { sourceData: _setup.numbers },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-container",
            {
              staticClass: "vue-chart vue-sustainability-radar inline-text",
              attrs: { "pa-0": "" },
            },
            [_c("p", [_vm._v("Geen gegevens beschikbaar voor deze partij")])]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }