var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "widget-item" },
    [
      _c("header", [
        _c("h2", [_vm._v("Volledigheid data")]),
        _c(
          "div",
          { staticClass: "widget-actions" },
          [
            _c(
              "information-dropdown",
              {
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [_c("h2", [_vm._v("Goed op weg!")])]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("p", [
                  _vm._v(
                    " Betrouwbare duurzaamheidsdata helpt om onderbouwde beslissingen te nemen in de verduurzamingsstrategie. INNAX begint daarom met het in kaart brengen van de beschikbare data en het bepalen van de kwaliteit hiervan. Belangrijke aspecten daarbij zijn: "
                  ),
                ]),
                _c("ul", [
                  _c("li", [_vm._v("Volledigheid")]),
                  _c("li", [_vm._v("Juistheid")]),
                  _c("li", [_vm._v("Actualiteit")]),
                ]),
                _c("br"),
                _c("p", [
                  _vm._v(
                    "De Paris Proof Monitor toont hier een aantal verbeterslagen voor uw portefeuille waar deze nog geen volledige data dekking/coverage heeft."
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("loader-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _setup.isLoading === true,
            expression: "isLoading === true",
          },
        ],
        attrs: { "is-loading": _setup.isLoading },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isLoading === false,
              expression: "isLoading === false",
            },
          ],
          staticClass: "widget-wrapper",
          attrs: { "min-height": 200 },
        },
        [
          _c(
            "div",
            { staticClass: "widget-chart data-availability" },
            [
              _c("percentage-chart", {
                attrs: {
                  percentage: _setup.chart.percent,
                  height: 200,
                  width: 200,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _setup.hasDataAvailabilityMessages,
                  expression: "hasDataAvailabilityMessages",
                },
              ],
              staticClass: "widget-info",
            },
            [
              _c("h3", [_vm._v("Dit missen we nog")]),
              _c("span", [_vm._v("Voor één of meerdere panden geldt")]),
              _c(
                "ul",
                _vm._l(_setup.chart.messages, function (msg, index) {
                  return _c("li", { key: index }, [
                    _vm._v(" " + _vm._s(_setup.msgDataScore(msg)) + " "),
                  ])
                }),
                0
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }