import { apiClient } from "./httpClient";

const END_POINT_ACCESS = "userAccess";

const getProfile = async () => {
  //console.log("user.api, getProfile: ");
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT_ACCESS}/profile`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const setCurrentUser = async () => {
  console.log("***************user.api, setCurrentUser");
  const response = await apiClient.put(`${END_POINT_ACCESS}/current`);
  return response;
};

const setCurrentProject = async (partyReference) => {
  console.log("user.api, setCurrentProject, partyReference: ", partyReference);
  const response = await apiClient.put(`${END_POINT_ACCESS}/project/${partyReference}`);
  console.log("user.api, setCurrentProject, response: ", response);

  return response;
};

export { getProfile, setCurrentUser, setCurrentProject };
