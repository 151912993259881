var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "login", attrs: { "flex-column": "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "2", sm: "4" } }),
          _c("v-col", { attrs: { md: "3", sm: "6" } }, [
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "div",
              { staticClass: "logo" },
              [
                _c("v-img", {
                  staticClass: "image",
                  attrs: {
                    alt: "INNAX Logo",
                    src: require("@/assets/img/logo.png"),
                  },
                }),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("br"),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "2", sm: "4" } }),
          _c("v-col", { attrs: { md: "6", sm: "12" } }, [
            _c("h1", [
              _vm._v(
                "U bent geauthoriseerd voor de Paris Proof Monitor maar u bent nog niet gekoppeld aan een project of de geldigheid voor het project is verlopen."
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "2", sm: "4" } }),
          _c("v-col", { attrs: { md: "6", sm: "12" } }, [
            _c("h2", [
              _vm._v(
                "Neem contact op met de administrator of beheerder van uw project(en)"
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "2", sm: "4" } }),
          _c(
            "v-col",
            { attrs: { md: "6", sm: "12" } },
            [
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _setup.logout } },
                [_vm._v("Afmelden")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }