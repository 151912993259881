<script lang="ts" setup>
import { onMounted } from "vue";

import { useUserStore } from "@/stores/user";
import DashboardUtility from "@/views/project/DashboardUtility.vue";
import DashboardHomes from "@/views/project/DashboardHomes.vue";
import DashboardLabels from "@/views/project/DashboardLabels.vue";

const userStore = useUserStore();

onMounted(() => {
  console.log("DashboardBase: ", userStore.currentPartyType, userStore.currentProjectName, userStore.currentPartyReference);
});
</script>

<template>
  <dashboard-homes v-if="userStore.isPartyHome"></dashboard-homes>
  <dashboard-labels v-else-if="userStore.isPartyEnergyLabels"></dashboard-labels>
  <dashboard-utility v-else-if="userStore.isPartyUtility"></dashboard-utility>
</template>
