var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "vue-chart vue-percentage-chart inline-text",
      attrs: { "pa-0": "" },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "vue-chart-wrapper", attrs: { cols: "auto" } },
            [
              _c("simple-doughnut-chart", {
                attrs: {
                  percentage: _setup.props.percentage,
                  color: _setup.props.color,
                  styles: _setup.chartStyles,
                },
              }),
              _c(
                "div",
                { staticClass: "textual-percentage" },
                [
                  _c("number", {
                    attrs: { to: _setup.props.percentage, duration: 1.6 },
                  }),
                  _vm._v("%"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }