<script lang="ts" setup>
import { computed, watch, getCurrentInstance } from "vue";
import { getDefinitions } from "@/services/api/measure.api";
import GridPagination from "@/components/general/GridPagination.vue";
import useGrid27 from "@/composables/grid27.js";

const emits = defineEmits(["single-select-measure"]);
const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  filterData: { type: Array, default: () => [] },
  paoId: { type: String, default: "" },
  ecmFunctionCode: { type: String, default: "" },
  certified: { type: Boolean, default: true },
});

const _headers = [
  { text: "Code", value: "code" },
  { text: "Naam", value: "name" },
  { text: "Omschrijving", value: "descr" },
  { text: "", value: "controls", sortable: false },
];
const headers = computed(() => _headers);

watch(
  () => props.filterData,
  () => {
    fetchFirstPage();
  },
  { immediate: true, deep: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  console.log("%cMeasureGrid, getData", "background: purple; color: white", props.ecmFunctionCode, props.certified, props.filterData);
  const pageNumber0 = pageNumber - 1;
  const response = await getDefinitions(props.ecmFunctionCode, props.certified, pageNumber0, pageSize, sortBy, sortDesc, props.filterData);
  return response;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  console.log("MeasureGrid fetchError", value);
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}

async function select(payload) {
  emits("single-select-measure", payload);
}
</script>

<template>
  <div>
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
      class="overview-table"
      @update:options="changedOptions"
    >
      <template v-slot:[`item.controls`]="{ item }">
        <v-btn v-if="certified == false" dark class="primary" title="Kiezen" @click="select(item)">
          <v-icon dark left>mdi-checkbox-marked-circle-outline</v-icon>
          Kiezen
        </v-btn>
      </template>
    </v-data-table>
    <grid-pagination v-if="totalItems > 5" :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
