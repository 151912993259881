var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "inner", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c(
              "div",
              { staticClass: "overview-filters align-items-start" },
              [
                _c("filter-set", {
                  attrs: {
                    name: _setup.FilterTokenName.AppliedLogging,
                    filters: _setup.filters,
                  },
                  on: {
                    "filter-search": _setup.handleFilterSearch,
                    "filters-changed27": _setup.handleFiltersChanged,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("v-spacer"),
        ],
        1
      ),
      _c(_setup.AppliedLoggingOverviewGrid, {
        attrs: {
          preFilter: _setup.preFilter,
          token: _setup.token,
          showAddress: _setup.showAddress,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }