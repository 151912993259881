var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "perfect-scrollbar",
    [
      _c("h2", [_vm._v("Paris Proof Score")]),
      _c(
        "information-dropdown",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("h2", [_vm._v("Paris Proof?")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("p", [
            _vm._v(
              "U ziet hier de Paris Proof Score op basis van de WEii methodiek van uw gebouw."
            ),
          ]),
          _c("p", [
            _vm._v(
              "De Paris Proof Score is berekend op basis van het WEii protocol versie 2.0."
            ),
          ]),
          _c("h4", [_vm._v("Waar komt de Paris Proof norm vandaan?")]),
          _c("p", [
            _vm._v(" Klik "),
            _c(
              "a",
              {
                staticClass: "nav-link-light",
                attrs: { href: "https://www.weii.nl", target: "_blank" },
              },
              [_c("b", [_vm._v("hier")])]
            ),
            _vm._v(" voor een uitgebreide uitleg. "),
          ]),
        ]
      ),
      _setup.showWarning
        ? _c(
            "warning-dropdown",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [_c("h2", [_vm._v("Ontbrekende data")])]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                297063070
              ),
            },
            [
              _c("p", [_vm._v(_vm._s(_setup.warningTextScore))]),
              _c("p", [_vm._v(_vm._s(_setup.warningTextNorm))]),
            ]
          )
        : _vm._e(),
      _c("v-container", [
        _c("p", { staticClass: "paris-score" }, [
          _setup.isLoading
            ? _c(
                "span",
                [_c("loader-spinner", { attrs: { "is-loading": true } })],
                1
              )
            : _c("span", [
                _setup.score
                  ? _c(
                      "span",
                      {
                        class: _setup.getParisProofColorClass(
                          _setup.score,
                          _setup.norm
                        ),
                      },
                      [
                        _vm._v(" Score: "),
                        _c("number", {
                          attrs: { from: 0, to: _setup.score, duration: 1.6 },
                        }),
                        _vm._v(" kWh/m²"),
                        _c("br"),
                      ],
                      1
                    )
                  : _c("span", [_vm._v(" Score: -"), _c("br")]),
                _setup.norm
                  ? _c(
                      "span",
                      {
                        class: _setup.getParisProofColorClass(
                          _setup.score,
                          _setup.norm
                        ),
                      },
                      [_vm._v(" Norm: " + _vm._s(_setup.norm) + " kWh/m² ")]
                    )
                  : _c("span", [_vm._v(" Norm: -"), _c("br")]),
              ]),
        ]),
      ]),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "12", sm: "6" } }, [
                _c(
                  "div",
                  { staticClass: "paris-proof-score" },
                  [
                    _c("eiffel-tower", {
                      attrs: { score: _setup.score, norm: _setup.norm },
                    }),
                  ],
                  1
                ),
              ]),
              _c("v-col", { attrs: { md: "12", sm: "6" } }, [
                _c("div", { staticClass: "paris-proof-legend" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "color color-red" }),
                    _vm._v(" Onvoldoende score"),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "color color-orange" }),
                    _vm._v(" Matige score"),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "color color-green" }),
                    _vm._v(" Onder of gelijk aan de norm"),
                  ]),
                ]),
                _c("div", { staticClass: "paris-proof-about" }, [
                  _c("h2", [_vm._v("Over Paris Proof")]),
                  _c("p", [
                    _c("strong", [_vm._v("Paris Proof")]),
                    _vm._v(
                      " is gebaseerd op de door Dutch Green Building Council opgestelde doelwaarden voor het realiseren van de 2050-doelstelling van het akkoord van Parijs. Uitgangspunt bij de getalswaarde van de Paris Proof doelstelling per gebouwtype is dat met de in 2050 verwachte beschikbare hoeveelheid duurzame energie alle gebouwen van energie kunnen worden voorzien als de gebouwen een energiegebruik hebben dat maximaal gelijk is aan de Paris Proof getalswaarden. Daarmee zou voldaan worden aan de doelselling van het akkoord van Parijs en het Nederlandse klimaatakkoord. "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }