var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "vue-chart vue-chart-building-functions",
      class: _vm.legendPosition,
      attrs: { "pa-0": "" },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "vue-chart-wrapper", attrs: { cols: "auto" } },
            [
              _c("advanced-doughnut-chart", {
                attrs: {
                  "data-set": _setup.chart,
                  options: _setup.options,
                  styles: _setup.chartStyles,
                },
                on: { "chart-rendered": _setup.handleChartRendered },
              }),
            ],
            1
          ),
          _c("v-col", { staticClass: "vue-chart-legend" }, [
            _c("span", { domProps: { innerHTML: _vm._s(_setup.htmlLegend) } }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }