//import Vue from "vue";
import VueRouter from "vue-router";
import AccountWelcome from "@/views/AccountWelcome";
import AppBase from "@/views/AppBase";
import DashboardBase from "@/views/project/DashboardBase";
import NoProject from "@/views/basic/NoProject";
import LoginUnauthorized from "@/views/basic/LoginUnauthorized";
import LoginCallback from "@/views/basic/LoginCallback";
import DemoLogin from "@/views/basic/DemoLogin";
import { Permission, PartyType } from "@/stores/user";
import { useUserStore } from "@/stores/user";
import { useAuthStore } from "@/stores/auth";

const routes = [
  {
    path: "/",
    name: "Base",
    redirect: "/dashboard",
    component: AppBase,
    meta: {},
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardBase,
        meta: {
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          breadCrumb: { text: "Dashboard", to: null },
        },
      },
      {
        path: "/buildingOverview",
        name: "BuildingOverview",
        component: () => import("@/views/project/BuildingOverview.vue"),
        meta: {
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          allowedFor: [PartyType.None, PartyType.Utility],
          breadCrumb: { text: "Gebouwen", to: "BuildingOverview" },
        },
      },
      {
        path: "/buildings/addcertified",
        name: "BuildingsAddCertified",
        component: () => import("@/views/project/BuildingsAddCertified.vue"),
        props: true,
        meta: {
          afterSwitchProjectGoTo: "Dashboard",
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          breadCrumb: { text: "Erkende maatregelen toevoegen", to: "BuildingsAddCertified" },
        },
      },
      {
        path: "/complexes",
        name: "ComplexOverview",
        component: () => import("@/views/project/ComplexOverview.vue"),
        meta: {
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
        },
      },
      {
        path: "/complexes/addstartingpoint",
          name: "ComplexesAddStartingPoint",
        component: () => import("@/views/project/ComplexesAddStartingPoint.vue"),
        props: true,
        meta: {
          afterSwitchProjectGoTo: "Dashboard",
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          allowedFor: [PartyType.Homes],
        },
      },
      {
        path: "/buildingDetails/:addressId",
        name: "BuildingDetails",
        component: () => import("@/views/project/BuildingDetails.vue"),
        props: true,
        meta: {
          afterSwitchProjectGoTo: "Dashboard",
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          breadCrumb: { text: "Gebouw details", to: "BuildingDetails" },
        },
      },
      {
        path: "/complexDetails/:addressId",
        name: "ComplexDetails",
        component: () => import("@/views/project/ComplexDetails.vue"),
        props: true,
        meta: {
          afterSwitchProjectGoTo: "Dashboard",
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          breadCrumb: { text: "Complex details", to: "ComplexDetails" },
        },
      },
      {
        path: "/measureOverview",
        name: "AppliedOverview",
        component: () => import("@/views/project/AppliedOverview.vue"),
        meta: {
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          allowedFor: [PartyType.None, PartyType.Utility, PartyType.Homes],
          breadCrumb: { text: "Maatregelen", to: "AppliedOverview" },
        },
      },
      {
        path: "/measures/applied/mutation",
        name: "AppliedMutation",
        component: () => import("@/views/project/AppliedMutation.vue"),
        props: true,
        meta: {
          afterSwitchProjectGoTo: "AppliedOverview",
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          allowedFor: [PartyType.None, PartyType.Utility, PartyType.Homes],
          breadCrumb: { text: "Maatregel wijzigen", to: "AppliedMutation" },
        },
      },
      {
        path: "/measures/applied/:appliedId",
        name: "AppliedDetails",
        component: () => import("@/views/project/AppliedDetails.vue"),
        props: true,
        meta: {
          afterSwitchProjectGoTo: "AppliedOverview",
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          allowedFor: [PartyType.None, PartyType.Utility, PartyType.Homes],
          breadCrumb: { text: "Maatregel details", to: "AppliedDetails" },
        },
      },
      {
        path: "/measures/applied/new/:addressId/:definitionId",
        name: "AppliedDetailsNew",
        component: () => import("@/views/project/AppliedDetails.vue"),
        props: true,
        meta: {
          afterSwitchProjectGoTo: "AppliedOverview",
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          allowedFor: [PartyType.None, PartyType.Utility, PartyType.Homes],
          breadCrumb: { text: "Maatregel toevoegen", to: "AppliedDetailsNew" },
        },
      },
      {
        path: "esnobligation",
        name: "ProjectCreateBulkUploadXML",
        component: () => import("@/views/admin/ProjectCreateBulkUploadXML.vue"),
        props: true,
        meta: {
          authorize: [Permission.Manager],
          allowedFor: [PartyType.None, PartyType.Utility],
          breadCrumb: { text: "Informatieplicht", to: "ProjectCreateBulkUploadXML" },
        },
      },
      {
        path: "/roadmap",
        name: "RoadMapUtility",
        component: () => import("@/views/project/RoadMapUtility.vue"),
        meta: {
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          allowedFor: [PartyType.None, PartyType.Utility],
          breadCrumb: { text: "Routekaart", to: "RoadMapUtility" },
        },
      },
      {
        path: "/roadmapComplexes",
        name: "RoadMapHome",
        component: () => import("@/views/project/RoadMapHome.vue"),
        meta: {
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          allowedFor: [PartyType.None, PartyType.Homes],
          breadCrumb: { text: "Routekaart Complexen", to: "RoadMapHome" },
        },
      },
      {
        path: "/powerbi/embed",
        name: "Rapportage",
        component: () => import("@/views/project/PowerBISelector.vue"),
        meta: {
          authorize: [Permission.Manager, Permission.User, Permission.Guest],
          allowedFor: [PartyType.None, PartyType.Utility, PartyType.Homes],
          breadCrumb: { text: "Rapporten", to: "Rapportage" },
        },
      },
    ],
  },
  {
    path: "/account",
    name: "Account",
    component: AccountWelcome,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      breadCrumb: { text: "Account", to: "Account" },
    },
  },
  {
    path: "/demo",
    name: "DemoUtility",
    component: DemoLogin,
    props: { type: PartyType.Utility },
    meta: {
      public: true,
      breadCrumb: { text: "Demo Utiliteiten", to: "DemoUtility" },
    },
  },
  {
    path: "/demowoningen",
    name: "DemoHomes",
    component: DemoLogin,
    props: { type: PartyType.Homes },
    meta: {
      public: true,
      breadCrumb: { text: "Demo Woningen", to: "DemoHomes" },
    },
  },
  {
    path: "/callback",
    name: "Callback",
    component: LoginCallback,
    meta: {
      public: true,
    },
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: LoginUnauthorized,
  },
  {
    path: "/noproject",
    name: "NoProject",
    component: NoProject,
  },
  {
    path: "/info",
    name: "Info",
    component: () => import("@/views/basic/ApplicationInfo.vue"),
    meta: {
      public: true,
      breadCrumb: { text: "Informatie", to: "Info" },
    },
  },
  {
    path: "/switchproject/:partyReference",
    name: "SwitchProject",
    meta: {
      authorize: [Permission.Manager, Permission.User, Permission.Guest],
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history", // haalt # weg uit url
});

router.beforeEach(async (to, from, next) => {
  // console.log("beforeEach to  ", to);
  // console.log("beforeEach from", from);
  // console.log("beforeEach to.meta.breadCrumb", to.meta.breadCrumb);
  // console.log("beforeEach next", next);
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);
  const authStore = useAuthStore();
  const userStore = useUserStore();

  if (to.meta.breadCrumb) {
    const hoofd = ['Dashboard', 'BuildingOverview', 'ComplexOverview', 'AppliedOverview', 'RoadMapUtility', 'RoadMapHome', 'Rapportage', 'ProjectCreateBulkUploadXML'];
    if (hoofd.includes(to.name)) {
      userStore.resetBreadcrumb(to);
    } else {
      userStore.pushBreadcrumb(to);
    }  
  }

  const loggedIn = await authStore.isLoggedIn();
  const { authorize } = to.meta;

  if (!isPublic && !loggedIn) {
    if (to.name == "Dashboard") {
      //console.log("beforeEach next => account");
      return next({ name: "Account" });
    } else {
      //console.log("beforeEach next => account redirect");
      return next({
        name: "Account",
        query: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
      });
    }
  } else {
    console.log("beforeEach authorize: ", authorize);
    if (authorize) {
      if (!loggedIn) {
        // not logged in so redirect to login page with the return url
        return next({ name: "Account", query: { returnUrl: to.path } });
      }
      const userStore = useUserStore();
      // check if route is restricted by role
      const authorised = await userStore.isAuthorized(authorize);

      if (authorize.length && !authorised) {
        // Indien men voor een pagina niet geautoriseerd is wordt geredirect naar de Dashboardpagina.
        // Als men ook daar niet voor geautoriseerd is wordt pas geredirect naar /unauthorized.
        if (to.name == "Dashboard") {
          router.app.$toaster.error("U bent niet geautoriseerd voor deze pagina.");
          return next({ name: "Unauthorized" });
        } else {
          return next({ name: "Dashboard" });
        }
      } else {
        if (to.name == "SwitchProject" && userStore.isPartyAllowed(to.params.partyReference)) {
          console.log("%cbeforeEach switchProject", "background: lightgreen; color: black", to.params.partyReference);
          await userStore.setParty(to.params.partyReference);
          return next({ name: "Dashboard" });
        }
      }
    }
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next({ name: "Dashboard" });
  }

  // in AppliedMutation mag je alleen maar komen via een button click, geen direct link
  if (to.name == "AppliedMutation") {
    if (!to.params.allow) {
      return next({ name: "AppliedOverview" });
    }
  }

  document.title = router.app.$appConfig.title;

  next();
});

export default router;
