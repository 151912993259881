<script lang="ts" setup>
import { onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import router from "@/router/index";

onMounted(async () => {
  console.log("Callback, begin ");
  const authStore = useAuthStore();
  const userStore = useUserStore();
  try {
    await authStore.signinRedirectCallback();
    const authorized = await authStore.isAllowedForApplication();
    console.log("Callback, authorized: ", authorized);
    if (authorized) {
      console.log("Callback, user is authorized");
      await userStore.setUserAccessData();
      router.push({ name: "Dashboard" }).catch(() => {});
    } else {
      console.log("Callback, user unauthorized for application");
      router.push({ name: "Unauthorized" });
    }
  } catch (error) {
    console.log("Callback, error: ", error);
    router.push({ name: "Unauthorized" });
  }
});
</script>

<template>
  <v-container fill-height class="callback">
    <v-row>
      <v-col>
        <div>Aanmelding voltooien...</div>
      </v-col>
    </v-row>
  </v-container>
</template>
