<script lang="ts" setup>
import { ref } from "vue";
import AppliedLoggingOverview from "@/components/app/measures/AppliedLoggingOverview.vue";

const props = defineProps({
  token: { type: String, default: null },
  addressId: { type: String, default: null },
  appliedId: { type: String, default: null },
});

const dialog = ref(false);

async function onCancel() {
  dialog.value = false;
}
</script>

<template>
  <v-dialog v-model="dialog" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark v-bind="attrs" v-on="on" class="primary"> Logboek </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <header class="app-welcome-message">
          <h2>Logboek</h2>
        </header>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <applied-logging-overview v-if="props.token || props.addressId || props.appliedId" :addressId="props.addressId" :appliedId="props.appliedId"></applied-logging-overview>
      </v-card-text>

      <v-card-actions>
        <v-container fluid>
          <v-row>
            <v-col cols="auto" class="form-group">
              <v-btn dark class="primary" title="Sluiten" @click.prevent="onCancel()">
                <v-icon dark left>mdi-cancel</v-icon>
                Sluiten
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
