var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { staticClass: "primary", attrs: { dark: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" Logboek ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _setup.dialog,
        callback: function ($$v) {
          _setup.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("header", { staticClass: "app-welcome-message" }, [
                _c("h2", [_vm._v("Logboek")]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _setup.dialog = false
                    },
                  },
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _setup.props.token ||
              _setup.props.addressId ||
              _setup.props.appliedId
                ? _c(_setup.AppliedLoggingOverview, {
                    attrs: {
                      addressId: _setup.props.addressId,
                      appliedId: _setup.props.appliedId,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "form-group", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { dark: "", title: "Sluiten" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _setup.onCancel()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-cancel"),
                              ]),
                              _vm._v(" Sluiten "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }