var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-breadcrumbs", {
    staticClass: "pb-0",
    attrs: { items: _setup.breadcrumbs, divider: ">" },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "v-breadcrumbs-item",
              { attrs: { to: item.to } },
              [
                item.icon
                  ? _c("v-icon", [_vm._v(_vm._s(item.icon))])
                  : [_vm._v(_vm._s(item.text))],
              ],
              2
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }