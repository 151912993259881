<script lang="ts" setup>
import { computed } from "vue";

const timeCountDown = computed(() => {
  //When subtracting dates the result is given in milliseconds
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const dateNow = new Date();
  const dateParisProof = new Date("2050-01-01");

  return Math.floor((dateParisProof - dateNow) / _MS_PER_DAY);
});
</script>

<template>
  <v-main>
    <div class="account-page">
      <div class="welcome">
        <div class="logo">
          <v-img alt="INNAX Logo" src="@/assets/img/logo.png" class="image" />
        </div>
        <v-tabs class="tabToggles" active-class="active">
          <v-tabs-slider hidden></v-tabs-slider>
          <v-tab class="tab">Aanmelden</v-tab>
          <v-tab class="tab">Meer informatie</v-tab>
          <v-tab-item>
            <account-login></account-login>
          </v-tab-item>
          <v-tab-item>
            <account-info></account-info>
          </v-tab-item>
        </v-tabs>
      </div>
      <v-layout class="main-image">
        <p>
          Nog <strong>{{ timeCountDown }} dagen</strong> om<br />Paris Proof te halen
        </p>
      </v-layout>
    </div>
  </v-main>
</template>
