var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", "max-width": "80%" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "div",
                { class: "btn-tooltip" },
                [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "primary" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { dark: "", left: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                      _vm._v(" Erkende maatregelen inladen "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _setup.dialog,
        callback: function ($$v) {
          _setup.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("header", { staticClass: "app-welcome-message" }, [
                _c("h2", [
                  _vm._v("Welke categorieen zijn aanwezig in dit gebouw?"),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _setup.onCancel()
                    },
                  },
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(_setup.CertifiedCategories, {
                attrs: {
                  selectedCategoriesProp: _setup.selectedCategoriesProp,
                  resetSelectedCategories: _setup.dialog,
                },
                on: { "changed-selection": _setup.categorySelected },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "form-group", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: {
                                disabled: _setup.busy,
                                dark: !_setup.busy,
                              },
                              on: { click: _setup.loadCertifiedMeasures },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-import"),
                              ]),
                              _vm._v(" Importeren "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { dark: "", title: "Annuleren" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _setup.onCancel()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-cancel"),
                              ]),
                              _vm._v(" Annuleren "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }