import dayjs from "dayjs";
import "dayjs/locale/nl";

export default function useDateTimeFormatter() {
  //console.log("dateTimeFormatter start ");
  const utcPlugin = require("dayjs/plugin/utc");
  dayjs.extend(utcPlugin);

  const isBetweenPlugin = require("dayjs/plugin/isBetween");
  dayjs.extend(isBetweenPlugin);

  const localizedFormatPlugin = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormatPlugin);

  const customParseFormatPlugin = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormatPlugin);

  dayjs.locale("nl");

  const utcToLocal = (date) => {
    return dayjs.utc(date).local();
  };

  //was formatDate
  const formatDateUtc = (date) => {
    if (date) {
      return utcToLocal(date).format("L");
    } else {
      return "-";
    }
  };

  //was formatDateS
  const formatDateUtcD = (date) => {
    if (date) {
      return utcToLocal(date).format("DD-MM-YYYY");
    } else {
      return "-";
    }
  };

  //was formatDateST
  const formatDateUtcDT = (date) => {
    if (date) {
      return utcToLocal(date).format("DD-MM-YYYY HH:mm:ss");
    } else {
      return "-";
    }
  };

  //was formatDateL
  const formatDateUtcL = (date) => {
    if (date) {
      return utcToLocal(date).format("dd D MMM YYYY h:mm:ss");
    } else {
      return "-";
    }
  };

  //was formatDateApi
  const formatDateApi = (value) => {
    if (value && value != "") {
      return dayjs(value).format("YYYYMMDD");
    } else {
      return undefined;
    }
  };

  //was getDateString
  const formatDateIsoD = (date) => {
    if (date) {
      return dayjs(date).format("YYYY-MM-DD");
    } else {
      return "";
    }
  };

  const formatDateUtcIsoD = (date) => {
    if (date) {
      return utcToLocal(date).format("YYYY-MM-DD");
    } else {
      return "";
    }
  };

  //was parseStringDate
  const parseDateIso = (date) => {
    return dayjs(date, "YYYY-MM-DD");
  };

  //was parseInputDate
  const parseDateInput = (date) => {
    return dayjs(date, "D-M-YYYY");
  };

  const dateBetween = (date, fromDate, tillDate) => {
    return dayjs(date).isBetween(fromDate, tillDate, "days", "[]"); // all inclusive
  };

  return {
    utcToLocal,
    formatDateUtc,
    formatDateUtcD,
    formatDateUtcDT,
    formatDateUtcL,
    formatDateApi,
    formatDateIsoD,
    formatDateUtcIsoD,
    parseDateIso,
    parseDateInput,
    dateBetween,
  };
}
