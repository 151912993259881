<script>
import { Doughnut } from "vue-chartjs";

export default {
  name: "SimpleDoughnutChart",

  extends: Doughnut,

  mounted() {
    //console.log("SimpleDoughnutChart renderChart ");
    this.renderChart(this.chartdata, this.options);
  },

  props: {
    percentage: {
      type: Number,
      default: undefined
    },
    color: {
      type: String,
      default: "#00A2DF"
    }
  },

  data() {
    return {
      chartdata: {
        datasets: [
          {
            // data: this.calculateDataSet(this.percentage),
            data: null,
            backgroundColor: [this.color, "#F1F5F6"],
            borderColor: "#FFFFFF",
            borderWidth: 2
          }
        ]
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        animation: {
          duration: 2000
        }
      }
    };
  },

  methods: {
    // calculateDataSet: function(percentage) {
    //   console.log("SimpleDoughnutChart calculateDataSet percentage ", percentage);
    //   return [percentage, 100 - percentage];
    // },

    // repopulateChartData: function(percentage) {
    //   console.log("SimpleDoughnutChart repopulateChartData percentage ", percentage);
    //   this.chartdata.datasets[0].data = this.calculateDataSet(this.percentage);
    //   this.$data._chart.update();
    // }
  },

  watch: {
    percentage: function(percentage) {
      //console.log("SimpleDoughnutChart watch percentage ", percentage);
      this.chartdata.datasets[0].data = [percentage, 100 - percentage];
      this.$data._chart.update();
      //      this.repopulateChartData(percentage);
    }
  }
};
</script>
