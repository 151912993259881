import { defineStore } from "pinia";

const useCacheStore = defineStore("cache", () => {

  const _cachedDocuments = [];

  async function cacheDocumentInfo(locRef, id, description) {
    _cachedDocuments.push({'locRef':locRef, 'documentId':id, 'description':description});
  }

  function isDocumentCached(locRef) {
    const isCached = (_cachedDocuments.length > 0 && _cachedDocuments.find(x => x.locRef == locRef) != undefined);
    return isCached;
  }

  function getDocumentInfo(locRef) {
    const document = _cachedDocuments.find(x => x.locRef == locRef);
    if (document != undefined) {
      return document;
    }
  }

  return {
    cacheDocumentInfo,
    isDocumentCached,
    getDocumentInfo,
  };
});

export { useCacheStore };
