var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    [
      _c(
        "v-row",
        _vm._l(_setup.categoriesMain, function (main) {
          return _c(
            "v-col",
            { key: main.key },
            [
              _c("v-checkbox", {
                ref: "maincb-" + main.key,
                refInFor: true,
                attrs: {
                  label: main.descr,
                  value: main.key,
                  indeterminate: _setup.isIndeterminate(main.key),
                  "hide-details": "",
                  dense: "",
                },
                on: {
                  change: function ($event) {
                    return _setup.mainSelected(main.key, $event)
                  },
                },
                model: {
                  value: _setup.selectedMains,
                  callback: function ($$v) {
                    _setup.selectedMains = $$v
                  },
                  expression: "selectedMains",
                },
              }),
              _vm._l(_setup.subCategories(main.key), function (cat) {
                return _c(
                  "v-row",
                  { key: cat.type.key, attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pl-7" },
                      [
                        _c("v-checkbox", {
                          attrs: {
                            label: cat.type.descr,
                            value: cat.type.key,
                            "hide-details": "",
                            dense: "",
                          },
                          on: {
                            change: function ($event) {
                              return _setup.categorySelected(main.key)
                            },
                          },
                          model: {
                            value: _setup.selectedCategories,
                            callback: function ($$v) {
                              _setup.selectedCategories = $$v
                            },
                            expression: "selectedCategories",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }