var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "overview-table",
        attrs: {
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.controls`,
              fn: function ({ item }) {
                return [
                  _vm.certified == false
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: { dark: "", title: "Kiezen" },
                          on: {
                            click: function ($event) {
                              return _setup.select(item)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "", left: "" } }, [
                            _vm._v("mdi-checkbox-marked-circle-outline"),
                          ]),
                          _vm._v(" Kiezen "),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _setup.totalItems > 5
        ? _c(_setup.GridPagination, {
            attrs: {
              pageNumber: _setup.pageNumber,
              pageSize: _setup.pageSize,
              pageCount: _setup.pageCount,
            },
            on: { changedPaging: _setup.changedPaging },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }