<script lang="ts" setup>
import { ref, watch } from "vue";

import { GetInvestmentVsHomesMeetingNetHeatRequirement } from "@/services/api/chart.api";

const props = defineProps({
  token: {
    type: String,
  },
});

const chartdata = ref({});
const isLoading = ref(true);

watch(
  () => props.token,
  () => {
    if (props.token) {
      fetchData();
    } else {
      chartdata.value = {};
    }
  }
);

async function fetchData() {
  isLoading.value = true;
  const response = await GetInvestmentVsHomesMeetingNetHeatRequirement(props.token);
  if (response) {
    chartdata.value = response.data;
  }
  isLoading.value = false;
}
</script>

<template>
  <v-layout class="widget-item">
    <h3>Investeringen en aantal woningen dat voldoet aan de warmtebehoeft</h3>
    <div class="widget-wrapper">
      <div class="widget-chart">
        <loader-spinner v-show="isLoading === true" :is-loading="isLoading" :min-height="170"></loader-spinner>
        <chart-roadmap-investment-vs-homes-meeting-netheat-requirement v-show="isLoading === false" :height="500" legendPosition="legend-bottom" :source-data="chartdata"></chart-roadmap-investment-vs-homes-meeting-netheat-requirement>
      </div>
    </div>
  </v-layout>
</template>
