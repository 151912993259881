<script lang="ts" setup>
import { ref, getCurrentInstance } from "vue";
import MeasureGrid from "@/components/app/measures/MeasureGrid.vue";
import { getDefinitionFilterOptions } from "@/services/api/measure.api";
import { FilterTokenName, FilterStatus, mapFilterResponseStatus } from "@/stores/filtertoken";
import handleFilterTokens27 from "@/composables/handleFilterTokens27";

const emits = defineEmits(["single-select-measure"]);
const { mapFilterResult } = handleFilterTokens27(FilterTokenName.MeasureSelection);

const props = defineProps({
  ecmFunctionCode: { type: String, default: null },
  certified: { type: Boolean, default: true },
});

const filters = ref([
  {
    name: "Code",
    fieldName: "code",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Naam",
    fieldName: "name",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Omschrijving",
    fieldName: "descr",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
]);

const filterData = ref(undefined);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function handleFilterSearch(index, text) {
  //console.log("MeasureSelection handleFilterSearch: ", filterIndex, text);
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    default:
      {
        const response = await getDefinitionFilterOptions(props.ecmFunctionCode, props.certified, filters.value[index].fieldName, text);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  console.log("MeasureSelection handleFilterSearch response: ", result);
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
  //console.log("MeasureSelection getFilterOptions options: ", options);
}

async function handleFiltersChanged(newFilterData) {
  console.log("MeasureSelection handleFiltersChanged: ", newFilterData);
  filterData.value = [...newFilterData];
}

async function selectMeasure(payload) {
  emits("single-select-measure", payload);
}
</script>

<template>
  <v-container fluid class="inner">
    <v-row class="overview-wrapper">
      <v-col cols="auto">
        <div class="overview-filters align-items-start">
          <filter-set :name="FilterTokenName.MeasureSelection" :filters="filters" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
        </div>
      </v-col>
    </v-row>

    <measure-grid :filterData="filterData" :ecmFunctionCode="ecmFunctionCode" :certified="certified" @single-select-measure="selectMeasure"></measure-grid>
  </v-container>
</template>
