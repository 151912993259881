var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "information-popup", class: { active: _setup.toggled } },
    [
      _c("button", {
        ref: "input",
        staticClass: "icon-information-icon",
        on: {
          click: _setup.toggle,
          blur: function ($event) {
            return _setup.blur()
          },
        },
      }),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _setup.toggled === true,
                expression: "toggled === true",
              },
            ],
            staticClass: "information-dropdown",
          },
          [
            _c(
              "div",
              { staticClass: "information-dropdown-header" },
              [_vm._t("header")],
              2
            ),
            _c(
              "div",
              { staticClass: "information-dropdown-content" },
              [_vm._t("default")],
              2
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }