<script lang="ts" setup>
import { ref, computed, watch } from "vue";

const props = defineProps({
  height: {
    type: Number,
    default: 150,
  },

  sourceData: {
    type: Object,
    required: true,
  },

  legendPosition: {
    type: String,
    default: "legend-side",
  },
});

const chartData = ref({
  labels: ["Verdeling"],
  datasets: [
    {
      label: "Reeds uitgevoerd",
      data: [],
      sumData: [],
      backgroundColor: "#00A2DF",
    },
    {
      label: "Nog uit te voeren",
      data: [],
      sumData: [],
      backgroundColor: "steelblue",
    },
    {
      label: "Niet van toepassing",
      data: [],
      sumData: [],
      backgroundColor: "grey",
    },
  ],
});
const options = ref({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        display: false,
        stacked: true,
      },
    ],
    xAxes: [
      {
        stacked: true,
        ticks: {
          minx: 0,
          max: 100,
        },
      },
    ],
  },
  legend: {
    display: true,
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        let label = data.datasets[tooltipItem.datasetIndex].label;
        let sum = data.datasets[tooltipItem.datasetIndex].sumData[tooltipItem.index];

        return `${label}: ${sum}`;
      },
    },
  },
});
const htmlLegend = ref();

const chartStyles = computed(() => {
  return {
    height: `${props.height}px`,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  };
});

watch(
  () => props.sourceData,
  () => {
    chartData.value.datasets[0].data = [props.sourceData.prcExecuted];
    chartData.value.datasets[0].sumData = [props.sourceData.numExecuted];
    chartData.value.datasets[1].data = [props.sourceData.prcApplicable];
    chartData.value.datasets[1].sumData = [props.sourceData.numApplicable];
    chartData.value.datasets[2].data = [props.sourceData.prcNotRelevant];
    chartData.value.datasets[2].sumData = [props.sourceData.numNotRelevant];
  }
);

function handleChartRendered(payload) {
  htmlLegend.value = payload;
}
</script>

<template>
  <v-row class="vue-chart vue-chart-measures pa-0" :class="props.legendPosition">
    <v-col class="vue-chart-wrapper" cols="12">
      <advanced-horizontal-bar-chart :chartData="chartData" :options="options" :styles="chartStyles" @chart-rendered="handleChartRendered"></advanced-horizontal-bar-chart>
    </v-col>
    <v-col class="vue-chart-legend"><span v-html="htmlLegend"></span></v-col>
  </v-row>
</template>
