<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();

function logout() {
  authStore.logout();
}
</script>

<template>
  <v-layout flex-column class="login">
    <v-row>
      <v-col md="2" sm="4"> </v-col>
      <v-col md="3" sm="6">
        <br /><br /><br />
        <div class="logo">
          <v-img alt="INNAX Logo" src="@/assets/img/logo.png" class="image" />
        </div>
        <br /><br /><br />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="2" sm="4"> </v-col>
      <v-col md="6" sm="12">
        <h1>U bent niet (meer) geauthoriseerd voor de Paris Proof Monitor of deze pagina</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="2" sm="4"> </v-col>
      <v-col md="6" sm="12">
        <h2>Neem contact op met de administrator</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="2" sm="4"> </v-col>
      <v-col md="6" sm="12">
        <v-btn color="primary" @click="logout">Afmelden</v-btn>
      </v-col>
    </v-row>
  </v-layout>
</template>
