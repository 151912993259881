<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";
import { getHomesMeetingNetHeatRequirement } from "@/services/api/chart.api";

const props = defineProps({
  token: {
    type: String,
  },
});

const refDate = ref(new Date());
const ppmSummary = ref({
  meanScore: 0,
  meanNorm: 0,
  count: 0,
  total: 0,
});
const percentage = ref(undefined);
const isLoading = ref(true);

watch(
  () => props.token,
  () => {
    if (props.token) {
      fetchData();
    } else {
      ppmSummary.value = {};
    }
  }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  isLoading.value = true;
  const response = await getHomesMeetingNetHeatRequirement(props.token, refDate.value);
  if (response) {
    //ppmSummary.meanScore = 0;
    //ppmSummary.meanNorm = 0;
    ppmSummary.value.count = response.data.counted;
    ppmSummary.value.total = response.data.total;
    percentage.value = response.data.counted == 0 ? 0 : Math.trunc((100 * response.data.meetingNetHeatRequirement) / response.data.counted + 0.5);
  } else {
    console.error("Failed to get Net Heat Requirement summary");
    proxy.$toaster.error("Ophalen van de netto warmtebehoefte is mislukt!");
  }
  isLoading.value = false;
}
</script>

<template>
  <v-layout class="widget-item">
    <h3>Hoeveel procent voldoet aan <b>Standaard</b></h3>
    <loader-spinner v-show="isLoading === true" :is-loading="isLoading" :min-height="170"></loader-spinner>
    <div class="widget-wrapper" v-show="isLoading === false">
      <div class="widget-chart ppm-widget">
        <percentage-chart :percentage="percentage" :height="170"></percentage-chart>
      </div>
    </div>
  </v-layout>
</template>
