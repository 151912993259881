var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "login", attrs: { "flex-column": "" } },
    [
      _c("header", [
        _c("h1", { staticClass: "large" }, [
          _vm._v("Welkom bij de"),
          _c("br"),
          _vm._v("Paris Proof Monitor"),
        ]),
      ]),
      _c("article", [
        _vm._v(
          "Het intelligente dataplatform voor overzicht en inzicht in uw vastgoed en de meest effectieve verduurzamingsroute."
        ),
      ]),
      _c(
        "footer",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    { staticClass: "primary", on: { click: _setup.login } },
                    [
                      _c("v-icon", { attrs: { dark: "", left: "" } }, [
                        _vm._v("mdi-arrow-right"),
                      ]),
                      _vm._v(" Inloggen "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }